import React from 'react'
import { Field } from 'react-final-form'

function ConditionalField({ when, is, children }) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  )
}

export default ConditionalField
