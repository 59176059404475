import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'

import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'

import { breakpointMax } from 'styles/mixins'
import Stack from 'components/base/Stack'

const useStyles = createUseStyles(({ colors }) => ({
  main: {
    padding: [25, 50],
    height: props => props.height,
    maxHeight: '80vh',
    overflowY: 'auto',
    [breakpointMax('xs')]: {
      padding: [25, 20]
    },
    position: 'relative'
  },
  tabContainer: {
    padding: 30,
    backgroundColor: colors.greyPale,
    borderBottom: `1px solid ${colors.greyExtraLight}`
  },
  sub: {
    width: 200
  }
}))

function ModalContentWithTabs({
  children,
  disableNonActiveTab,
  height,
  onTabSelection,
  tabs,
  footer: Footer
}) {
  const classes = useStyles({ height })

  const modalWithTabs = tabs?.length

  return (
    <>
      {modalWithTabs && (
        <div className={classes.tabContainer}>
          <Stack stretch justify="space-between">
            <Tabs variant="modal">
              {tabs.map(tab => {
                const { title, active } = tab
                return (
                  <Tab
                    active={active}
                    disableNonActiveTab={disableNonActiveTab}
                    key={title}
                    onClick={() => onTabSelection(title)}
                    variant="fullWidth"
                  >
                    {title}
                  </Tab>
                )
              })}
            </Tabs>
            {Footer && (
              <Stack justify="flex-end" className={classes.sub}>
                <Footer />
              </Stack>
            )}
          </Stack>
        </div>
      )}
      <main className={classes.main}>{children}</main>
    </>
  )
}

ModalContentWithTabs.propTypes = {
  height: PropTypes.number.isRequired,
  onTabSelection: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.object),
  showTabs: PropTypes.bool
}

ModalContentWithTabs.defaultProps = {
  onTabSelection: () => null,
  tabs: null,
  showTabs: true
}

export default ModalContentWithTabs
