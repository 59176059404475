import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import validateSchema from 'lib/validators'

const schema = yup.object().shape({
  providerUsername1: yup.string().required()
})

function SetupUsernameForm({ onFormSubmit, role }) {
  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoFocus
            component={AuthTextInput}
            name="providerUsername1"
            placeholder="Provider Username"
            showValidationMessage
          />
          <AuthFormFooter>
            <Button
              type="submit"
              label="Next"
              disabled={pristine || submitting}
              onClick={onFormSubmit}
              role={role}
            />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

SetupUsernameForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default SetupUsernameForm
