import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

// generated by Fragment Matcher plugin
import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

export default fragmentMatcher
