import theme from './theme'

export default {
  colors: {
    ...theme.colors,

    primary: '#045999',
    primaryText: '#1f2433',

    surgicalNotesBlue: '#02487C',
    secondary: '#0568b2',
    tertiary: '#3ab0e5',
    secondaryDark: '#02487C'
  },
  blockColors: {
    ...theme.blockColors
  },
  onCallBlockColors: {
    ...theme.onCallBlockColors
  },
  zIndexes: {
    ...theme.zIndexes
  },
  typography: {
    ...theme.typography
  },
  units: {
    ...theme.units
  }
}
