import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import Row from 'components/base/Row'
import SetupPasswordForm from 'components/forms/SetupPasswordForm'
import useMutation from 'hooks/useMutation'

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePasswordMutation($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      email
    }
  }
`

function SetupAdminContact() {
  const history = useHistory()
  const { token } = useParams()

  const [updatePassword] = useMutation(UPDATE_PASSWORD_MUTATION, {
    onCompleted: () => {
      history.push({
        pathname: '/completed'
      })
    }
  })

  const handleUpdatePassword = values => {
    const updateParameters = {
      ...values,

      token
    }

    return updatePassword(updateParameters)
  }

  return (
    <Fragment>
      <AuthTitle title="Set Up Your Password." />
      <Row>
        <Column span={3} />
        <Column span={6} spanMd={12}>
          <Card variant="secondary">
            <SetupPasswordForm
              submitLabel="Next"
              onFormSubmit={handleUpdatePassword}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default SetupAdminContact
