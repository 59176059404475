import React, { useContext, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AppContext from 'contexts/AppContext'
import useDefaultTab from 'hooks/useDefaultTab'
import useFeature, { featureTypes } from 'hooks/useFeature'
import { accountType } from 'models/Account'

import AccountVerificationPage from 'pages/registration/AccountVerificationPage'
import ChooseOrganizationPage from 'pages/auth/ChooseOrganizationPage'
import CompletedPage from 'pages/signup/CompletedPage'
import CompleteRegistrationPage from 'pages/registration/CompleteRegistrationPage'
import ESignSuccessPage from 'pages/signup/ESignSuccessPage'
import EulaPage from 'pages/registration/EulaPage'
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage'
import InviteProvidersPage from 'pages/signup/InviteProvidersPage'
import LoginPage from 'pages/auth/LoginPage'
import NotFoundPage from 'pages/common/NotFoundPage'
import OrganizationLoginPage from 'pages/auth/OrganizationLoginPage'
import ResetPasswordPage from 'pages/auth/ResetPasswordPage'
import SetupAccountPage from 'pages/signup/SetupAccountPage'
import SetupOrganizationPage from 'pages/signup/SetupOrganizationPage'
import SetupPasswordPage from 'pages/signup/SetupPasswordPage'
import SetupUsernamePage from 'pages/signup/SetupUsernamePage'
import SignupPage from 'pages/signup/SignupPage'
import OauthRedirect from 'pages/auth/OauthRedirect'
import VerifySessionPage from 'pages/auth/VerifySessionPage'

import InternalRoute from 'components/routes/InternalRoute'
import ExternalLoginRoute from 'components/routes/ExternalLoginRoute'
import LoginRoute from 'components/routes/LoginRoute'
import SignupRoute from 'components/routes/SignupRoute'
import isVetBuddyProduct from 'lib/CheckProduct'

function useInternalRoutes() {
  const { currentAccount } = useContext(AppContext)

  const canManageNotifications = useFeature(featureTypes.MANAGE_NOTIFICATIONS)
  const canViewAdmin = currentAccount && currentAccount.administrator
  const canManageIndependentEncounters = useFeature(
    featureTypes.MANAGE_INDEPENDENT_ENCOUNTERS
  )
  const canViewVoiceCommands = useFeature(featureTypes.VIEW_VOICE_COMMANDS)
  const canViewIntegrationHub =
    useFeature(featureTypes.VIEW_INTEGRATION_HUB) &&
    currentAccount?.type === accountType.STAFF
  const canViewBeta = useFeature(featureTypes.VIEW_BETA)
  const canViewReports =
    useFeature(featureTypes.VIEW_REPORTS) && !isVetBuddyProduct()

  const internalRoutes = useMemo(() => {
    /*
      NOTE: Order is important. The details pages should be before the list pages.
       /patient/:id should be before /patients
    */

    let routes = [
      {
        path: '/notes',
        component: React.lazy(() => import('pages/internal/NotesPage')),
        title: 'Notes'
      },
      {
        path: '/appointments/:id',
        component: React.lazy(() => import('pages/internal/AppointmentPage')),
        title: 'Appointment Details'
      },
      {
        path: '/patients/:id',
        component: React.lazy(() => import('pages/internal/PatientPage')),
        title: 'Patient Details'
      },
      {
        path: '/help/article/:id',
        component: React.lazy(() => import('pages/internal/HelpArticlePage')),
        title: 'Help'
      },
      {
        path: '/help/release/:id',
        component: React.lazy(() => import('pages/internal/ReleaseNotePage')),
        title: 'Help'
      },
      {
        path: '/admin/licensing/users/:id',
        component: React.lazy(() => import('pages/internal/UserPage')),
        title: 'User'
      },
      {
        path: '/help',
        component: React.lazy(() => import('pages/internal/HelpPage')),
        title: 'Help'
      },
      {
        path: '/patients',
        component: React.lazy(() => import('pages/internal/PatientsPage')),
        title: 'Patients'
      },
      {
        path: '/profile',
        component: React.lazy(() => import('pages/internal/ProfilePage')),
        title: 'Profile'
      },
      {
        path: '/schedule',
        component: React.lazy(() => import('pages/internal/SchedulePage')),
        title: 'Schedule'
      },
      {
        path: '/block-times/:id',
        component: React.lazy(() =>
          import('pages/internal/AvailabilityDetailsPage')
        ),
        title: 'Block Times Details'
      }
    ].filter(Boolean)

    if (canViewAdmin) {
      routes = [
        ...routes,
        {
          path: '/admin',
          component: React.lazy(() => import('pages/internal/AdminPage')),
          title: 'Admin'
        }
      ]
    }

    if (canManageIndependentEncounters) {
      routes = [
        ...routes,
        {
          path: '/encounters/:id',
          component: React.lazy(() => import('pages/internal/EncounterPage')),
          title: 'Encounter Details'
        }
      ]
    }

    if (canViewVoiceCommands) {
      routes = [
        ...routes,
        {
          path: '/voice-commands/:id',
          component: React.lazy(() =>
            import('pages/internal/VoiceCommandPage')
          ),
          title: 'Voice Command Details'
        }
      ]
    }

    if (canManageNotifications) {
      routes = [
        ...routes,
        {
          path: '/inbox/notifications',
          component: React.lazy(() => import('pages/internal/InboxPage')),
          title: 'Inbox Notification'
        }
      ]
    }

    routes = [
      ...routes,
      {
        path: '/encounters',
        component: React.lazy(() => import('pages/internal/EncountersPage')),
        title: 'Encounters'
      },
      {
        path: '/on-call',
        component: React.lazy(() => import('pages/internal/OnCallPage')),
        title: 'On Call Records'
      },
      {
        path: '/voice-commands',
        component: React.lazy(() => import('pages/internal/VoiceCommandsPage')),
        title: 'Voice Commands'
      }
    ]

    if (canViewIntegrationHub) {
      routes = [
        ...routes,
        {
          path: '/integration-hub/inbox',
          component: React.lazy(() =>
            import('pages/internal/IntegrationHubPage')
          ),
          title: 'Inbox - Integration Hub'
        },
        {
          path: '/integration-hub/requests',
          component: React.lazy(() =>
            import('pages/internal/IntegrationHubPage')
          ),
          title: 'Requests - Integration Hub'
        },
        {
          path: '/integration-hub/patients',
          component: React.lazy(() =>
            import('pages/internal/IntegrationHubPage')
          ),
          title: 'Patients - Integration Hub'
        },
        {
          path: '/integration-hub/activity',
          component: React.lazy(() =>
            import('pages/internal/IntegrationHubPage')
          ),
          title: 'Activity - Integration Hub'
        },
        {
          path: '/integration-hub',
          component: React.lazy(() =>
            import('pages/internal/IntegrationHubPage')
          ),
          title: 'Integration Hub'
        }
      ]
    }

    if (canViewBeta) {
      routes = [
        ...routes,
        {
          path: '/beta',
          component: React.lazy(() => import('pages/internal/BetaPage')),
          title: 'DocBuddy - Beta',
          layoutProps: { hideSideBar: true }
        }
      ]
    }

    if (canViewReports) {
      routes = [
        ...routes,
        {
          path: '/reports',
          component: React.lazy(() => import('pages/internal/ReportsPage')),
          title: 'Reports'
        }
      ]
    }

    return routes
  }, [
    canManageNotifications,
    canViewAdmin,
    currentAccount,
    canManageIndependentEncounters,
    canViewVoiceCommands,
    canViewIntegrationHub,
    canViewBeta,
    canViewReports
  ])

  return internalRoutes
}

function AppRouter() {
  const { isLoggedIn } = useContext(AppContext)
  const defaultTab = useDefaultTab()
  const internalRoutes = useInternalRoutes()

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Redirect replace to={isLoggedIn ? defaultTab : '/login'} />
        )}
      />
      <SignupRoute
        path="/completed"
        component={CompletedPage}
        title="Setup Password"
      />
      <SignupRoute
        path="/esign-success"
        component={ESignSuccessPage}
        title="e-Sign Success"
      />
      <SignupRoute path="/signup" component={SignupPage} title="Sign up" />
      <SignupRoute
        path="/setup/:token/account"
        component={SetupAccountPage}
        title="Set up Account"
      />
      <SignupRoute
        path="/setup/:token/organization"
        component={SetupOrganizationPage}
        title="Set up Organization"
      />
      <SignupRoute
        path="/setup/:token/invite-providers"
        component={InviteProvidersPage}
        title="Invite Providers"
      />
      <SignupRoute
        path="/setup/:token/setup-username"
        component={SetupUsernamePage}
        title="Setup Username"
      />
      <SignupRoute
        path="/setup/:token/password"
        component={SetupPasswordPage}
        title="Setup Password"
      />
      <SignupRoute
        path="/verification"
        component={AccountVerificationPage}
        title="Account Verification"
      />
      <SignupRoute
        path="/complete-registration"
        component={CompleteRegistrationPage}
        title="Complete Registration"
      />
      <SignupRoute
        path="/shared/:token/calendar"
        component={React.lazy(() =>
          import('pages/common/ViewOnCallAvailabilities')
        )}
        title="On Call Schedule"
        layoutProps={{ fullWidthContent: true, isLoggedIn }}
      />

      <ExternalLoginRoute
        path="/verify/saml"
        render={props => <VerifySessionPage {...props} />}
      />
      <ExternalLoginRoute
        path="/oauth-redirect"
        render={props => <OauthRedirect {...props} />}
      />

      <LoginRoute path="/login" component={LoginPage} title="Login" />
      <LoginRoute
        path="/choose-organization"
        component={ChooseOrganizationPage}
        title="Choose Organization"
      />
      <LoginRoute
        path="/organization-login"
        component={OrganizationLoginPage}
        title="Organization Login"
      />
      <LoginRoute
        path="/accept-eula"
        component={EulaPage}
        title="Accept EULA"
      />
      <LoginRoute
        path="/forgot-password"
        component={ForgotPasswordPage}
        title="Forgot Password"
      />
      <LoginRoute
        path="/:type/reset-password/:token"
        component={ResetPasswordPage}
        title="Reset Password"
      />

      {internalRoutes.map(routeProps => (
        <InternalRoute key={routeProps.path} {...routeProps} />
      ))}

      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default AppRouter
