import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ colors }) => ({
  footer: {
    borderTop: `1px solid ${colors.greyExtraLight}`,
    display: 'flex',
    justifyContent: props =>
      props.justifyContent ? props.justifyContent : 'flex-end',
    padding: props => (props.padding ? props.padding : '25px 50px')
  },
  footer_compact: {
    border: 'none',
    justifyContent: props => !props.justifyContent && 'center'
  }
}))

function ModalFooter({ children, variant, ...props }) {
  const classes = useStyles(props)

  return (
    <footer
      className={classNames(classes.footer, {
        [classes.footer_compact]: variant === 'compact'
      })}
    >
      {children}
    </footer>
  )
}

ModalFooter.propTypes = {
  variant: PropTypes.oneOf(['default', 'compact'])
}

ModalFooter.defaultProps = {
  variant: 'default'
}

export default ModalFooter
