import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import rgba from 'lib/rgba'

const useStyles = createUseStyles(({ colors }) => ({
  divider: {
    alignSelf: ({ align }) => align
  },
  divider_vertical: {
    borderLeft: ({ color, opacity }) => `1px solid ${rgba(colors[color], opacity)}`,
    height: ({ heightOffset, percentHeight }) => `calc( ${percentHeight}% + ${heightOffset}px )`,
    marginRight: ({ verticalGutter }) => verticalGutter,
    marginLeft: ({ verticalGutter }) => verticalGutter
  },
  divider_horizontal: {
    borderBottom: ({ color, opacity }) => `1px solid ${rgba(colors[color], opacity)}`,
    width: ({ widthOffset, percentWidth }) => `calc( ${percentWidth}% + ${widthOffset}px )`,
    marginTop: ({ horizontalGutter }) => horizontalGutter,
    marginBottom: ({ horizontalGutter }) => horizontalGutter
  }
}))

function Divider({ orientation, ...props }) {
  const classes = useStyles(props)

  return (
    <div className={
      classNames(
        classes.divider,
        classes[`divider_${orientation}`]
      )
    }
    />
  )
}

Divider.propTypes = {
  align: PropTypes.oneOf([ 'auto', 'stretch' ]),
  color: PropTypes.string,
  heightOffset: PropTypes.number,
  horizontalGutter: PropTypes.number,
  opacity: PropTypes.number,
  orientation: PropTypes.oneOf([ 'vertical', 'horizontal' ]),
  percentHeight: PropTypes.number,
  percentWidth: PropTypes.number,
  verticalGutter: PropTypes.number,
  widthOffset: PropTypes.number
}

Divider.defaultProps = {
  align: 'stretch',
  color: 'greyExtraLight',
  heightOffset: 0,
  horizontalGutter: 15,
  opacity: 1,
  orientation: 'vertical',
  percentHeight: 100,
  percentWidth: 100,
  verticalGutter: 20,
  widthOffset: 0
}

export default Divider
