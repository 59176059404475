/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  rectangleArt: props => ({
    borderTop: `2px solid ${colors.secondary}`,
    borderLeft: props.left && `2px solid ${colors.secondary}`,
    borderRight: props.right && `2px solid ${colors.secondary}`,
    borderTopLeftRadius: props.topLeftRadius,
    borderTopRightRadius: props.topRightRadius,
    borderBottomRightRadius: props.bottomRightRadius,
    borderBottomLeftRadius: props.bottomLeftRadius,
    opacity: props.opacity,
    position: 'fixed',
    top: `${props.top}%`,
    right: `${props.right}%`,
    bottom: `${props.bottom}%`,
    left: `${props.left}%`,
    height: `${100 - props.top - props.bottom}%`,
    width: `${100 - props.left - props.right}%`,
    zIndex: zIndexes.background
  })
}))

function RectangleArt(props) {
  const classes = useStyles(props)

  return <div className={classes.rectangleArt} />
}

RectangleArt.propTypes = {
  topLeftRadius: PropTypes.number,
  topRightRadius: PropTypes.number,
  bottomRightRadius: PropTypes.number,
  bottomLeftRadius: PropTypes.number,
  opacity: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number
}

RectangleArt.defaultProps = {
  topLeftRadius: 0,
  topRightRadius: 0,
  bottomRightRadius: 0,
  bottomLeftRadius: 0,
  opacity: 0.6,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
}

export default RectangleArt
