import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { capitalize } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import alerts from 'constants/alerts'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import PageTitle from 'components/typography/PageTitle'
import ResetPasswordForm from 'components/forms/ResetPasswordForm'
import Row from 'components/base/Row'
import useMutation from 'hooks/useMutation'
import { showAlertSuccess } from 'client/methods'

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      id
    }
  }
`

function ResetPasswordPage() {
  const { t } = useTranslation()
  const { type, token } = useParams()
  const history = useHistory()

  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      showAlertSuccess({ message: alerts.reset_password })
      history.push('/login')
    }
  })

  const handleSubmit = values => {
    const params = {
      resetPasswordToken: token,
      type: capitalize(type),

      ...values
    }

    return resetPassword(params)
  }

  return (
    <Fragment>
      <Row>
        <Column span={3} />
        <Column span={6}>
          <PageTitle data-testid="reset-password-title">
            {t('resetPasswordPage.title')}
          </PageTitle>
        </Column>
      </Row>
      <Row>
        <Column span={3} />
        <Column span={6}>
          <Card variant="secondary">
            <ResetPasswordForm onFormSubmit={handleSubmit} />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default ResetPasswordPage
