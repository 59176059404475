import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthSelectInput from 'components/inputs/AuthSelectInput'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import validateSchema from 'lib/validators'
import { ACCOUNT_REGIONS } from 'models/Account'

const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email').required()
})

function SignupForm({ onFormSubmit, submitLabel, userRegion }) {
  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={AuthTextInput}
            icon="email"
            name="email"
            placeholder="Enter your email"
            showValidationMessage
            showValidationUI
          />
          {userRegion && (
            <Field
              component={AuthSelectInput}
              name="region"
              placeholder="Select a region"
              options={ACCOUNT_REGIONS.map(region => ({
                value: region,
                label: region
              }))}
              defaultOption={{ value: userRegion, label: userRegion }}
              showValidationMessage
              variant="large"
            />
          )}
          <AuthFormFooter>
            <Button label={submitLabel} disabled={submitting} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

SignupForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  userRegion: PropTypes.string
}

SignupForm.defaultProps = {
  userRegion: null
}

export default SignupForm
