const KEY = 'docbuddy-portal-component-query'

const update = data => {
  const json = JSON.stringify(data)

  localStorage.setItem(KEY, json)
}

const get = () => JSON.parse(localStorage.getItem(KEY))

export default {
  update,
  get
}
