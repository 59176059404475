import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import Divider from 'components/base/Divider'
import Menu from 'components/menu/Menu'
import MenuItem from 'components/menu/MenuItem'
import ProfilePicture from 'components/base/ProfilePicture'
import Text from 'components/typography/Text'
import useClientQuery from 'hooks/useClientQuery'
import useMutation from 'hooks/useMutation'
import { DESTROY_SESSION_MUTATION, GET_AUTH } from 'client/state/auth'

import rgba from 'lib/rgba'
import { logout } from 'client/methods'

const useStyles = createUseStyles(({ colors, typography }) => ({
  menuWrapper: {
    cursor: 'pointer',
    position: 'absolute',
    top: 25,
    right: 15
  },
  menuFooter: {
    background: colors.greyPale,
    borderTop: `1px solid ${rgba(colors.greyExtraLight, 0.5)}`,
    color: colors.grey,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.semibold,
    letterSpacing: 1.2,
    padding: [10, 30],
    textTransform: 'uppercase'
  },
  userDetails: {
    padding: [0, 20, 0, 30]
  }
}))

function AccountMenu({ currentAccount }) {
  const { t } = useTranslation()
  const classes = useStyles()
  const ref = useRef(null)
  const [visible, setVisibility] = useState(false)
  const [destroySession] = useMutation(DESTROY_SESSION_MUTATION)

  const { data: { auth: { currentToken } = {} } = {} } =
    useClientQuery(GET_AUTH)

  const toggleMenu = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisibility(false)
    } else {
      setVisibility(!visible)
    }
  }

  const handleLogout = () => {
    destroySession({ accessToken: currentToken })
    logout()
  }

  useEffect(() => {
    document.addEventListener('click', toggleMenu)
    return () => {
      document.removeEventListener('click', toggleMenu)
    }
  })

  const removeIntegrationHubTokens = () => {
    localStorage.removeItem('accessToken')
  }

  return (
    <div
      ref={ref}
      role="menu"
      tabIndex={0}
      className={classes.menuWrapper}
      onClick={toggleMenu}
      onKeyDown={toggleMenu}
    >
      <ProfilePicture
        bordered={visible}
        currentAccount={currentAccount}
        role="menuitem"
      />
      <Menu visible={visible} role="menu">
        <Text
          color="secondary"
          weight="medium"
          className={classes.userDetails}
          as="span"
          role="menuitem"
        >
          {currentAccount?.firstName} {currentAccount?.lastName}
        </Text>
        <Text
          color="grey"
          size="xSmall"
          as="span"
          className={classes.userDetails}
          role="menuitem"
        >
          {currentAccount?.organization?.displayName}
        </Text>
        <Divider orientation="horizontal" role="separator" />
        <MenuItem
          onClick={removeIntegrationHubTokens}
          to="/choose-organization"
        >
          {t('accountMenu.menuItems.switchOrganization')}
        </MenuItem>
        <MenuItem to="/profile">{t('accountMenu.menuItems.profile')}</MenuItem>
        <MenuItem to="/help">{t('accountMenu.menuItems.help')}</MenuItem>
        <MenuItem onClick={handleLogout} className={classes.menuFooter}>
          {t('accountMenu.menuItems.logout')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default AccountMenu
