import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

import Divider from 'components/base/Divider'
import FontIcon from 'components/base/FontIcon'
import Menu from 'components/menu/Menu'
import MenuItem from 'components/menu/MenuItem'
import resolveImage from 'lib/resolveImage'
import Stack from 'components/base/Stack'
import Text from 'components/typography/Text'
import { regionAcronyms, switcherLanguageAcronyms } from 'models/Account'

const useStyles = createUseStyles(({ colors }) => ({
  wrapper: {
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    userSelect: 'none',
    transition: 'all 0.5s ease-out'
  },

  menu: {
    top: 42,
    padding: [6, 0, 10, 0]
  },

  menuItemHeader: {
    '&:hover': {
      cursor: 'default'
    },
    fontSize: 12,
    padding: [8, 18, 8, 15]
  },

  menuItem: {
    '&:hover': {
      backgroundColor: colors.secondaryPale,
      color: colors.dark
    },
    '& img': {
      marginRight: 5
    },

    fontSize: 12,
    fontWeight: 400,
    padding: [6, 18, 6, 15]
  },

  menuItemSelected: {
    color: colors.secondary
  }
}))

const shortenLanguageLabel = languageLabel => {
  if (languageLabel.includes('French')) {
    return languageLabel.replace(/\s*\(.*\)$/, '')
  }
  return languageLabel
}

const regionFile = currentRegion =>
  `docbuddy-${regionAcronyms[currentRegion]}.png`

const languageFile = currentLanguage =>
  `docbuddy-${switcherLanguageAcronyms[currentLanguage]}.png`

function RegionAndLanguageSelectorMenu({
  regionSelectorMenuItems,
  currentRegion = 'United States',
  languageSelectorMenuItems,
  currentLanguage = 'English'
}) {
  const classes = useStyles()
  const ref = useRef(null)
  const [visible, setVisibility] = useState(false)

  const toggleMenu = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setVisibility(false)
    } else {
      setVisibility(!visible)
    }
  }

  useEffect(() => {
    document.addEventListener('click', toggleMenu)
    return () => {
      document.removeEventListener('click', toggleMenu)
    }
  })

  const regionImgSrc = regionFile(currentRegion)

  return (
    <div
      ref={ref}
      role="menu"
      tabIndex={0}
      className={classes.wrapper}
      onClick={toggleMenu}
      onKeyDown={toggleMenu}
    >
      <Stack>
        <img
          src={resolveImage(regionImgSrc)}
          alt={regionImgSrc}
          height={20}
          width={20}
        />
        <Stack>
          <Text weight="semibold" color="tertiary" transform="uppercase">
            {currentRegion}
          </Text>
          <Text weight="bold" color="tertiary">
            &bull;
          </Text>
          <Text weight="semibold" color="tertiary" transform="uppercase">
            {shortenLanguageLabel(currentLanguage)}
          </Text>
          <FontIcon
            color="tertiary"
            title="Change Region"
            name={visible ? 'angle-up' : 'angle-down'}
            size="small"
          />
        </Stack>
      </Stack>
      <Menu visible={visible} className={classes.menu}>
        <Text
          color="dark"
          weight="medium"
          className={classes.menuItemHeader}
          as="span"
          role="menuitem"
        >
          Region
        </Text>
        <Divider
          orientation="horizontal"
          role="separator"
          horizontalGutter={0}
        />
        {regionSelectorMenuItems.map(item => (
          <MenuItem
            className={classNames(classes.menuItem, {
              [classes.menuItemSelected]: currentRegion === item.children
            })}
            key={item.key}
            onClick={item.handleOnClick}
          >
            <img
              src={resolveImage(regionFile(item.children))}
              alt={regionFile(item.children)}
              height={15}
              width={15}
            />
            {item.children}
          </MenuItem>
        ))}
        <Text
          color="dark"
          weight="medium"
          className={classes.menuItemHeader}
          as="span"
          role="menuitem"
        >
          Language
        </Text>
        <Divider
          orientation="horizontal"
          role="separator"
          horizontalGutter={0}
        />
        {languageSelectorMenuItems.map(item => (
          <MenuItem
            className={classNames(classes.menuItem, {
              [classes.menuItemSelected]: currentLanguage === item.children
            })}
            key={item.key}
            onClick={item.handleOnClick}
          >
            <img
              src={resolveImage(languageFile(item.children))}
              alt={regionFile(item.children)}
              height={15}
              width={15}
            />
            {item.children}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default RegionAndLanguageSelectorMenu
