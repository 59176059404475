import React from 'react'
import { createUseStyles } from 'react-jss'

import Logo from 'components/base/Logo'
import Hidden from 'components/base/Hidden'
import { breakpointMax } from 'styles/mixins'

const useStyles = createUseStyles({
  navbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',

    [breakpointMax('xs')]: {
      justifyContent: 'center'
    }
  },
  buttons: {
    display: 'flex',

    '& > *': {
      marginLeft: 30
    }
  }
})

function Navbar({ children, ...props }) {
  const classes = useStyles(props)

  return (
    <nav className={classes.navbar}>
      <Logo />
      <Hidden xs>
        <div className={classes.buttons}>{children}</div>
      </Hidden>
    </nav>
  )
}

export default Navbar
