import { gql } from '@apollo/client'
import { useState } from 'react'
import { startCase } from 'lodash'

import useAppContext from 'hooks/useAppContext'

const AVAILABLE_TYPES = Object.freeze({
  enterprise: 'Enterprise',
  'integration-hub': 'IntegrationHub',
  'surgery-center': 'SurgeryCenter',
  'surgery-clinic': 'SurgeryClinic',
  'on-call-calendar': 'OnCallCalendar',
  'on-call': 'OnCall',
  'op-note': 'OpNote',
  voice: 'Voice',
  'portal-dictation': 'PortalDictation'
})

export const SOLUTIONS_QUERY = gql`
  query SolutionsQuery {
    solutions {
      id
      type
      usageAllowance
    }
  }
`

export const useSelectSolution = () => {
  const { solutions } = useAppContext()

  const [selectedSolution, setSelectedSolution] = useState(null)

  const solutionDropdownOptions = solutions.map(solution => {
    const aiNotesUsageAllowance =
      // eslint-disable-next-line no-nested-ternary
      solution?.type === 'AINote'
        ? solution?.usageAllowance
          ? `${Number(solution?.usageAllowance)?.toLocaleString()} min/month`
          : 'Staff'
        : ''
    const label = aiNotesUsageAllowance
      ? `${startCase(solution.type)} - ${aiNotesUsageAllowance}`
      : startCase(solution.type)

    return {
      label,
      value: solution.id
    }
  })

  const solutionCellOptions = solutions.map(solution => ({
    label: startCase(solution.type),
    value: solution.id
  }))

  return [
    solutionCellOptions,
    solutionDropdownOptions,
    selectedSolution,
    setSelectedSolution
  ]
}

export default {
  SOLUTIONS_QUERY,
  useSelectSolution,
  AVAILABLE_TYPES
}
