import React from 'react'

import LoginLayout from 'components/layouts/LoginLayout'
import ProtectedRoute from 'components/routes/ProtectedRoute'

function LoginRoute({ path, ...props }) {
  return (
    <ProtectedRoute
      layout={LoginLayout}
      requiredSessionState={ProtectedRoute.sessionStates.LOGGED_OUT}
      layoutProps={{ path }}
      path={path}
      {...props}
    />
  )
}

export default LoginRoute
