/* eslint-disable react/destructuring-assignment */
import React from 'react'

function checkChildrenType(props, propName, componentName, childType) {
  let error
  const children = props[propName]

  React.Children.forEach(children, child => {
    if (child.type.name !== childType) {
      error = new Error(
        `\`${componentName}\` only accepts children of type \`${childType}\`.`
      )
    }
  })

  return error
}

function checkRangeOf(props, propName, componentName, min, max) {
  let error
  const value = props[propName]

  if (value) {
    if (typeof value === 'number') {
      error =
        (value < min || value > max) &&
        new Error(
          `\`${propName}\` in \`${componentName}\` component is not within range of \`${min}\` to \`${max}\`.`
        )
    } else {
      error = new Error(
        `\`${propName}\` in \`${componentName}\` component must be a \`number\` within range of \`${min}\`
         to \`${max}\`.`
      )
    }
  }

  return error
}

function createCustomPropType(validate, ...rest) {
  function checkRequired(isRequired, props, propName, componentName) {
    let error

    if (props[propName] == null) {
      if (isRequired) {
        error = new Error(
          `Required prop \`${propName}\` was not specified in \`${componentName}\`.`
        )
      }

      return error
    }

    return validate(props, propName, componentName, ...rest)
  }

  const customPropType = checkRequired.bind(null, false)
  customPropType.isRequired = checkRequired.bind(null, true)
  return customPropType
}

const childrenOfType = createCustomPropType.bind(null, checkChildrenType)
const rangeOf = createCustomPropType.bind(null, checkRangeOf)

export { childrenOfType, rangeOf }
