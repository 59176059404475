import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import Button from 'components/buttons/Button'
import AuthTextInput from 'components/inputs/AuthTextInput'

import { isSurgicalNotesProduct } from 'lib/CheckProduct'
import validateSchema from 'lib/validators'

const createSchema = t =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('resetPasswordPage.form.fields.password.validationMessage')),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password'), null],
        t(
          'resetPasswordPage.form.fields.passwordConfirmation.validationMessage'
        )
      )
      .required(
        t('resetPasswordPage.form.fields.passwordConfirmation.requiredMessage')
      )
  })

function ResetPasswordForm({ onFormSubmit }) {
  const { t } = useTranslation()
  const schema = createSchema(t)

  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              component={AuthTextInput}
              name="password"
              placeholder={t(
                'resetPasswordPage.form.fields.password.placeholder'
              )}
              type="password"
              icon={isSurgicalNotesProduct() ? 'surgical_notes_lock' : 'lock'}
              showValidationMessage
            />
          </div>
          <div>
            <Field
              component={AuthTextInput}
              name="passwordConfirmation"
              placeholder={t(
                'resetPasswordPage.form.fields.passwordConfirmation.placeholder'
              )}
              type="password"
              icon={isSurgicalNotesProduct() ? 'surgical_notes_lock' : 'lock'}
              showValidationMessage
            />
          </div>
          <AuthFormFooter>
            <Button
              label={t('resetPasswordPage.form.buttons.resetPassword')}
              disabled={pristine || submitting}
            />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

ResetPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default ResetPasswordForm
