import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

import resolveImage from 'lib/resolveImage'

const widths = {
  horizontal_full_light: 310,
  vertical_full_light: 95,
  vertical_icon_dark: 95,
  vertical_icon_light: 45
}

const useStyles = createUseStyles({
  logo: {
    width: ({ variant }) => widths[variant]
  }
})

function Logo({ path, variant, ...props }) {
  const classes = useStyles({ variant })

  const logoFileName = `${import.meta.env.VITE_PRODUCT}_logo_${variant}.png`

  return (
    <Link to={path} {...props}>
      <img
        className={classNames(classes.logo, props.className)}
        src={resolveImage(logoFileName)}
        alt={logoFileName}
      />
    </Link>
  )
}

Logo.propTypes = {
  path: PropTypes.string,
  variant: PropTypes.oneOf([
    'horizontal_full_light',
    'vertical_full_light',
    'vertical_icon_light',
    'vertical_icon_dark'
  ])
}

Logo.defaultProps = {
  path: '/',
  variant: 'horizontal_full_light'
}

export default Logo
