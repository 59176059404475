import theme from 'styles/theme'
import vetbuddyTheme from 'styles/vetbuddyTheme'
import surgicalNotesTheme from 'styles/surgicalNotesTheme'

const PRODUCTS = Object.freeze({
  docbuddy: 'DocBuddy',
  vetbuddy: 'VetBuddy',
  surgical_notes: 'Surgical Notes'
})

const helmetItems = {
  [PRODUCTS.docbuddy]: {
    favIcon: '/docbuddyIcon.ico',
    touchIcon: 'docbuddyLogo192.png',
    manifest: '/docbuddyManifest.json'
  },
  [PRODUCTS.vetbuddy]: {
    favIcon: '/vetbuddyIcon.ico',
    touchIcon: 'vetbuddyLogo192.png',
    manifest: '/vetbuddyManifest.json'
  },
  [PRODUCTS.surgical_notes]: {
    favIcon: '/surgicalNotesIcon.ico',
    touchIcon: 'surgicalNotesLogo192.png',
    manifest: '/surgicalNotesManifest.json'
  }
}

function getCurrentProduct() {
  return PRODUCTS[import.meta.env.VITE_PRODUCT]
}

function isVetBuddyProduct() {
  return import.meta.env.VITE_PRODUCT === 'vetbuddy'
}

export function getProductTheme() {
  if (import.meta.env.VITE_PRODUCT === 'surgical_notes') {
    return surgicalNotesTheme
  }
  if (isVetBuddyProduct()) {
    return vetbuddyTheme
  }
  return theme
}

export function isSurgicalNotesProduct() {
  return import.meta.env.VITE_PRODUCT === 'surgical_notes'
}

export { getCurrentProduct, helmetItems, PRODUCTS }
export default isVetBuddyProduct
