import React from 'react'
import { createUseStyles } from 'react-jss'

import Column from 'components/base/Column'
import Logo from 'components/base/Logo'
import Row from 'components/base/Row'
import Stack from 'components/base/Stack'
import Text from 'components/typography/Text'

const useStyles = createUseStyles(({ colors }) => ({
  layout: {
    backgroundImage: `linear-gradient(to bottom right, ${colors.primary}, ${colors.primaryVivid}, ${colors.secondary})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: [ 50, 70 ]
  }
}))

function NotFoundPage() {
  const classes = useStyles()

  return (
    <div className={classes.layout}>
      <Row>
        <Column>
          <Text color="light" align="center">Not found</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <Stack justify="center">
            <Logo />
          </Stack>
        </Column>
      </Row>
    </div>
  )
}

export default NotFoundPage
