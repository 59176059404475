import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'

import columnWidth from 'lib/grid'
import { breakpointMax } from 'styles/mixins'
import { rangeOf } from 'lib/customPropTypes'

const useStyles = createUseStyles(({ colors }) => ({
  column: {
    borderLeft: props => props.withSeparator && `1px solid ${colors.greyLight}`,
    height: ({ stretch }) => (stretch ? '100%' : 'auto'),
    textAlign: ({ align }) => align,
    width: ({ span }) => columnWidth(span),
    alignSelf: ({ alignSelf }) => alignSelf,

    [breakpointMax('lg')]: {
      display: ({ hiddenLg }) => hiddenLg && 'none',
      marginRight: () => 0,
      width: ({ spanLg }) => spanLg && columnWidth(spanLg)
    },

    [breakpointMax('md')]: {
      display: ({ hiddenMd }) => hiddenMd && 'none',
      marginRight: () => 0,
      width: ({ spanMd }) => spanMd && columnWidth(spanMd)
    },

    [breakpointMax('xs')]: {
      display: ({ hiddenXs }) => hiddenXs && 'none',
      marginRight: () => 0,
      width: ({ spanXs }) => columnWidth(spanXs)
    }
  }
}))

const Column = forwardRef(({ children, ...props }, ref) => {
  const classes = useStyles(props)

  return (
    <div className={classes.column} ref={ref}>
      {children}
    </div>
  )
})

Column.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  alignSelf: PropTypes.oneOf([
    'auto',
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'baseline',
    'initial',
    'inherit'
  ]),
  hiddenLg: PropTypes.bool,
  hiddenMd: PropTypes.bool,
  hiddenXs: PropTypes.bool,
  span: rangeOf(0, 12),
  spanLg: rangeOf(0, 12),
  spanMd: rangeOf(0, 12),
  spanXs: rangeOf(0, 12),
  stretch: PropTypes.bool,
  withSeparator: PropTypes.bool
}

Column.defaultProps = {
  align: null,
  alignSelf: 'auto',
  hiddenLg: false,
  hiddenMd: false,
  hiddenXs: false,
  span: 12,
  spanLg: undefined,
  spanMd: undefined,
  spanXs: 12,
  stretch: false,
  withSeparator: false
}

export default Column
