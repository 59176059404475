import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import TextLink from 'components/links/TextLink'
import {
  breakpointMax,
  makeRoundedCorners,
  transitionFluid
} from 'styles/mixins'
import { isSurgicalNotesProduct } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  navButton: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',

    '&:hover > $background': {
      background: isSurgicalNotesProduct()
        ? colors.surgicalNotesBlue
        : colors.secondary
    }
  },
  background: {
    ...makeRoundedCorners(16),
    ...transitionFluid(),

    backgroundColor: isSurgicalNotesProduct()
      ? colors.secondary
      : colors.primaryVivid,
    position: 'absolute',
    width: 180,
    height: 70,
    zIndex: zIndexes.background,

    [breakpointMax('md')]: {
      width: ({ compact }) => compact && 'auto'
    }
  }
}))

function NavButton(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.navButton}>
      <div className={classes.background} />
      <TextLink color="tertiary" size="xSmall" {...props} />
    </div>
  )
}

NavButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired
}

export default NavButton
