import { gql } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Loader from 'components/loaders/Loader'
import useMutation from 'hooks/useMutation'
import useURLParams from 'hooks/useURLParams'

export const CREATE_OAUTH_SESSION_MUTATION = gql`
  mutation CreateOauthSessionMutation($input: CreateOauthSessionInput!) {
    createOauthSession(input: $input) {
      id
      accessToken
      dashxToken
      expiresAt
      status
      account {
        id
        email
      }
    }
  }
`

function OauthRedirect({ handleSetTokens }) {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const urlParams = useURLParams()

  const [createOauthSession] = useMutation(CREATE_OAUTH_SESSION_MUTATION, {
    onCompleted: response => {
      const createdSession = response.createOauthSession
      handleSetTokens(createdSession)
    }
  })

  useEffect(() => {
    const code = urlParams.get('code')
    const state = urlParams.get('state')

    if (code && state) {
      setIsLoading(true)
      const loginParameters = { code, state }
      createOauthSession(loginParameters).finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  if (isLoading) return <Loader />
  return null
}

export default OauthRedirect
