import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import FontIcon from 'components/base/FontIcon'
import Text from 'components/typography/Text'
import {
  makeRoundedCorners,
  transitionSimple,
  breakpointMax
} from 'styles/mixins'
import { isSurgicalNotesProduct } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ colors }) => ({
  selectionCard: {
    ...makeRoundedCorners(16),
    ...transitionSimple(),

    alignItems: 'center',
    background: colors.primary,
    cursor: 'pointer',
    display: 'flex',
    height: 70,
    justifyContent: 'space-between',
    marginBottom: 10,
    marginLeft: 20,
    paddingLeft: 80,
    paddingRight: 20,
    position: 'relative',

    '&:last-of-type': {
      marginBottom: 0
    },

    '&:hover': {
      '& $icon': {
        background: isSurgicalNotesProduct()
          ? colors.tertiary
          : colors.secondaryVivid,

        ...(isSurgicalNotesProduct() && { color: colors.light })
      },

      '& $subtitle': {
        color: isSurgicalNotesProduct() ? colors.light : colors.tertiary
      }
    },

    [breakpointMax('xs')]: {
      paddingLeft: 65
    }
  },
  subtitle: {
    ...transitionSimple()
  },
  icon: {
    ...makeRoundedCorners(16),
    ...transitionSimple(),

    alignItems: 'center',
    background: colors.secondary,
    boxShadow: `0px 10px 20px ${colors.dark}`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: -20,
    position: 'absolute',
    width: 70
  }
}))

function SelectionCard({ account, icon, accessoryIcon, onClick, path }) {
  const classes = useStyles()

  return (
    <div
      className={classes.selectionCard}
      onClick={() => onClick && onClick(account, path)}
      onKeyPress={() => onClick && onClick(account, path)}
      role="button"
      tabIndex={0}
    >
      <div>
        <Text
          data-test="organization-name"
          color="light"
          weight="semibold"
          data-testid="organization-name"
        >
          {account.organizationName}
        </Text>
        <Text
          className={classes.subtitle}
          color="primaryPale"
          size="xSmall"
          weight="medium"
        >
          {account.email}
        </Text>
      </div>
      <FontIcon
        className={classes.icon}
        color="tertiary"
        name={icon}
        size="xxLarge"
      />
      {accessoryIcon && (
        <FontIcon color="positive" name={accessoryIcon} size="normal" />
      )}
    </div>
  )
}

SelectionCard.propTypes = {
  accessoryIcon: PropTypes.string,
  account: PropTypes.shape({
    organizationName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  path: PropTypes.string.isRequired
}

SelectionCard.defaultProps = {
  accessoryIcon: null,
  icon: 'group',
  onClick: null
}

export default SelectionCard
