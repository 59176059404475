import { createPopper } from '@popperjs/core'
import { useEffect, useRef } from 'react'

function usePopper(options) {
  const popperInstance = useRef(null)

  const targetRef = useRef(null)
  const popupRef = useRef(null)
  const arrowRef = useRef(null)

  useEffect(() => {
    if (arrowRef.current) {
      options.modifiers.push(
        {
          name: 'arrow',
          options: {
            element: arrowRef.current
          }
        }
      )
    }

    if (targetRef.current && popupRef.current) {
      popperInstance.current = createPopper(
        targetRef.current,
        popupRef.current,
        options
      )
    }

    return () => {
      if (popperInstance.current) {
        popperInstance.current.destroy()
      }
    }
  }, [ options ])

  useEffect(() => {
    if (popperInstance.current) {
      popperInstance.current.forceUpdate()
    }
  })

  return { targetRef, popupRef, arrowRef }
}

export default usePopper
