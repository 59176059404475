import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import {
  ADD_ACCOUNT_TOKEN,
  ADD_EMAIL,
  GET_AUTH,
  LOGIN
} from 'client/state/auth'
import dx from 'lib/dashx'
import useClientMutation from 'hooks/useClientMutation'
import useClientQuery from 'hooks/useClientQuery'

function ExternalLoginRoute({ render }) {
  const [login] = useClientMutation(LOGIN)
  const [addAccountToken] = useClientMutation(ADD_ACCOUNT_TOKEN)
  const { data: { auth: { accountTokens, currentToken } } = {} } =
    useClientQuery(GET_AUTH)
  const [session, setSession] = useState(null)
  const [addEmail] = useClientMutation(ADD_EMAIL)

  const handleSetTokens = createdSession => {
    const {
      accessToken,
      account: { id: accountId, email },
      dashxToken
    } = createdSession

    setSession(createdSession)
    addEmail({ email })
    addAccountToken({ accountId, dashxToken, token: accessToken })

    if (accountTokens.length > 0) {
      dx.setIdentity(accountId, dashxToken)
      login({ accountId })
    }
  }

  useEffect(() => {
    if (
      session &&
      accountTokens.find(token => token.accountId === session.account.id) &&
      currentToken !== session.accessToken
    ) {
      const {
        account: { id: accountId },
        dashxToken
      } = session

      dx.setIdentity(accountId, dashxToken)
      login({ accountId })
    }
  }, [session, accountTokens, currentToken, login])

  return (
    <Route
      render={() =>
        render({
          handleSetTokens: createdSession => handleSetTokens(createdSession)
        })
      }
    />
  )
}

export default ExternalLoginRoute
