/* eslint-disable prefer-rest-params */
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const { VITE_GA_CONVERSION_ID } = import.meta.env

function GATagGlobal() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', `AW-${VITE_GA_CONVERSION_ID}`)
  }, [])

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=AW-${VITE_GA_CONVERSION_ID}`}
      />
    </Helmet>
  )
}

function GATagConversionEvent() {
  function gtagReportConversion(url) {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }

    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url
      }
    }

    gtag('event', 'conversion', {
      send_to: `AW-${VITE_GA_CONVERSION_ID}/w5jNCLq02-wBELLmr7wC`,
      event_callback: callback
    })

    return false
  }

  useEffect(() => {
    gtagReportConversion()
  }, [])

  return <Helmet />
}

export { GATagConversionEvent, GATagGlobal }
