/* eslint-disable no-nested-ternary */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Badge from 'components/badge/Badge'
import FontIcon from 'components/base/FontIcon'
import Text from 'components/typography/Text'
import rgba from 'lib/rgba'
import { makeRoundedCorners } from 'styles/mixins'

const useStyles = createUseStyles(({ colors }) => ({
  container: {
    marginBottom: 20,
    paddingTop: '100%',
    position: 'relative',
    width: '100%'
  },
  profilePicture: {
    ...makeRoundedCorners(16),

    alignItems: 'center',
    backgroundColor: colors.secondaryLight,
    backgroundImage: src => `url(${src})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    boxShadow: `0px 10px 20px ${rgba(colors.primary, 0.13)}`,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: 50,
    width: 50
  },
  profilePicture_bordered: {
    border: `2px solid ${colors.light}`
  },
  profilePicture_icon: {
    ...makeRoundedCorners(30),

    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%'
  },
  badge: {
    position: 'absolute',
    right: -3,
    top: -4
  }
}))

function ProfilePicture({
  badge,
  bordered,
  includeIcon,
  src,
  currentAccount,
  ...props
}) {
  const classes = useStyles(src)

  const userFirstInitial = currentAccount?.firstName[0]
  const userLastInitial = currentAccount?.lastName[0]
  const hasInitials =
    !!currentAccount?.firstName[0] && !!currentAccount?.lastName[0]

  const profileImage = (
    <div
      className={classNames(classes.profilePicture, {
        [classes.profilePicture_bordered]: bordered,
        [classes.profilePicture_icon]: includeIcon
      })}
      {...props}
    >
      {!src && !includeIcon ? (
        hasInitials ? (
          <Text size="large" weight="medium">
            {userFirstInitial}
            {userLastInitial}
          </Text>
        ) : (
          <FontIcon name="avatar" size="xxLarge" />
        )
      ) : (
        <FontIcon name="avatar-big" size="xxxLarge" />
      )}
      {badge && <Badge className={classes.badge} />}
    </div>
  )

  const largeProfileIconImage = (
    <div className={classes.container}>{profileImage}</div>
  )

  return includeIcon ? largeProfileIconImage : profileImage
}

ProfilePicture.propTypes = {
  badge: PropTypes.bool,
  bordered: PropTypes.bool,
  includeIcon: PropTypes.bool,
  src: PropTypes.string
}

ProfilePicture.defaultProps = {
  badge: false,
  bordered: false,
  includeIcon: false,
  src: null
}

export default ProfilePicture
