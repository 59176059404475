import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { switcherLanguageAcronyms } from 'models/Account'

import en from './en.json'
import fr from './fr.json'

const defaultLanguage = 'en'

const currentLanguage =
  switcherLanguageAcronyms[localStorage.getItem('DB_USER_LANGUAGE')] ||
  defaultLanguage

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr }
  },
  lng: currentLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false // React already escapes values to prevent XSS
  }
})

export default i18n
