import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/buttons/Button'
import Stack from 'components/base/Stack'
import Text from 'components/typography/Text'
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  ModalContent
} from 'components/modals/base'

function ConfirmationModal({
  content,
  subContents,
  isDisabled,
  labelCancel,
  labelSubmit,
  onSubmit,
  onCancel,
  variant,
  ...props
}) {
  return (
    <BaseModal width={380} id="confirmation-modal" variant="compact" {...props}>
      <ModalHeader background={variant} />
      <ModalContent>
        <Text align="center" color="dark" size="medium" weight="semibold">
          {content}
        </Text>
        {!!subContents?.length && <br />}
        {subContents?.map(subContent => (
          <Text
            key={subContent}
            align="center"
            color="dark"
            size="medium"
            weight="semibold"
          >
            {subContent}
          </Text>
        ))}
      </ModalContent>
      <ModalFooter>
        <Stack>
          <Button
            type="button"
            color="primary"
            disabled={isDisabled}
            variant="outline"
            data-testid="no-btn"
            label={labelCancel || 'No'}
            size="large"
            onClick={onCancel}
          />
          {onSubmit && (
            <Button
              color={variant}
              disabled={isDisabled}
              type="submit"
              data-testid="yes-btn"
              label={labelSubmit || 'Yes'}
              size="large"
              onClick={onSubmit}
              isLoading={props.isLoading}
            />
          )}
        </Stack>
      </ModalFooter>
    </BaseModal>
  )
}

ConfirmationModal.propTypes = {
  content: PropTypes.string.isRequired,
  subContents: PropTypes.array,
  isDisabled: PropTypes.bool,
  variant: PropTypes.oneOf(['tertiary', 'negative']),
  onCancel: PropTypes.func.isRequired,
  labelSubmit: PropTypes.string,
  labelCancel: PropTypes.string
}

ConfirmationModal.defaultProps = {
  isDisabled: false,
  subContents: null,
  variant: 'negative',
  labelSubmit: 'Yes',
  labelCancel: 'No'
}

export default ConfirmationModal
