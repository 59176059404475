import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import alerts from 'constants/alerts'
import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import LoginForm from 'components/forms/LoginForm'
import Row from 'components/base/Row'
import useClientQuery from 'hooks/useClientQuery'
import useClientMutation from 'hooks/useClientMutation'
import useLazyQuery from 'hooks/useLazyQuery'
import useURLParams from 'hooks/useURLParams'
import { showAlertFailure } from 'client/methods'

import { ACCOUNTS_INFO_QUERY } from 'models/Account'
import { ADD_EMAIL, GET_AUTH } from 'client/state/auth'

function LoginPage() {
  const { t } = useTranslation()
  const history = useHistory()
  const urlParams = useURLParams()
  const referrer = urlParams.get('referrer')
  const invalidExternalAuthentication = urlParams.get('authentication')

  const { data: { auth: { emails } = {} } = {} } = useClientQuery(GET_AUTH)
  const [addEmail] = useClientMutation(ADD_EMAIL)
  const [email, setEmail] = useState(null)

  const [getAccountsInfo, { data: { accountsInfo } = {} }] = useLazyQuery(
    ACCOUNTS_INFO_QUERY,
    {
      onCompleted: response => {
        if (response?.accountsInfo?.length === 0) {
          showAlertFailure({ message: alerts.no_account })
        } else {
          addEmail({ email })
        }
      }
    }
  )

  const getHistoryObjectWithReferrer = useCallback(
    ({ pathname, state }) => ({
      pathname,
      ...(state ? { state } : {}),
      ...(referrer ? { search: `?referrer=${referrer}` } : {})
    }),
    [referrer]
  )

  useEffect(() => {
    if (invalidExternalAuthentication && !email) {
      showAlertFailure({ message: alerts[invalidExternalAuthentication] })
    }

    if (accountsInfo && emails?.includes(email)) {
      if (accountsInfo.length === 1) {
        const account = accountsInfo[0]

        if (account.status === 'invited') {
          history.push(
            getHistoryObjectWithReferrer({
              pathname: '/verification',
              state: { account }
            })
          )
        } else if (account.status === 'not_invited') {
          showAlertFailure({ message: alerts.not_invited })
        } else if (account.status === 'inactive') {
          showAlertFailure({ message: alerts.inactive })
        } else {
          history.push(
            getHistoryObjectWithReferrer({
              pathname: '/organization-login',
              state: { account }
            })
          )
        }
      } else {
        history.push(
          getHistoryObjectWithReferrer({
            pathname: '/choose-organization'
          })
        )
      }
    }
  }, [
    accountsInfo,
    emails,
    email,
    history,
    invalidExternalAuthentication,
    getHistoryObjectWithReferrer
  ])

  const handleEmailSubmitted = values => {
    setEmail(values.email)
    getAccountsInfo({ variables: { emails: [values.email] } })
  }

  return (
    <Fragment>
      <AuthTitle
        title={t('loginPage.title')}
        subtitle={t('loginPage.subtitle')}
      />
      <Row>
        <Column span={3} hiddenMd hiddenXs />
        <Column span={6} spanMd={12}>
          <Card variant="secondary">
            <LoginForm onFormSubmit={handleEmailSubmitted} />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default LoginPage
