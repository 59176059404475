/* eslint-disable */
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const { VITE_PENDO_KEY, VITE_ENV } = import.meta.env

function Pendo({ currentAccount, isLoggedIn }) {
  useEffect(() => {
    function installPendo(apiKey) {
      ;(function (p, e, n, d, o) {
        var v, w, x, y, z
        o = p[d] = p[d] || {}
        o._q = o._q || []

        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                )
              }
          })(v[w])

        y = e.createElement(n)
        y.async = !0
        y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`

        z = e.getElementsByTagName(n)[0]
        z.parentNode.insertBefore(y, z)
      })(window, document, 'script', 'pendo')
    }
    installPendo(VITE_PENDO_KEY)
  }, [])

  return (
    isLoggedIn && (
      <Helmet>
        <script type="application/javascript">
          {window.pendo?.initialize({
            visitor: {
              id: `${VITE_ENV}-${currentAccount?.id}`,
              name: currentAccount?.fullName,
              email: currentAccount?.email
            },
            account: {
              id: `${VITE_ENV}-${currentAccount?.organization?.id}`,
              name: currentAccount?.organization?.name
            }
          })}
        </script>
      </Helmet>
    )
  )
}

export default Pendo
