import { debounce } from 'lodash'
import { useState, useRef } from 'react'

import useLazyQuery from 'hooks/useLazyQuery'
import { useFilterState } from 'contexts/FilterContext'

const debounceSearchMS = 500

function useSearchInput(
  searchQuery,
  searchVariableFn,
  dataToOptions,
  filterKey,
  initialOption
) {
  const [searchInput, setSearchInput] = useState(null)
  const [selectedOption, setSelectedOption] = useFilterState(
    filterKey,
    initialOption
  )
  const searchCallback = useRef(null)

  const [fetchData] = useLazyQuery(searchQuery, {
    variables: {
      ...searchVariableFn(searchInput)
    },
    onCompleted(data) {
      const options = dataToOptions(data)
      searchCallback.current(options)
    }
  })

  const loadData = useRef(
    debounce((value, callback) => {
      setSearchInput(value)
      searchCallback.current = callback
      fetchData()
    }, debounceSearchMS)
  )

  return [loadData.current, selectedOption, setSelectedOption]
}

export default useSearchInput
