import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  modalContent: {
    maxHeight: ({ fixed }) => (fixed ? '100%' : '60vh'),
    overflowY: 'auto',
    paddingBottom: ({ fixed }) => fixed && 100
  }
})

function ModalFormContent({ children, fixed }) {
  const classes = useStyles({ fixed })

  return <div className={classes.modalContent}>{children}</div>
}

export default ModalFormContent
