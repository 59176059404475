import React from 'react'
import { Form, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'

import validateSchema from 'lib/validators'
import * as yup from 'yup'

const createSchema = t =>
  yup.object().shape({
    verificationCode: yup
      .string()
      .required(
        t(
          'accountVerificationPage.form.fields.verificationCode.validationMessage'
        )
      )
  })

function AccountVerificationForm({ onFormSubmit }) {
  const { t } = useTranslation()
  const schema = createSchema(t)

  return (
    <Form
      onSubmit={onFormSubmit}
      validate={validateSchema(schema)}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              component={AuthTextInput}
              name="verificationCode"
              placeholder={t(
                'accountVerificationPage.form.fields.verificationCode.placeholder'
              )}
              showValidationMessage
            />
          </div>
          <AuthFormFooter>
            <Button label={t('accountVerificationPage.form.buttons.submit')} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

export default AccountVerificationForm
