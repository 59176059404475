import { gql } from '@apollo/client'
import { DateTime } from 'luxon'
import { findIndex, isEqual, unionWith } from 'lodash'
import { useContext } from 'react'

import AppContext from 'contexts/AppContext'
import findByKey from 'lib/findByKey'
import useQuery from 'hooks/useQuery'

export const ACCESSIBLE_WORKFLOW_ITEMS_QUERY = gql`
  query AppQuery {
    currentAccount {
      id
      accessibleWorkflowItemIds
    }
  }
`

export const USER_ACCESSIBLE_WORKFLOW_ITEMS_QUERY = gql`
  query UserQuery($id: ID!) {
    user(id: $id) {
      id
      accessibleWorkflowItemIds
    }
  }
`

export const WORKFLOW_ITEMS_QUERY = gql`
  query AppointmentWorkflowItemsQuery {
    workflowItems {
      id
      category
      name
      position
      isShared
      organization {
        id
      }
    }
  }
`

export const CREATE_APPOINTMENT_WORKFLOW_ITEM_MUTATION = gql`
  mutation createAppointmentWorkflowItem(
    $id: ID!
    $input: CreateAppointmentWorkflowItemInput!
  ) {
    createAppointmentWorkflowItem(id: $id, input: $input) {
      id
      status
      workflowItem {
        id
        name
        category
        position
        isShared
      }
    }
  }
`

export const UPDATE_APPOINTMENT_WORKFLOW_ITEM_MUTATION = gql`
  mutation updateAppointmentWorkflowItem(
    $id: ID!
    $input: UpdateAppointmentWorkflowItemInput!
  ) {
    updateAppointmentWorkflowItem(id: $id, input: $input) {
      id
      status
      workflowItem {
        id
        name
        category
        position
        isShared
      }
    }
  }
`

export const workflowCategories = Object.freeze([
  {
    icon: 'pre-surgery-workflow',
    key: 'pre_surgery',
    label: 'Pre-Surgery',
    value: 'pre_surgery'
  },
  {
    icon: 'post-surgery',
    key: 'post_surgery',
    label: 'Post-Surgery',
    value: 'post_surgery'
  }
])

const defaultWorkflowStatuses = Object.freeze({
  pending: 'pending',
  in_progress: 'in_progress',
  info_needed: 'info_needed',
  urgent: 'urgent',
  complete: 'complete'
})

export const workflowStatusColors = Object.freeze({
  pending: 'accentGray',
  in_progress: 'accentBlue',
  info_needed: 'accentYellow',
  urgent: 'accentRed',
  complete: 'accentGreen'
})

export const workflowStatuses = Object.freeze([
  {
    borderColor: 'accentGrayPale',
    coreColor: 'accentGray',
    icon: 'window-add',
    key: defaultWorkflowStatuses.pending,
    label: 'Pending'
  },
  {
    borderColor: 'accentBluePale',
    coreColor: 'accentBlue',
    icon: 'progress-indicator',
    key: defaultWorkflowStatuses.in_progress,
    label: 'In Progress'
  },
  {
    borderColor: 'accentYellowPale',
    coreColor: 'accentYellow',
    icon: 'warning-small',
    key: defaultWorkflowStatuses.info_needed,
    label: 'More Info Needed'
  },
  {
    borderColor: 'accentRedPale',
    coreColor: 'accentRed',
    icon: 'error-small',
    key: defaultWorkflowStatuses.urgent,
    label: 'Urgent Action Needed'
  },
  {
    borderColor: 'accentGreenPale',
    coreColor: 'accentGreen',
    icon: 'success-small',
    key: defaultWorkflowStatuses.complete,
    label: 'Complete'
  }
])

export function useDefaultWorkflowItems(
  { onlyCurrent } = { onlyCurrent: false }
) {
  const { currentAccount } = useContext(AppContext)
  const currentOrganization = currentAccount && currentAccount.organization

  const { data: { workflowItems } = {}, loading } = useQuery(
    WORKFLOW_ITEMS_QUERY,
    {
      fetchPolicy: 'cache-first'
    }
  )

  let filteredWorkflowItems = workflowItems
  if (onlyCurrent) {
    filteredWorkflowItems =
      workflowItems?.filter(
        workflowItem => workflowItem.organization.id === currentOrganization.id
      ) || []
  }

  return loading ? [[]] : [filteredWorkflowItems]
}

export function overallWorkflowStatus(
  appointmentWorkflowItems,
  defaultWorkflowItems,
  appointmentTime
) {
  const isFutureAppointment = DateTime.fromSQL(appointmentTime || "") >= DateTime.now()
  const defaultPreSurgeryWorkflowItems = defaultWorkflowItems.filter(
    item => item.category === 'pre_surgery'
  )
  const preSurgeryAppointmentWorkflowItems = appointmentWorkflowItems.filter(
    item => item.workflowItem.category === 'pre_surgery'
  )

  const totalItemsCount = isFutureAppointment
    ? defaultPreSurgeryWorkflowItems.length
    : defaultWorkflowItems.length
  const activeItemsCount = isFutureAppointment
    ? preSurgeryAppointmentWorkflowItems.length
    : appointmentWorkflowItems.length
  const appointmentWorkflowItemsStatuses = isFutureAppointment
    ? preSurgeryAppointmentWorkflowItems.map(item => item.status)
    : appointmentWorkflowItems.map(item => item.status)

  const countOccurrences = (array, value) =>
    array.reduce(
      (accumulator, item) => (item === value ? accumulator + 1 : accumulator),
      0
    )

  const statusFrequency = Object.values(defaultWorkflowStatuses).reduce(
    (accumulator, status) => {
      const occurences = countOccurrences(
        appointmentWorkflowItemsStatuses,
        status
      )
      if (status === defaultWorkflowStatuses.pending) {
        accumulator[status] = occurences + totalItemsCount - activeItemsCount
      } else {
        accumulator[status] = occurences
      }

      return accumulator
    },
    {}
  )

  if (statusFrequency.urgent > 0) {
    return defaultWorkflowStatuses.urgent
  }
  if (statusFrequency.info_needed > 0) {
    return defaultWorkflowStatuses.info_needed
  }
  if (statusFrequency.in_progress > 0) {
    return defaultWorkflowStatuses.in_progress
  }
  if (statusFrequency.complete === totalItemsCount) {
    return defaultWorkflowStatuses.complete
  }

  return defaultWorkflowStatuses.pending
}

export function mergeWorkflowItems(
  appointmentWorkflowItems,
  defaultWorkflowItems,
  options = {}
) {
  const { filterItemsWithIds = [] } = options
  const hasFilterableIds = Boolean(filterItemsWithIds.length)

  const filteredDefaultWorkflowItems = defaultWorkflowItems.map(item => {
    if (hasFilterableIds && filterItemsWithIds.includes(Number(item.id))) {
      return { ...item, readOnly: false }
    }

    return { ...item, readOnly: true }
  })

  const workflowItemsByCategories = filteredDefaultWorkflowItems
    .map(workflowItem => {
      const appointmentWorkflowItem = appointmentWorkflowItems.find(
        item => item.workflowItem.id === workflowItem.id
      )
      const workflowItemStatusProps = findByKey(
        workflowStatuses,
        appointmentWorkflowItem ? appointmentWorkflowItem.status : 'pending'
      )
      return {
        workflowItemId: appointmentWorkflowItem && appointmentWorkflowItem.id,

        ...workflowItem,
        ...workflowItemStatusProps
      }
    })
    .reduce(
      (accumulator, workflowItem) => {
        accumulator
          .find(
            ({ workflowCategory }) =>
              workflowCategory.key === workflowItem.category
          )
          .workflowItems.push(workflowItem)

        return accumulator
      },
      workflowCategories.map(workflowCategory => ({
        workflowCategory,
        workflowItems: []
      }))
    )
  return workflowItemsByCategories
}

export function handleCacheUpdate(
  currentRecords,
  responseRecords,
  selectedRecord
) {
  const hasMultipleAppts = Array.isArray(currentRecords?.appointments)
  const isAppointmentsPage = Object.prototype.hasOwnProperty.call(
    currentRecords,
    `appointment${hasMultipleAppts ? 's' : ''}`
  )
  const isPatientsPage = Object.prototype.hasOwnProperty.call(
    currentRecords,
    'patient'
  )

  if (isAppointmentsPage) {
    const appointmentToUpdateIndex =
      hasMultipleAppts &&
      findIndex(currentRecords.appointments, {
        id: selectedRecord.id
      })
    const appointmentToUpdate = hasMultipleAppts
      ? currentRecords.appointments[appointmentToUpdateIndex]
      : currentRecords.appointment

    appointmentToUpdate.appointmentWorkflowItems = unionWith(
      appointmentToUpdate.appointmentWorkflowItems,
      responseRecords,
      isEqual
    )

    if (hasMultipleAppts) {
      currentRecords.appointments[appointmentToUpdateIndex] =
        appointmentToUpdate
    } else {
      currentRecords.appointment = appointmentToUpdate
    }
  }

  if (isPatientsPage) {
    const appointmentToUpdateIndex = findIndex(
      currentRecords.patient.appointments,
      { id: selectedRecord.id }
    )
    const appointmentToUpdate =
      currentRecords.patient.appointments[appointmentToUpdateIndex]

    appointmentToUpdate.appointmentWorkflowItems = unionWith(
      appointmentToUpdate.appointmentWorkflowItems,
      responseRecords,
      isEqual
    )

    currentRecords.patient.appointments[appointmentToUpdateIndex] =
      appointmentToUpdate
  }

  return currentRecords
}

export default {
  WORKFLOW_ITEMS_QUERY,
  USER_ACCESSIBLE_WORKFLOW_ITEMS_QUERY,
  workflowCategories,
  workflowStatuses,
  CREATE_APPOINTMENT_WORKFLOW_ITEM_MUTATION,
  handleCacheUpdate,
  mergeWorkflowItems,
  overallWorkflowStatus,
  UPDATE_APPOINTMENT_WORKFLOW_ITEM_MUTATION,
  useDefaultWorkflowItems,
  workflowStatusColors
}
