import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import { breakpointMax, breakpointMin } from 'styles/mixins'

const useStyles = createUseStyles({
  xs_hidden: {
    [breakpointMax('xs')]: {
      display: 'none'
    }
  },
  sm_hidden: {
    [breakpointMax('sm')]: {
      display: 'none'
    }
  },
  md_hidden: {
    [breakpointMax('md')]: {
      display: 'none'
    }
  },
  lg_hidden: {
    [breakpointMin('xs')]: {
      display: 'none'
    }
  }
})

function Hidden({ children, md, sm, xs, lg }) {
  const classes = useStyles()

  const className = (xs && 'xs') || (sm && 'sm') || (md && 'md') || (lg && 'lg') || 'sm'

  return <div className={classes[`${className}_hidden`]}>{children}</div>
}

Hidden.propTypes = {
  lg: PropTypes.bool,
  md: PropTypes.bool,
  sm: PropTypes.bool,
  xs: PropTypes.bool
}

Hidden.defaultProps = {
  lg: true,
  md: false,
  sm: false,
  xs: false
}

export default Hidden
