import { ApolloLink } from '@apollo/client'

const clientTypeLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext()
  if (context.includeClientType) {
    context.headers = context.headers || {}
    context.headers['X-Client-Platform'] = 'web'
    context.headers['X-Client-Version'] = import.meta.env.VITE_VERSION
  }
  operation.setContext(context)

  return forward(operation)
})

export default clientTypeLink
