import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import InviteProvidersForm from 'components/forms/InviteProvidersForm'
import Organization from 'models/Organization'
import Row from 'components/base/Row'
import useMutation from 'hooks/useMutation'

export const UPDATE_INVITATIONS_MUTATION = gql`
  mutation updateInvitationsMutation($input: UpdateInvitationsInput!) {
    updateInvitations(input: $input) {
      email
      firstName
      lastName
      phoneNumber
    }
  }
`

function InviteProvidersPage({
  location: { state: { emr = {}, role = '' } = {} } = {}
}) {
  const history = useHistory()
  const { token } = useParams()

  const handleSkip = () =>
    history.push({
      pathname: `/setup/${token}/password`
    })

  const [updateInvitations] = useMutation(UPDATE_INVITATIONS_MUTATION, {
    onCompleted: handleSkip
  })

  const handleUpdateInvitations = values => {
    const updateParameters = {
      ...values,

      token
    }

    return updateInvitations(updateParameters)
  }

  const requiresUsername = Organization.isEmr(emr)
  const count = role === 'provider' ? 2 : 3

  return (
    <Fragment>
      <AuthTitle title={`Invite Up To ${count} Providers.`} />
      <Row>
        <Column span={requiresUsername ? 1 : 3} />
        <Column span={requiresUsername ? 9 : 6} spanMd={12}>
          <Card variant="secondary">
            <InviteProvidersForm
              role={role}
              requiresUsername={requiresUsername}
              submitLabel="Next"
              onFormSubmit={handleUpdateInvitations}
              onSkip={handleSkip}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default InviteProvidersPage
