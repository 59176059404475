import '../custom-xhr'

import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import Root from 'Root'
import 'assets/stylesheets/main.css'
import './locales/i18n'

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root')
)
