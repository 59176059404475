import React from 'react'

import SignupLayout from 'components/layouts/SignupLayout'
import ProtectedRoute from 'components/routes/ProtectedRoute'
import useScrollToTop from 'hooks/useScrollToTop'

function SignupRoute(props) {
  useScrollToTop()

  return (
    <ProtectedRoute
      layout={SignupLayout}
      requiredSessionState={ProtectedRoute.sessionStates.LOGGED_OUT}
      {...props}
    />
  )
}

export default SignupRoute
