import { ApolloClient, from } from '@apollo/client'

import cache from 'client/cache'
import links, { createHttpLink } from 'client/links'
import { defaults, resolvers, typeDefs } from 'client/state'
import { GET_ALERT } from 'client/state/alert'
import { GET_AUTH } from 'client/state/auth'

function writeInitialData() {
  cache.writeQuery({ query: GET_ALERT, data: defaults })
  cache.writeQuery({ query: GET_AUTH, data: defaults })
}

const createClient = region => {
  const httpLink = createHttpLink(region)

  const client = new ApolloClient({
    link: from([...links, httpLink]),
    cache,
    resolvers,
    typeDefs
  })

  // Initialize the cache with default data
  writeInitialData()

  // Set up cache reset and clear handlers
  client.onResetStore(writeInitialData)
  client.onClearStore(writeInitialData)

  return client
}

export default createClient
