import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

import Badge from 'components/badge/Badge'
import FontIcon from 'components/base/FontIcon'

const useStyles = createUseStyles(({ colors, blockColors }) => ({
  notifications: {
    cursor: 'pointer',
    position: 'absolute',
    top: 40,
    right: 90
  },
  icon: {
    color: blockColors.linkWater,

    '&:hover:not(:disabled), &:focus:not(:disabled)': {
      color: colors.primary
    }
  },
  badge: {
    position: 'absolute',
    right: -2,
    top: -5
  }
}))

function NotificationsMenu({ hasUnreadNotifications }) {
  const classes = useStyles()

  return (
    <div className={classes.notifications}>
      <Link to="/inbox/notifications" aria-label="Notifications">
        <FontIcon className={classes.icon} name="notification" size="xxLarge" />
        {hasUnreadNotifications && <Badge className={classes.badge} />}
      </Link>
    </div>
  )
}

export default NotificationsMenu
