import { useContext } from 'react'

import AppContext from 'contexts/AppContext'

export const permissions = Object.freeze({
  MANAGE_AVAILABILITIES: 'manage_availabilities',
  VIEW_CALENDAR_AVAILABILITIES: 'view_calendar_availabilities',

  CREATE_PRACTICE: 'create_practice',
  UPDATE_PRACTICE: 'update_practice',
  DESTROY_PRACTICE: 'destroy_practice',
  VIEW_PRACTICES: 'view_practices',

  CREATE_ROOM: 'create_room',
  UPDATE_ROOM: 'update_room',
  DESTROY_ROOM: 'destroy_room',
  VIEW_ROOMS: 'view_rooms',

  APPEND_IMAGES: 'append_images',
  MANAGE_ON_CALL_AVAILABILITIES: 'manage_on_call_availabilities',

  CREATE_ON_CALL_TYPE: 'create_on_call_type',
  UPDATE_ON_CALL_TYPE: 'update_on_call_type',
  DESTROY_ON_CALL_TYPE: 'destroy_on_call_type',
  VIEW_ON_CALL_TYPES: 'view_on_call_types',

  CREATE_ANESTHESIA_TYPE: 'create_anesthesia_type',
  DESTROY_ANESTHESIA_TYPE: 'destroy_anesthesia_type',
  UPDATE_ANESTHESIA_TYPE: 'update_anesthesia_type',

  CREATE_APPOINTMENT_INSURANCE: 'create_appointment_insurance',
  DESTROY_APPOINTMENT_INSURANCE: 'destroy_appointment_insurance',
  UPDATE_APPOINTMENT_INSURANCE: 'update_appointment_insurance',

  CREATE_APPOINTMENT_STATUS_TYPES: 'create_appointment_status_types',
  VIEW_APPOINTMENT_STATUS_TYPES: 'view_appointment_status_types',

  CREATE_APPOINTMENT_TYPE: 'create_appointment_type',
  DESTROY_APPOINTMENT_TYPE: 'destroy_appointment_type',
  UPDATE_APPOINTMENT_TYPE: 'update_appointment_type',

  CREATE_APPOINTMENT_TYPES: 'create_appointment_types',
  VIEW_APPOINTMENT_TYPES: 'view_appointment_types',

  CREATE_CASE_TYPE: 'create_case_type',
  DESTROY_CASE_TYPE: 'destroy_case_type',
  UPDATE_CASE_TYPE: 'update_case_type',

  CREATE_DOCUMENT_TYPE: 'create_document_type',
  DESTROY_DOCUMENT_TYPE: 'destroy_document_type',
  UPDATE_DOCUMENT_TYPE: 'update_document_type',

  CREATE_ENCOUNTER_ANESTHESIA_TYPE: 'create_encounter_anesthesia_type',
  DESTROY_ENCOUNTER_ANESTHESIA_TYPE: 'destroy_encounter_anesthesia_type',
  UPDATE_ENCOUNTER_ANESTHESIA_TYPE: 'update_encounter_anesthesia_type',

  CREATE_ENCOUNTER_EQUIPMENT: 'create_encounter_equipment',
  DESTROY_ENCOUNTER_EQUIPMENT: 'destroy_encounter_equipment',
  UPDATE_ENCOUNTER_EQUIPMENT: 'update_encounter_equipment',

  CREATE_ENCOUNTER_IMPLANT: 'create_encounter_implant',
  DESTROY_ENCOUNTER_IMPLANT: 'destroy_encounter_implant',
  UPDATE_ENCOUNTER_IMPLANT: 'update_encounter_implant',

  CREATE_EQUIPMENT: 'create_equipment',
  DESTROY_EQUIPMENT: 'destroy_equipment',
  UPDATE_EQUIPMENT: 'update_equipment',

  CREATE_EQUIPMENT_SUPPLIER: 'create_equipment_supplier',
  DESTROY_EQUIPMENT_SUPPLIER: 'destroy_equipment_supplier',
  UPDATE_EQUIPMENT_SUPPLIER: 'update_equipment_supplier',

  CREATE_IMPLANT: 'create_implant',
  DESTROY_IMPLANT: 'destroy_implant',
  UPDATE_IMPLANT: 'update_implant',

  CREATE_IMPLANT_MANUFACTURER: 'create_implant_manufacturer',
  DESTROY_IMPLANT_MANUFACTURER: 'destroy_implant_manufacturer',
  UPDATE_IMPLANT_MANUFACTURER: 'update_implant_manufacturer',

  CREATE_INSURANCE_PROVIDER: 'create_insurance_provider',
  DESTROY_INSURANCE_PROVIDER: 'destroy_insurance_provider',
  UPDATE_INSURANCE_PROVIDER: 'update_insurance_provider',

  CREATE_LOCATION: 'create_location',
  DESTROY_LOCATION: 'destroy_location',
  UPDATE_LOCATION: 'update_location',

  CREATE_ON_CALL_FACILITY: 'create_on_call_facility',
  DESTROY_ON_CALL_FACILITY: 'destroy_on_call_facility',
  UPDATE_ON_CALL_FACILITY: 'update_on_call_facility',

  CREATE_PROCEDURE: 'create_procedure',
  DESTROY_PROCEDURE: 'destroy_procedure',
  UPDATE_PROCEDURE: 'update_procedure',

  CREATE_ROLE: 'create_role',
  UPDATE_ROLE: 'update_role',

  CREATE_SPECIMEN: 'create_specimen',
  DESTROY_SPECIMEN: 'destroy_specimen',
  UPDATE_SPECIMEN: 'update_specimen',

  CREATE_SURGERY_REQUEST_REQUIRED_FIELDS:
    'create_surgery_request_required_fields',
  DESTROY_SURGERY_REQUEST_REQUIRED_FIELDS:
    'destroy_surgery_request_required_fields',
  UPDATE_SURGERY_REQUEST_REQUIRED_FIELDS:
    'update_surgery_request_required_fields',

  CREATE_USER: 'create_user',
  INVITE_USER: 'invite_user',
  UPDATE_USER: 'update_user',

  DESTROY_APPOINTMENT_STATUS_TYPE: 'destroy_appointment_status_type',
  UPDATE_APPOINTMENT_STATUS_TYPE: 'update_appointment_status_type',

  UPDATE_PROVIDER: 'update_provider',
  VIEW_ROLES: 'view_roles',

  CANCEL_APPOINTMENT: 'cancel_appointment',
  CREATE_APPOINTMENT: 'create_appointment',
  READ_APPOINTMENT: 'read_appointment',
  UPDATE_APPOINTMENT: 'update_appointment',

  CREATE_ALLERGY: 'create_allergy',
  DESTROY_ALLERGY: 'destroy_allergy',
  UPDATE_ALLERGY: 'update_allergy',

  CREATE_APPOINTMENT_WORKFLOW_ITEM: 'create_appointment_workflow_item',
  UPDATE_APPOINTMENT_WORKFLOW_ITEM: 'update_appointment_workflow_item',

  CREATE_CASE: 'create_case',
  READ_CASE: 'read_case',
  UPDATE_CASE: 'update_case',

  CREATE_CASE_WORKFLOW_ITEM: 'create_case_workflow_item',
  UPDATE_CASE_WORKFLOW_ITEM: 'update_case_workflow_item',

  CREATE_DOCUMENT_REFERENCE: 'create_document_reference',
  DESTROY_DOCUMENT_REFERENCE: 'destroy_document_reference',
  UPDATE_DOCUMENT_REFERENCE: 'update_document_reference',

  CREATE_ENCOUNTER: 'create_encounter',
  MANAGE_ENCOUNTER: 'manage_encounter',
  READ_ENCOUNTER: 'read_encounter',
  UPDATE_ENCOUNTER: 'update_encounter',

  CREATE_FOLLOW: 'create_follow',
  DESTROY_FOLLOW: 'destroy_follow',

  CREATE_ON_CALL_RECORD: 'create_on_call_record',
  READ_ON_CALL_RECORD: 'read_on_call_record',
  UPDATE_ON_CALL_RECORD: 'update_on_call_record',

  CREATE_PATIENT: 'create_patient',
  READ_PATIENT: 'read_patient',
  UPDATE_PATIENT: 'update_patient',
  DESTROY_PATIENT: 'destroy_patient',

  CREATE_PATIENT_DOCUMENT: 'create_patient_document',
  UPDATE_PATIENT_DOCUMENT: 'update_patient_document',

  CREATE_PATIENT_EMERGENCY_CONTACT: 'create_patient_emergency_contact',
  DESTROY_PATIENT_EMERGENCY_CONTACT: 'destroy_patient_emergency_contact',
  UPDATE_PATIENT_EMERGENCY_CONTACT: 'update_patient_emergency_contact',

  CREATE_PATIENT_INSURANCE: 'create_patient_insurance',
  DESTROY_PATIENT_INSURANCE: 'destroy_patient_insurance',
  UPDATE_PATIENT_INSURANCE: 'update_patient_insurance',

  CREATE_PATIENT_RECORDING: 'create_patient_recording',

  CREATE_VOICE_COMMAND: 'create_voice_command',
  DESTROY_VOICE_COMMAND: 'destroy_voice_command',
  READ_VOICE_COMMAND: 'read_voice_command',
  UPDATE_VOICE_COMMAND: 'update_voice_command',

  CREATE_WORKFLOW_ITEM: 'create_workflow_item',
  DESTROY_WORKFLOW_ITEM: 'destroy_workflow_item',
  UPDATE_WORKFLOW_ITEM: 'update_workflow_item',

  DESTROY_DEMOGRAPHICS_IMAGE: 'destroy_demographics_image',

  DESTROY_PATIENT_DOCUMENTS: 'destroy_patient_documents',
  VIEW_PATIENT_DOCUMENTS: 'view_patient_documents',

  GENERATE_MERGED_ON_CALL_PDF: 'generate_merged_on_call_pdf',

  MANAGE_ACTIVITY_NOTIFICATIONS: 'manage_activity_notifications',
  VIEW_ACTIVITY_NOTIFICATIONS: 'view_activity_notifications',

  CREATE_SMART_FORM: 'create_smart_form',
  DESTROY_SMART_FORM: 'destroy_smart_form',
  UPDATE_SMART_FORM: 'update_smart_form',

  CREATE_SMART_NOTE: 'create_smart_note',
  DESTROY_SMART_NOTE: 'destroy_smart_note',
  UPDATE_SMART_NOTE: 'update_smart_note',

  MANAGE_AUTHENTICATION_TOKEN: 'manage_authentication_token',
  RE_REQUEST_APPOINTMENT: 're_request_appointment',
  READ_CONVERSATION: 'read_conversation',
  SEND_MERGED_PDF_TO_EMR: 'send_merged_pdf_to_emr',
  SEND_TO_EMR: 'send_to_emr',
  UPDATE_ACCOUNT: 'update_account',
  UPDATE_CONVERSATIONS: 'update_conversations',
  UPDATE_MESSAGE: 'update_message',
  UPDATE_PATIENT_STORY: 'update_patient_story',
  VIEW_ANESTHESIA_TYPES: 'view_anesthesia_types',
  VIEW_APPOINTMENT_ENCOUNTERS: 'view_appointment_encounters',
  VIEW_APPOINTMENTS: 'view_appointments',
  VIEW_CALENDARS: 'view_calendars',
  VIEW_CASE_TYPES: 'view_case_types',
  VIEW_CASES: 'view_cases',
  VIEW_CONVERSATIONS: 'view_conversations',
  VIEW_DOCUMENT_TYPES: 'view_document_types',
  VIEW_ENCOUNTER_IMPLANTS: 'view_encounter_implants',
  VIEW_EQUIPMENT_SUPPLIERS: 'view_equipment_suppliers',
  VIEW_EQUIPMENTS: 'view_equipments',
  VIEW_FINANCIAL_CENTERS: 'view_financial_centers',
  VIEW_FOLLOWS: 'view_follows',
  VIEW_IMPLANT_MANUFACTURERS: 'view_implant_manufacturers',
  VIEW_IMPLANTS: 'view_implants',
  VIEW_INSURANCE_PROVIDERS: 'view_insurance_providers',
  VIEW_LICENSE_TRANSACTION_CATEGORIES: 'view_license_transaction_categories',
  VIEW_LINKED_ORGANIZATIONS: 'view_linked_organizations',
  VIEW_LOCATIONS: 'view_locations',

  VIEW_ADMIN_INTEGRATIONS: 'view_admin_integrations',
  VIEW_ADMIN_LICENSES: 'view_admin_licenses',
  VIEW_ADMIN_SOLUTIONS: 'view_admin_solutions',
  VIEW_ON_CALL_AVAILABILITIES: 'view_on_call_availabilities',
  VIEW_ON_CALL_FACILITIES: 'view_on_call_facilities',
  VIEW_ON_CALL_RECORDS: 'view_on_call_records',
  VIEW_PATIENTS: 'view_patients',
  VIEW_PROCEDURES: 'view_procedures',
  VIEW_PROVIDERS: 'view_providers',
  VIEW_RECORDINGS: 'view_recordings',
  VIEW_SPECIMENS: 'view_specimens',
  VIEW_USERS: 'view_users',
  VIEW_VISITS: 'view_visits',
  VIEW_VOICE_COMMANDS: 'view_voice_commands',
  VIEW_WORKFLOW_ITEMS: 'view_workflow_items'
})

const usePermissions = permission => {
  const { currentAccount } = useContext(AppContext)

  const enabledPermissions =
    (currentAccount && currentAccount.resolvedPermissions) || []

  return enabledPermissions.includes(permission)
}

export const useAccountPermissions = (account, permission) => {
  const enabledPermissions = (account && account.resolvedPermissions) || []

  return enabledPermissions.includes(permission)
}

export default usePermissions
