import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { NavLink } from 'react-router-dom'

import rgba from 'lib/rgba'
import { transitionSimple } from 'styles/mixins'

const useStyles = createUseStyles(({ colors, typography }) => ({
  tabItem_fullWidth: {
    flex: 1
  },
  tabItem_modal: {
    flex: 1
  },
  tabLink: {
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    fontFamily: typography.fontFamilyVariants.primary,
    fontWeight: typography.fontWeightVariants.medium,
    justifyContent: 'center',
    paddingBottom: 10
  },
  tabLink_default: {
    borderBottom: '2px solid transparent',
    color: colors.greyDark,
    fontSize: typography.fontSizeVariants.medium,
    marginRight: 50,

    '&:hover': {
      ...transitionSimple(),

      color: colors.secondary
    }
  },
  tabLink_fullWidth: {
    color: colors.secondaryLight,
    fontSize: typography.fontSizeVariants.small,

    '&:hover': {
      ...transitionSimple(),

      color: colors.light
    }
  },
  tabLink_vertical: {
    color: colors.greyDark,
    fontSize: typography.fontSizeVariants.small,
    paddingTop: 11,
    paddingLeft: 30,

    '&:hover': {
      ...transitionSimple(),

      color: colors.secondary
    }
  },
  tabLink_modal: {
    padding: 10,

    '&:hover': {
      ...transitionSimple(),

      color: colors.secondary
    }
  },
  tab_default_active: {
    borderColor: colors.secondary,
    color: colors.secondary
  },
  tab_fullWidth_active: {
    borderBottom: `2px solid ${colors.light}`,
    color: colors.light,
    marginBottom: -2
  },
  tab_vertical_active: {
    fontWeight: typography.fontWeightVariants.semibold,
    color: colors.secondary,
    borderLeftColor: colors.secondary,
    borderLeftStyle: 'solid',
    borderLeftWidth: 4
  },
  tab_modal_active: {
    backgroundColor: colors.light,
    color: colors.secondary,
    borderRadius: 10,
    boxShadow: `0px 8px 15px ${rgba(colors.primary, 0.051)}`,
    fontWeight: typography.fontWeightVariants.medium
  },
  tab_disable: {
    cursor: 'not-allowed',
    opacity: 0.5
  },
  pointer_events_none: {
    pointerEvents: 'none'
  }
}))

function Tab({ active, children, onClick, to, variant, disableNonActiveTab }) {
  const classes = useStyles()

  if (to) {
    return (
      <li className={classes[`tabItem_${variant}`]} role="presentation">
        <NavLink
          to={to}
          className={classNames(classes.tabLink, classes[`tabLink_${variant}`])}
          activeClassName={classes[`tab_${variant}_active`]}
          onClick={onClick}
          role="menuitem"
        >
          {children}
        </NavLink>
      </li>
    )
  }

  return (
    <li
      className={classNames(classes[`tabItem_${variant}`], {
        [classes.tab_disable]: disableNonActiveTab && !active
      })}
      role="presentation"
    >
      <div
        role="tab"
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onClick}
        className={classNames(classes.tabLink, classes[`tabLink_${variant}`], {
          [classes[`tab_${variant}_active`]]: active,
          [classes.pointer_events_none]: disableNonActiveTab && !active
        })}
      >
        {children}
      </div>
    </li>
  )
}

Tab.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string
}

Tab.defaultProps = {
  onClick: () => {},
  to: null
}

export default Tab
