import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'

import { breakpointMax } from 'styles/mixins'

const useStyles = createUseStyles(({ colors }) => ({
  header: {
    height: 90,
    display: 'flex',
    padding: 30,
    width: '100%',
    backgroundColor: colors.greyPale,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.greyExtraLight}`
  },
  main: {
    padding: [25, 50],
    height: props => props.fixedHeight && `${props.height}vh`,
    maxHeight: props => `${props.height}vh`,
    overflow: ({ overflow }) => overflow,
    [breakpointMax('xs')]: {
      padding: [25, 20]
    },
    position: props => (props.wrapper ? 'relative' : 'static')
  }
}))

const ModalContent = forwardRef(
  (
    {
      children,
      height,
      wrapper,
      fixedHeight,
      overflow,
      header: HeaderComponent
    },
    ref
  ) => {
    const classes = useStyles({
      height,
      fixedHeight,
      wrapper,
      overflow
    })

    return (
      <>
        {HeaderComponent && (
          <div className={classes.header}>{HeaderComponent}</div>
        )}
        <main ref={ref} className={classes.main}>
          {children}
        </main>
      </>
    )
  }
)

ModalContent.propTypes = {
  height: PropTypes.number,
  fixedHeight: PropTypes.bool,
  wrapper: PropTypes.bool,
  overflow: PropTypes.oneOf(['hidden', 'visible', 'auto'])
}

ModalContent.defaultProps = {
  height: 80,
  fixedHeight: false,
  wrapper: false,
  overflow: 'auto'
}

export default ModalContent
