import { useContext } from 'react'

import AppContext from 'contexts/AppContext'

export const featureTypes = Object.freeze({
  CREATE_APPOINTMENTS: 'create_appointments',
  IMPORT_PROVIDER_APPOINTMENTS: 'import_provider_appointments',
  IMPORT_ALL_APPOINTMENTS: 'import_all_appointments',
  UPDATE_APPOINTMENTS: 'update_appointments',

  CREATE_LOCATION: 'create_location',
  DESTROY_LOCATION: 'destroy_location',
  UPDATE_LOCATION: 'update_location',

  VIEW_APPOINTMENT_STATUS_TYPES: 'view_appointment_status_types',
  CREATE_APPOINTMENT_STATUS_TYPE: 'create_appointment_status_type',
  UPDATE_APPOINTMENT_STATUS_TYPE: 'update_appointment_status_type',
  DESTROY_APPOINTMENT_STATUS_TYPE: 'destroy_appointment_status_type',

  VIEW_APPOINTMENT_TYPES: 'view_appointment_types',
  CREATE_APPOINTMENT_TYPE: 'create_appointment_type',
  UPDATE_APPOINTMENT_TYPE: 'update_appointment_type',
  DESTROY_APPOINTMENT_TYPE: 'destroy_appointment_type',

  VIEW_INSURANCES: 'view_insurances',
  CREATE_INSURANCE: 'create_insurance',
  UPDATE_INSURANCE: 'update_insurance',
  DESTROY_INSURANCE: 'destroy_insurance',

  CREATE_ROOM: 'create_room',
  DESTROY_ROOM: 'destroy_room',
  UPDATE_ROOM: 'update_room',

  CREATE_PATIENT: 'create_patient',
  UPDATE_PATIENT: 'update_patient',
  DESTROY_PATIENT: 'destroy_patient',

  VIEW_VOICE_COMMANDS: 'view_voice_commands',
  CREATE_VOICE_COMMAND: 'create_voice_command',
  DESTROY_VOICE_COMMAND: 'destroy_voice_command',
  UPDATE_VOICE_COMMAND: 'update_voice_command',

  VIEW_DOCUMENTS: 'view_documents',
  CREATE_DOCUMENT: 'create_document',
  UPDATE_DOCUMENT: 'update_document',
  DESTROY_DOCUMENT: 'destroy_document',

  VIEW_ON_CALL_TYPES: 'view_on_call_types',
  CREATE_ON_CALL_TYPE: 'create_on_call_type',
  UPDATE_ON_CALL_TYPE: 'update_on_call_type',
  DESTROY_ON_CALL_TYPE: 'destroy_on_call_type',

  DISABLE_EMR_FIELDS: 'disable_emr_fields',

  ENABLE_APPOINTMENT_OPERATIVE_REPORT: 'enable_appointment_operative_report',
  ENABLE_CANCEL_APPOINTMENT: 'enable_cancel_appointment',
  ENABLE_COMPLETE_APPOINTMENT_STATUS: 'enable_complete_appointment_status',
  ENABLE_DUAL_OP_NOTE: 'enable_dual_op_note',
  ENABLE_DYNAMIC_LICENSING: 'enable_dynamic_licensing',
  ENABLE_EXTERNAL_APPOINTMENTS: 'enable_external_appointments',
  ENABLE_EXTERNAL_PATIENTS: 'enable_external_patients',
  ENABLE_FOLLOWINGS: 'enable_followings',
  ENABLE_MANAGEMENT_STATUS: 'enable_management_status',
  ENABLE_MULTIFIELD_PHRASE: 'enable_multi_field_phrases',
  ENABLE_NURSE_NOTES: 'enable_nurse_notes',
  ENABLE_ON_CALL_OPERATIVE_REPORT: 'enable_on_call_operative_report',
  ENABLE_SURGERY_WORKFLOW_PRINT: 'enable_surgery_workflow_print',
  ENABLE_STAFF_ADDENDUMS: 'enable_staff_addendums',
  ENABLE_WEB_APPOINTMENTS_SYNC: 'enable_web_appointments_sync',
  ENABLE_ADDITIONAL_DOCUMENT_TYPE_PARAMS:
    'enable_additional_document_type_params',

  AUTO_GENERATE_SURGERY_ENCOUNTER: 'auto_generate_surgery_encounter',

  MANAGE_ALLERGIES: 'manage_allergies',
  MANAGE_ANESTHESIA: 'manage_anesthesia',
  MANAGE_APPOINTMENT_COMMENTS: 'manage_appointment_comments',
  MANAGE_APPOINTMENT_IMAGES: 'manage_appointment_images',
  MANAGE_APPOINTMENT_INSURANCES: 'manage_appointment_insurances',
  MANAGE_AVAILABILITIES: 'manage_availabilities',
  MANAGE_CASE_DETAILS: 'manage_case_details',
  MANAGE_CLINIC_MRN: 'manage_clinic_mrn',
  MANAGE_COVID_VACCINATION_STATUS: 'manage_covid_vaccination_status',
  MANAGE_DOCUMENT_TYPES: 'manage_document_types',
  MANAGE_EMERGENCY_CONTACTS: 'manage_emergency_contacts',
  MANAGE_EQUIPMENT_SUPPLIERS: 'manage_equipment_suppliers',
  MANAGE_EQUIPMENTS: 'manage_equipments',
  MANAGE_IMPLANTS: 'manage_implants',
  MANAGE_INDEPENDENT_ENCOUNTERS: 'manage_independent_encounters',
  MANAGE_INSURANCE_PROVIDERS: 'manage_insurance_providers',
  ENABLE_ON_CALL_MEDICAL_CODES: 'enable_on_call_medical_codes',
  ENABLE_APPOINTMENT_MEDICAL_CODES: 'enable_appointment_medical_codes',
  MANAGE_NEW_PASSWORD_VALIDATION: 'manage_new_password_validation',
  MANAGE_NOTIFICATIONS: 'manage_notifications',
  MANAGE_PATIENT_COMMENTS: 'manage_patient_comments',
  MANAGE_PATIENT_STORY: 'manage_patient_story',
  MANAGE_PROCEDURES: 'manage_procedures',
  MANAGE_SPECIMENS: 'manage_specimens',
  MANAGE_SURGERY_CENTER_MRN: 'manage_surgery_center_mrn',
  MANAGE_SURGERY_WORKFLOW_STATUS: 'manage_surgery_workflow_status',
  MANAGE_WORKFLOW: 'manage_workflow',

  VERIFY_PATIENT_DETAILS_BEFORE_SIGN_OFF:
    'verify_patient_details_before_sign_off',

  ENABLE_WEB_RECORDINGS: 'enable_web_recordings',

  USE_APPOINTMENT_CALENDARS: 'use_appointment_calendars',
  USE_APPOINTMENT_STATUS_FILTER: 'use_appointment_status_filter',
  USE_APPOINTMENT_STATUS_TYPES: 'use_appointment_status_types',
  USE_APPOINTMENT_TYPES_FOR_SURGERY: 'use_appointment_types_for_surgery',
  MANAGE_ON_CALL_FACILITIES: 'manage_on_call_facilities',
  USE_PRACTICES: 'use_practices',
  USE_FINANCIAL_CENTERS: 'use_financial_centers',
  USE_LOCATIONS: 'use_locations',
  USE_LOCATION_FOR_SURGERY: 'use_location_for_surgery',
  USE_ROOMS: 'use_rooms',
  USE_SIMPLE_UNIFIED_STATUS: 'use_simple_unified_status',

  PATIENT_ADVANCED_FIELDS: 'patient_advanced_fields',
  SYNC_OP_REPORT: 'sync_op_report',
  SYNC_MERGED_PDF: 'sync_merged_pdf',

  VIEW_ADMIN_LICENSES: 'view_admin_licenses',
  VIEW_ANONYMOUS_AVAILABILITIES: 'view_anonymous_availabilities',
  VIEW_BETA: 'view_beta',
  VIEW_CALENDAR_AVAILABILITIES: 'view_calendar_availabilities',
  VIEW_CALENDAR_EVENTS_WITHOUT_FILTERS: 'view_calendar_events_without_filters',
  VIEW_INTEGRATION_HUB: 'view_integration_hub',
  VIEW_LINKED_DATA: 'view_linked_data',
  VIEW_ON_CALL_RECORDS: 'view_on_call_records',
  VIEW_ON_CALL_AVAILABILITIES: 'view_on_call_availabilities',
  VIEW_PATIENTS: 'view_patients',
  VIEW_PROVIDER_PHONE_ON_CALL_SCHEDULE: 'view_provider_phone_on_call_schedule',
  VIEW_SCHEDULE: 'view_schedule',
  VIEW_ON_CALL_ICAL_SUBSCRIPTIONS: 'view_on_call_ical_subscriptions',
  VIEW_PROVIDER_PHONE_SHARED_ON_CALL_SCHEDULE:
    'view_provider_phone_shared_on_call_schedule',
  VIEW_PROVIDER_PRACTICES_IN_ON_CALL_CALENDAR:
    'view_provider_practices_in_on_call_calendar',
  VIEW_SCHEDULE_LIST: 'view_schedule_list',
  VIEW_SCHEDULE_ROOMS: 'view_schedule_rooms',
  VIEW_SCHEDULE_SURGERIES: 'view_schedule_surgeries',
  VIEW_SURGERY_WORKFLOW_STATUS: 'view_surgery_workflow_status',
  VIEW_REPORTS: 'view_reports',
  VIEW_INTEGRATION_HUB_STATISTICS: 'view_integration_hub_statistics',
  VIEW_OPERATIVE_NOTE_STATISTICS: 'view_operative_note_statistics',
  VIEW_ENTERPRISE_STATISTICS: 'view_enterprise_statistics',
  VIEW_INTEGRATION_HUB_APPOINTMENTS: 'view_integration_hub_appointments',

  DISPLAY_APPOINTMENT_STATUS: 'display_appointment_status',

  AI_SMART_NOTES: 'ai_smart_notes',
  PORTAL_DICTATION: 'portal_dictation',

  // Computed
  DISPLAY_SURGERY_WORKFLOW_STATUS: [
    'view_surgery_workflow_status',
    'manage_surgery_workflow_status'
  ],
  MANAGE_LOCATIONS: ['create_location', 'destroy_location', 'update_location'],
  MANAGE_ROOMS: ['create_room', 'destroy_room', 'update_room'],
  MANAGE_SURGERY_REQUEST_REQUIRED_FIELDS: [
    'create_surgery_request_required_fields',
    'update_surgery_request_required_fields',
    'destroy_surgery_request_required_fields'
  ],

  PATIENTS_CATEGORY_SEARCH: {
    patients_category_search: true,
    patients_advanced_search: false
  }
})

const useFeature = featureType => {
  const { resolvedFeatures } = useContext(AppContext)

  if (!resolvedFeatures || !featureType) return undefined

  if (Array.isArray(featureType)) {
    return featureType.some(feature => resolvedFeatures[feature])
  }

  if (featureType?.constructor === Object) {
    return Object.keys(featureType).every(
      key =>
        featureType[key] === resolvedFeatures[key] ||
        resolvedFeatures[key] === undefined
    )
  }

  return resolvedFeatures[featureType] || false
}

export const useAccountFeature = (account, featureType) => {
  const { resolvedFeatures } = account

  if (!resolvedFeatures) return undefined

  if (Array.isArray(featureType)) {
    return featureType.some(feature => resolvedFeatures[feature])
  }

  return resolvedFeatures[featureType] || false
}

export default useFeature
