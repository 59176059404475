export default {
  success_title: 'Success!',
  failure_title: 'Failed!',

  // generic
  delayed_import: 'File contents will be imported shortly.',
  bad_request: "We've received a bad request, please try again.",
  no_response: 'Please try again after some time.',
  unauthorized: 'You seem to have logged out.',
  forbidden: 'You are not allowed to do that.',
  not_found: 'That resource does not exist.',
  not_invited:
    'You have not yet been invited to this organization. Please check with your administrator.',
  inactive:
    'You do not have access to this organization. Please check with your administrator.',
  no_account: 'No account exists with that email.',
  internal_server_error:
    'Something went wrong. Our engineers are looking into it.',
  other_error: "We've been notified. Please try after some time.",
  account_locked: 'Max Retries Reached. Try logging in after 10 minutes.',
  account_not_found: 'External login was unsuccessful. Please try again.',
  account_unverified:
    "We couldn't authenticate your account against your external login provider. Please contact your administrator.",
  print_document_error:
    "You don't have a document to print for the selected document types.",
  // specific
  registration_complete:
    'Welcome! We have sent you an email with helpful information to get you up and running.',
  payment_already_setup: "You've already setup your payment information.",
  forgot_password:
    'You will receive an email with instructions on how to reset your password in a few minutes.',
  reset_password: "You've successfully reset your password.",
  integration_hub_ignore_success: 'Record successfully ignored.',
  integration_hub_archive_success: 'Record successfully archived.',
  integration_hub_import_success: 'Record successfully imported.',
  integration_hub_recommendation_success: 'Record successfully recommended.',
  integration_hub_cancel_success: 'Request successfully cancelled.',
  integration_hub_status_change_success: 'Status successfully updated',
  request_license: 'License Request has been sent to Account manager',
  enquire_solution: 'Solution enquiry request sent to the account manager',
  create_license: 'License successfully added.',
  remove_license: 'License successfully removed.'
}
