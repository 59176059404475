import React from 'react'
import { kebabCase } from 'lodash'
import { useTranslation } from 'react-i18next'

import AuthLayout from 'components/layouts/AuthLayout'
import RegionAndLanguageSelectorMenu from 'components/menu/RegionAndLanguageSelectorMenu'
import usePersistedState from 'hooks/usePersistedState'
import {
  ACCOUNT_REGIONS,
  ACCOUNT_LANGUAGES,
  DB_USER_REGION_KEY,
  DB_USER_LANGUAGE_KEY,
  switcherLanguageAcronyms
} from 'models/Account'

function LoginLayout({ path, ...props }) {
  const { i18n } = useTranslation()
  const subDomain = window.location.hostname
  const [userRegion, setUserRegion] = usePersistedState(
    DB_USER_REGION_KEY,
    subDomain.includes('portal-ca') ? 'Canada' : 'United States'
  )
  const [userLanguage, setUserLanguage] = usePersistedState(
    DB_USER_LANGUAGE_KEY,
    'English'
  )

  const regionSelectorMenuItems = ACCOUNT_REGIONS.map(region => ({
    children: region,
    handleOnClick: () => setUserRegion(region),
    key: kebabCase(region)
  }))

  const languageSelectorMenuItems = ACCOUNT_LANGUAGES.map(language => ({
    children: language,
    handleOnClick: () => {
      setUserLanguage(language)
      i18n.changeLanguage(switcherLanguageAcronyms[language])
    },
    key: kebabCase(language)
  }))

  const navbarItems = path.includes('/login') ? (
    <RegionAndLanguageSelectorMenu
      regionSelectorMenuItems={regionSelectorMenuItems}
      languageSelectorMenuItems={languageSelectorMenuItems}
      currentRegion={userRegion}
      currentLanguage={userLanguage}
    />
  ) : null

  return <AuthLayout navbarItems={navbarItems} {...props} />
}

export default LoginLayout
