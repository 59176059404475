import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'

import FontIcon from 'components/base/FontIcon'
import { breakpointMax, transitionSimple } from 'styles/mixins'

const useStyles = createUseStyles(({ colors, typography }) => ({
  pageTitle: {
    ...transitionSimple(),

    color: colors.light,
    cursor: props => props.to && 'pointer',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xxxLarge,
    fontWeight: typography.fontWeightVariants.extralight,
    lineHeight: '32px',

    '& > $strong': {
      fontWeight: typography.fontWeightVariants.semibold
    },

    '& > .icon': {
      marginRight: 15
    },

    '&:hover > .icon': {
      color: colors.tertiary
    },

    [breakpointMax('md')]: {
      textAlign: 'center'
    }
  }
}))

function PageTitle({ children, className, iconLeft, to, ...props }) {
  const classes = useStyles({ to })

  const content = (
    <div className={classNames(classes.pageTitle, className)} {...props}>
      {iconLeft && <FontIcon name={iconLeft} size="large" color="light" />}
      {children}
    </div>
  )

  if (!to) {
    return content
  }

  return <Link to={to}>{content}</Link>
}

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconLeft: PropTypes.string,
  to: PropTypes.string
}

PageTitle.defaultProps = {
  iconLeft: null,
  className: null,
  to: null
}

export default PageTitle
