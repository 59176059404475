import React, { createContext, useMemo, useState } from 'react'

const RequiredFieldsContext = createContext()

export function RequiredFieldsProvider({ children }) {
  const [errorMsgs, setErrorMsgs] = useState([])
  const [showRequiredFieldsErrorModal, setShowRequiredFieldsErrorModal] =
    useState(false)

  const contextValue = useMemo(() => ({
    errorMsgs,
    setErrorMsgs,
    showRequiredFieldsErrorModal,
    setShowRequiredFieldsErrorModal
  }), [
    errorMsgs,
    setErrorMsgs,
    showRequiredFieldsErrorModal,
    setShowRequiredFieldsErrorModal
  ])

  return (
    <RequiredFieldsContext.Provider value={contextValue}>
      {children}
    </RequiredFieldsContext.Provider>
  )
}

export default RequiredFieldsContext
