import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Column from 'components/base/Column'
import PageTitle from 'components/typography/PageTitle'
import Row from 'components/base/Row'
import Text from 'components/typography/Text'

import { breakpointMax } from 'styles/mixins'

const useStyles = createUseStyles({
  title: {
    paddingBottom: 15,
    paddingTop: 10,

    [breakpointMax('md')]: {
      textAlign: 'center'
    }
  },
  subtitle: {
    [breakpointMax('md')]: {
      textAlign: 'center'
    }
  }
})

function AuthTitle({ title, subtitle, ...other }) {
  const classes = useStyles()

  return (
    <Row>
      <Column span={3} hiddenXs />
      <Column span={6}>
        <Text className={classes.subtitle} size="small" weight="semibold" color="tertiary">{subtitle}</Text>
        {title && (
          <PageTitle
            iconLeft={other.iconLeft}
            className={classes.title}
            {...other}
          >
            {title}
          </PageTitle>
        )}
      </Column>
    </Row>
  )
}

AuthTitle.propTypes = {
  subtitle: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]),
  title: PropTypes.string
}

AuthTitle.defaultProps = {
  subtitle: 'Time for your details.',
  title: null
}

export default AuthTitle
