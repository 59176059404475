import theme from 'styles/theme'

export default {
  ...theme,
  colors: {
    ...theme.colors,
    tertiary: '#f39969',
    tertiaryPale: '#fde3d4'
  }
}
