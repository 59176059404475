import React from 'react'

import Card from 'components/cards/Card'
import PageTitle from 'components/typography/PageTitle'

function ESignSuccessPage() {
  return (
    <Card>
      <PageTitle>You have successfully signed the Master Service Agreement.</PageTitle>
    </Card>
  )
}

export default ESignSuccessPage
