import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import Row from 'components/base/Row'
import SetupAccountForm from 'components/forms/SetupAccountForm'
import useMutation from 'hooks/useMutation'
import { GATagConversionEvent } from 'components/analytics/GATagManager'

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccountMutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      email
      firstName
      lastName
      role
    }
  }
`

function SetupAccountPage() {
  const history = useHistory()
  const { token } = useParams()

  const [updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION, {
    onCompleted: response => {
      history.push({
        pathname: `/setup/${token}/organization`,
        state: {
          email: response.updateAccount.email,
          role: response.updateAccount.role
        }
      })
    }
  })

  const handleUpdateAccount = values => {
    const updateParameters = { ...values, token }

    return updateAccount(updateParameters)
  }

  return (
    <Fragment>
      <GATagConversionEvent />
      <AuthTitle title="Set Up Your Account." />
      <Row>
        <Column span={3} />
        <Column span={6} spanMd={12}>
          <Card variant="secondary">
            <SetupAccountForm
              submitLabel="Next"
              onFormSubmit={handleUpdateAccount}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default SetupAccountPage
