import { get, set } from 'lodash'

const validateSchema = schema => values => {
  let errors = {}

  try {
    schema.validateSync(values, { abortEarly: false })
  } catch (err) {
    errors = err.inner.reduce((formErrors, { path, message }) => {
      if (get(formErrors, path)) {
        set(formErrors, path, `${get(formErrors, path)} ${message}`)
      } else {
        set(formErrors, path, message)
      }
      return formErrors
    }, {})
  }

  return errors
}

export default validateSchema
