import alerts from 'constants/alerts'
import { FORM_ERROR, setIn } from 'final-form'

export const errorTypes = Object.freeze({
  BAD_REQUEST: 'BAD_REQUEST',
  UNPROCESSABLE_ENTITY: 'UNPROCESSABLE_ENTITY',
  UNAUTHORIZED: 'UNAUTHORIZED',
  FORBIDDEN: 'FORBIDDEN',
  NOT_FOUND: 'NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  ACTIONABLE_WARNING: 'ACTIONABLE_WARNING',
  RESOURCE_LOCKED: 'RESOURCE_LOCKED'
})

/*
  https://www.apollographql.com/docs/link/links/http.html#Errors
  https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error
*/

const parseSubmissionError = graphQLErrors =>
  graphQLErrors.reduce((errors, { type, path, message }) => {
    if (type && type === errorTypes.UNPROCESSABLE_ENTITY && path) {
      const field = path.join('.')

      if (field === 'base') {
        return setIn(errors, FORM_ERROR, message)
      }

      return setIn(errors, field, message)
    }
    return errors
  }, {})

const parseError = ({ networkError, graphQLErrors = [] }) => {
  let message = null
  let submissionError = null
  let errorType = null

  if (networkError) {
    const { response, result: { exception, errors } = {} } = networkError

    if (!response) {
      // Server is down / No internet.
      message = alerts.no_response
    } else if (exception) {
      /*
        Internal Server Errors with status 500.
        For eg: `#<ActiveRecord::PendingMigrationError: ...>`
        In development, they are not formatted and therefore are not part of the `errors` key.
      */
      message = exception
    } else if (errors) {
      errors.forEach(({ type, path, message: errorMessage }) => {
        if (type === errorTypes.BAD_REQUEST) {
          message = errorMessage || alerts.bad_request
        } else if (type === errorTypes.UNAUTHORIZED) {
          message = errorMessage || alerts.unauthorized
        } else if (type === errorTypes.FORBIDDEN) {
          message = errorMessage || alerts.forbidden
        } else if (type === errorTypes.NOT_FOUND) {
          message = errorMessage || alerts.not_found
        } else if (type === errorTypes.INTERNAL_SERVER_ERROR) {
          message = errorMessage || alerts.internal_server_error
        } else if (type === errorTypes.RESOURCE_LOCKED) {
          message = errorMessage || alerts.account_locked
        } else if (
          type === errorTypes.UNPROCESSABLE_ENTITY &&
          (!path || path === '')
        ) {
          message = errorMessage
        } else if (type === errorTypes.ACTIONABLE_WARNING) {
          message = errorMessage
        }

        errorType = type
      })

      if (!message) {
        submissionError = parseSubmissionError(errors)
      }
    }
  } else {
    /*
      Errors with status 200
      For eg: Variable input of type `*Input!` was provided invalid value
    */
    graphQLErrors.forEach(({ message: errorMessage }) => {
      if (
        import.meta.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'test'
      ) {
        message = errorMessage
      } else {
        message = alerts.other_error
      }
    })

    if (
      process.env.NODE_ENV === 'test' &&
      graphQLErrors &&
      graphQLErrors.length > 0
    ) {
      const {
        message: graphqlErrorMessage,
        extensions: { code: graphqlErrorType }
      } = graphQLErrors[0]

      message = graphqlErrorMessage
      errorType = graphqlErrorType
      submissionError = graphqlErrorMessage
    }
  }

  return { message, submissionError, errorType }
}

export default parseError
