import { gql } from '@apollo/client'
import { useContext } from 'react'

import AppContext from 'contexts/AppContext'
import useFeature, { featureTypes } from 'hooks/useFeature'
import usePermissions, { permissions } from 'hooks/usePermissions'
import useQuery from 'hooks/useQuery'
import { filterKeys, useFilterState } from 'contexts/FilterContext'

const MAX_PRACTICES = 100

export const practiceStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
}

export const practiceStatusOptions = [
  { label: 'Active', value: practiceStatus.ACTIVE },
  { label: 'Inactive', value: practiceStatus.INACTIVE }
]

export class Practice {}

Practice.fragments = {
  details: gql`
    fragment Practice_details on Practice {
      id
      name
      status
      accounts {
        id
        fullName
      }
    }
  `
}

export const PRACTICES_LIST_QUERY = gql`
  query PracticesListQuery(
    $first: Int!
    $skip: Int!
    $sort: String!
    $sortDirection: String!
    $filter: PracticeFilterInput!
  ) {
    practices(
      first: $first
      skip: $skip
      sort: $sort
      sortDirection: $sortDirection
      filter: $filter
    ) {
      ...Practice_details
    }
  }

  ${Practice.fragments.details}
`

export const CREATE_PRACTICE_MUTATION = gql`
  mutation CreatePracticeMutation($input: CreatePracticeInput!) {
    createPractice(input: $input) {
      ...Practice_details
    }
  }

  ${Practice.fragments.details}
`

export const UPDATE_PRACTICE_MUTATION = gql`
  mutation UpdatePracticeMutation($id: ID!, $input: UpdatePracticeInput!) {
    updatePractice(id: $id, input: $input) {
      ...Practice_details
    }
  }

  ${Practice.fragments.details}
`

export const DESTROY_PRACTICE_MUTATION = gql`
  mutation DestroyPracticeMutation($id: ID!) {
    destroyPractice(id: $id) {
      id
    }
  }
`

export const useInitialPractices = currentAccount => {
  const canViewPractices = usePermissions(permissions.VIEW_PRACTICES)

  const { loading, data: { practices = [] } = {} } = useQuery(
    PRACTICES_LIST_QUERY,
    {
      variables: {
        first: MAX_PRACTICES,
        skip: 0,
        sort: 'name',
        sortDirection: 'asc',
        filter: { accountId: currentAccount && currentAccount.id }
      },
      skip: !canViewPractices
    }
  )

  if (!currentAccount) return []

  const practiceOptions = practices.map(practice => ({
    label: practice.name,
    value: practice.id
  }))

  return [loading, practiceOptions]
}

export const useSelectPractice = (
  { isMultiSelect, onlyActive, onlyCurrentAccount, accountId } = {
    isMultiSelect: false,
    onlyActive: false,
    onlyCurrentAccount: false,
    accountId: null
  }
) => {
  const { currentAccount } = useContext(AppContext)
  const canViewPractices = usePermissions(permissions.VIEW_PRACTICES)
  const hasUsePracticesFeature = useFeature(featureTypes.USE_PRACTICES)

  const [selectedPractice, setSelectedPractice] = useFilterState(
    filterKeys.PRACTICE
  )
  const [selectedPractices, setSelectedPractices] = useFilterState(
    filterKeys.PRACTICES
  )

  let filter = {}
  if (onlyActive) {
    filter = { ...filter, status: practiceStatus.ACTIVE }
  }

  if (onlyCurrentAccount) {
    filter = { ...filter, accountId: accountId || currentAccount.id }
  }

  const { data: { practices = [] } = {} } = useQuery(PRACTICES_LIST_QUERY, {
    variables: {
      first: MAX_PRACTICES,
      skip: 0,
      sort: 'name',
      sortDirection: 'asc',
      filter
    },
    skip: !hasUsePracticesFeature || !canViewPractices
  })

  const practiceOptions = practices.map(practice => ({
    label: practice.name,
    value: practice.id
  }))

  return isMultiSelect === true
    ? [practiceOptions, selectedPractices, setSelectedPractices]
    : [practiceOptions, selectedPractice, setSelectedPractice]
}

export default {
  CREATE_PRACTICE_MUTATION,
  DESTROY_PRACTICE_MUTATION,
  PRACTICES_LIST_QUERY,
  practiceStatus,
  practiceStatusOptions,
  useInitialPractices,
  UPDATE_PRACTICE_MUTATION
}
