import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import Organization from 'models/Organization'
import Row from 'components/base/Row'
import SetupOrganizationForm from 'components/forms/SetupOrganizationForm'
import useMutation from 'hooks/useMutation'

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganizationMutation($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      email
      emr
      firstName
      lastName
      phoneNumber
      role
    }
  }
`

function SetupOrganization({ location: { state: { email, role } = {} } }) {
  const history = useHistory()
  const { token } = useParams()

  const [updateOrganization] = useMutation(UPDATE_ORGANIZATION_MUTATION, {
    onCompleted: response => {
      history.push({
        pathname:
          role === 'provider' &&
            Organization.isEmr(response.updateOrganization.emr)
            ? `/setup/${token}/setup-username`
            : `/setup/${token}/invite-providers`,
        state: {
          email,
          emr: response.updateOrganization.emr,
          role
        }
      })
    }
  })

  const handleUpdateOrganization = values => {
    const updateParameters = {
      ...values,

      token
    }

    return updateOrganization(updateParameters)
  }

  return (
    <Fragment>
      <AuthTitle title="Set Up Your Organization." />
      <Row>
        <Column span={3} />
        <Column span={6} spanMd={12}>
          <Card variant="secondary">
            <SetupOrganizationForm
              submitLabel="Next"
              onFormSubmit={handleUpdateOrganization}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default SetupOrganization
