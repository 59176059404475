import { useRef, useEffect } from 'react'

function useClickOutside(onClickOutside) {
  const nodeRef = useRef(null)

  useEffect(() => {
    const handleClick = event => {
      if (nodeRef.current && !nodeRef.current.contains(event.target)) {
        onClickOutside(event)
      }
    }

    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [onClickOutside])

  return [nodeRef]
}

export default useClickOutside
