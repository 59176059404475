import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import Row from 'components/base/Row'
import SetupUsernameForm from 'components/forms/SetupUsernameForm'
import Text from 'components/typography/Text'
import useMutation from 'hooks/useMutation'

export const SETUP_USERNAME_MUTATION = gql`
  mutation setupUsernameMutation($input: SetupUsernameInput!) {
    setupUsername(input: $input) {
      email
      firstName
      lastName
      phoneNumber
    }
  }
`

function SetupUsernamePage({ location: { state: { email } = {} } }) {
  const history = useHistory()
  const { token } = useParams()

  const handleSubmit = () =>
    history.push({
      pathname: `/setup/${token}/password`
    })

  const [setupUsername] = useMutation(SETUP_USERNAME_MUTATION, {
    onCompleted: handleSubmit
  })

  const handleSetupUsername = values => {
    const updateParameters = {
      ...values,

      token
    }

    return setupUsername(updateParameters)
  }

  return (
    <Fragment>
      <AuthTitle title="Enter your username." />
      <Row>
        <Column span={3} hiddenMd hiddenXs />
        <Column span={6} spanMd={12}>
          <Card variant="secondary">
            <Text size="medium" weight="medium" color="light" align="center">
              {email}
            </Text>
            <br />
            <SetupUsernameForm
              submitLabel="Next"
              onFormSubmit={handleSetupUsername}
            />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default SetupUsernamePage
