import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import { getProductTheme } from 'lib/CheckProduct'

const sizes = {
  xSmall: 8,
  small: 10,
  medium: 12,
  normal: 16,
  large: 20,
  xLarge: 22,
  xxLarge: 24,
  xxxLarge: 64
}

const useStyle = createUseStyles(({ colors }) => ({
  icon: {
    color: props => colors[props.color],
    fontSize: props => sizes[props.size]
  }
}))

function FontIcon({ className, color, showTitle, name, size, ...other }) {
  const classes = useStyle({ color, size })

  return (
    <span
      className={classNames('icon', `icon-${name}`, classes.icon, className)}
      title={showTitle ? name : ''}
      {...other}
    />
  )
}

FontIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(getProductTheme().colors)),
  showTitle: PropTypes.bool,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(sizes))
}

FontIcon.defaultProps = {
  className: null,
  color: 'secondary',
  showTitle: true,
  size: 'normal'
}

export default FontIcon
