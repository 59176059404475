import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthSelectInput from 'components/inputs/AuthSelectInput'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import emrs, { vetbuddyEmrs } from 'constants/emrs'
import isVetBuddyProduct from 'lib/CheckProduct'
import states from 'constants/states'
import usePersistedState from 'hooks/usePersistedState'
import validateSchema from 'lib/validators'
import { DB_USER_REGION_KEY } from 'models/Account'

const schema = yup.object().shape({
  city: yup.string().required(),
  emr: isVetBuddyProduct()
    ? yup.string().notRequired()
    : yup.string().required(),
  organizationName: isVetBuddyProduct()
    ? yup.string().notRequired()
    : yup.string().required(),
  state: yup.string().required(),
  street: yup.string().required()
})

function SetupOrganizationForm({ onFormSubmit }) {
  const [region] = usePersistedState(DB_USER_REGION_KEY, 'United States')

  const initialValues = isVetBuddyProduct()
    ? {
      emr: vetbuddyEmrs[0].value
    }
    : {}

  return (
    <Form
      validate={validateSchema(schema)}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoFocus
            component={AuthTextInput}
            name="organizationName"
            placeholder="Organization Name"
            showValidationMessage
          />
          {!isVetBuddyProduct() && (
            <Fragment>
              <Field
                variant="large"
                component={AuthSelectInput}
                name="emr"
                placeholder="Using EMR?"
                options={emrs}
                showValidationMessage
              />
              <ConditionalField when="emr" is="aprima">
                <Field
                  component={AuthTextInput}
                  name="identifier"
                  placeholder="Identifier"
                  showValidationMessage
                />
              </ConditionalField>
            </Fragment>
          )}
          <Field
            component={AuthTextInput}
            name="street"
            placeholder="Street"
            showValidationMessage
          />
          <Field
            component={AuthTextInput}
            name="city"
            placeholder="City"
            showValidationMessage
          />
          <Field
            variant="large"
            component={AuthSelectInput}
            name="state"
            placeholder="Select State"
            options={states[region]}
            showValidationMessage
          />
          <Field
            component={AuthTextInput}
            name="pinCode"
            placeholder="Zip Code"
          />
          <AuthFormFooter>
            <Button label="Next" disabled={submitting} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

SetupOrganizationForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default SetupOrganizationForm
