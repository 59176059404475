import React from 'react'
import { createUseStyles } from 'react-jss'

import { breakpointMax } from 'styles/mixins'

const useStyles = createUseStyles({
  container: {
    alignItems: ({ fullWidthContent }) => !fullWidthContent && 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    margin: ({ fullWidthContent }) => fullWidthContent ? 0 : [ 0, '5.5%' ],

    [breakpointMax('xs')]: {
      margin: [ 50, 0, 0, 0 ]
    }
  }
})

function MainContainer({ children, ...props }) {
  const classes = useStyles(props)

  return <main className={classes.container}>{children}</main>
}

export default MainContainer
