import React, { useState, useEffect } from 'react'
import { CachePersistor } from 'apollo3-cache-persist'

import cache from 'client/cache'
import createClient from 'client'
import Loader from 'components/loaders/Loader'
import usePersistedState from 'hooks/usePersistedState'
import { DB_USER_REGION_KEY } from 'models/Account'
import { logout } from 'client/methods'

const SCHEMA_VERSION = '5'
const SCHEMA_VERSION_KEY = 'apollo-schema-version'

const storage = window.localStorage
const persistor = new CachePersistor({
  cache,
  storage
})

function ClientProvider({ children }) {
  const [apolloClient, setApolloClient] = useState(null)
  const [userRegion] = usePersistedState(DB_USER_REGION_KEY, 'United States')

  // Handle cache purge and local storage changes, specifically for user region
  const onLocalStorageChange = event => {
    if (event.type === 'user-region-change') {
      const newRegion = storage.getItem(DB_USER_REGION_KEY)
      if (newRegion && userRegion !== newRegion) {
        logout(500).then(async () => persistor.purge())
      }
    }
  }

  useEffect(() => {
    window.addEventListener('user-region-change', onLocalStorageChange)

    return () => {
      window.removeEventListener('user-region-change', onLocalStorageChange)
    }
  }, [])

  // Persist the Apollo cache and manage schema version
  async function persistLocalCache() {
    // Create the Apollo client with the current user region
    const client = createClient(userRegion)

    const currentSchemaVersion = storage.getItem(SCHEMA_VERSION_KEY)

    if (currentSchemaVersion === SCHEMA_VERSION) {
      await persistor.restore().then(() => setApolloClient(client))
    } else {
      await persistor.purge().then(() => setApolloClient(client))
      storage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
    }
  }

  useEffect(() => {
    if (!apolloClient) {
      persistLocalCache()
    }
  }, [apolloClient])

  if (!apolloClient) {
    return <Loader />
  }

  return children({ apolloClient })
}

export default ClientProvider
