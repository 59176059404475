import React, { useContext } from 'react'
import { createUseStyles } from 'react-jss'

import AccountMenu from 'components/menu/AccountMenu'
import AlertBox from 'components/alerts/AlertBox'
import AppContext from 'contexts/AppContext'
import IdleTimer from 'components/internal/IdleTimer'
import Loader from 'components/loaders/Loader'
import NotificationsMenu from 'components/menu/NotificationsMenu'
import Sidebar from 'components/internal/Sidebar'
import useFeature, { featureTypes } from 'hooks/useFeature'

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  layout: {
    backgroundColor: colors.greyPale,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto 1fr',
    gridGap: '30px 66px',
    gridTemplateAreas: `
      "aside main menus"
    `
  },
  main: {
    gridArea: 'main',
    marginRight: 66
  },
  menus: {
    gridArea: 'menus',
    position: 'sticky',
    right: 0,
    zIndex: zIndexes.popup
  }
}))

function InternalLayout({ children, hideSideBar = false }) {
  const classes = useStyles()
  const { currentAccount = {}, hasUnreadNotifications = false } =
    useContext(AppContext)
  const canManageNotifications = useFeature(featureTypes.MANAGE_NOTIFICATIONS)
  const timeout = currentAccount.organization.idleTimeout || 0

  return (
    <div className={classes.layout}>
      <AlertBox />
      {timeout && <IdleTimer timeout={timeout} />}
      {!hideSideBar && <Sidebar />}
      <main className={classes.main}>
        {currentAccount ? children : <Loader />}
      </main>
      {/* This empty div prevents the menu items from overflowing into other elements */}
      <div />
      <div className={classes.menus}>
        {canManageNotifications && (
          <NotificationsMenu hasUnreadNotifications={hasUnreadNotifications} />
        )}
        <AccountMenu currentAccount={currentAccount} />
      </div>
    </div>
  )
}

export default InternalLayout
