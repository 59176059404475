import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthSelectInput from 'components/inputs/AuthSelectInput'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import validateSchema from 'lib/validators'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  role: yup.string().required()
})

function SetupAccountForm({ onFormSubmit }) {
  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoFocus
            component={AuthTextInput}
            name="firstName"
            placeholder="First Name"
            showValidationMessage
          />
          <Field
            component={AuthTextInput}
            name="lastName"
            placeholder="Last Name"
            showValidationMessage
          />
          <Field
            component={AuthSelectInput}
            name="role"
            placeholder="Role"
            options={[
              { value: 'provider', label: 'Provider' },
              { value: 'staff', label: 'Staff' }
            ]}
            showValidationMessage
            variant="large"
          />
          <AuthFormFooter>
            <Button label="Next" disabled={submitting} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

SetupAccountForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default SetupAccountForm
