const breakpoints = {
  xxs: 0,
  xs: 768,
  sm: 960,
  md: 1024,
  lg: 1280,
  xlg: 1480,
  xxlg: 1920
}

const breakpointMin = size => `@media (min-width: ${breakpoints[size]}px)`

const breakpointMax = size => `@media (max-width: ${breakpoints[size] - 1}px)`

const hideScrollbar = {
  scrollbarWidth: 'none',

  '::-webkit-scrollbar': {
    display: 'none'
  }
}

const makeRoundedCorners = radius => ({
  borderRadius: `${radius}px ${radius}px 0px ${radius}px`
})

const makeTransition =
  ({ transitionDuration = '0.3s', transitionTimingFunction = 'ease' } = {}) =>
  (transitionProperty = 'all') => ({
    transitionProperty,
    transitionDuration,
    transitionTimingFunction
  })

const transitionSimple = makeTransition()
const transitionFluid = makeTransition({
  transitionTimingFunction: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'
})

const truncate = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

const applyFlex = (align, justify, direction) => ({
  display: 'flex',
  alignItems: align,
  justifyContent: justify,
  flexDirection: direction
})

export {
  breakpointMax,
  breakpointMin,
  hideScrollbar,
  makeRoundedCorners,
  transitionFluid,
  transitionSimple,
  truncate,
  applyFlex
}
