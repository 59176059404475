import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'

import Alert from 'components/alerts/Alert'
import useClientMutation from 'hooks/useClientMutation'
import useClientQuery from 'hooks/useClientQuery'
import useClickOutside from 'hooks/useClickOutside'

import { GET_ALERT, CLOSE_ALERT } from 'client/state/alert'

const closeTimeoutInMS = 10000

const useStyles = createUseStyles(({ zIndexes }) => ({
  alertBox: {
    left: '50%',
    position: 'fixed',
    top: 65,
    transform: 'translateX(-50%)',
    zIndex: zIndexes.alert
  }
}))

function AlertBox() {
  const { data: { alert } = {} } = useClientQuery(GET_ALERT)
  const [closeAlert] = useClientMutation(CLOSE_ALERT)
  const classes = useStyles()

  const { isOpen, message, variant } = alert || {}

  useEffect(() => {
    const timer = setTimeout(closeAlert, closeTimeoutInMS)

    return () => clearTimeout(timer)
  }, [closeAlert])

  const handleClose = () => {
    closeAlert()
  }

  const [overlayRef] = useClickOutside(() => {
    closeAlert()
  })

  return (
    <div
      role="button"
      className={classes.alertBox}
      tabIndex={0}
      onClick={handleClose}
      onKeyDown={handleClose}
      ref={overlayRef}
      aria-label="Close Alert"
    >
      {message && <Alert isOpen={isOpen} message={message} variant={variant} />}
    </div>
  )
}

export default AlertBox
