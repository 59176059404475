import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import BaseLink from 'components/links/BaseLink'

const paddingList = {
  default: [0, 20, 14, 30],
  appointmentMenu: [0, 30, 10, 30]
}

const useStyles = createUseStyles(({ colors, typography }) => ({
  menuItem: props => ({
    color: colors[props.color] || colors.dark,
    flexDirection: 'column',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.small,
    fontWeight: typography.fontWeightVariants.medium,
    position: 'relative',
    padding: paddingList[props.padding] || paddingList.default,
    textAlign: 'left',

    '&:hover': {
      color: colors.secondary
    }
  })
}))

function MenuItem({ children, className, onClick, to, href, ...props }) {
  const classes = useStyles(props)

  return (
    <BaseLink
      className={classNames(classes.menuItem, className)}
      onClick={onClick}
      to={to}
      href={href}
      role="menuitem"
    >
      {children}
    </BaseLink>
  )
}

MenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string
}

MenuItem.defaultProps = {
  className: null,
  onClick: () => {},
  to: null,
  href: null
}

export default MenuItem
