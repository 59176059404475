import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import FontIcon from 'components/base/FontIcon'
import rgba from 'lib/rgba'
import { makeRoundedCorners } from 'styles/mixins'

const useStyles = createUseStyles(({ colors, typography, zIndexes }) => ({
  alert: {
    ...makeRoundedCorners(20),

    alignItems: 'center',
    color: colors.light,
    display: 'flex',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.small,
    padding: [20, 15, 20, 30],
    width: 510,
    zIndex: zIndexes.alert,

    '& > *': {
      marginRight: 15
    }
  },
  alert_success: {
    backgroundColor: colors.positive,
    boxShadow: `0px 10px 30px ${rgba(colors.positive, 0.5)}`
  },
  alert_failure: {
    backgroundColor: colors.negative,
    boxShadow: `0px 10px 30px ${rgba(colors.negative, 0.3)}`
  },
  alert_info: {
    backgroundColor: colors.grey,
    boxShadow: `0px 10px 30px ${rgba(colors.grey, 0.7)}`
  },
  message: {
    textTransform: 'capitalize'
  },
  alert_isHidden: {
    display: 'none'
  }
}))

function Alert({ isOpen, message, variant }) {
  const classes = useStyles()

  return (
    <div
      role="alert"
      className={
        isOpen
          ? classNames(classes.alert, classes[`alert_${variant}`])
          : classes.alert_isHidden
      }
    >
      <FontIcon name={variant} size="xLarge" color="light" />
      <span>
        <strong className={classes.message}>{`${variant}! `}</strong>
        {message}
      </span>
    </div>
  )
}

Alert.propTypes = {
  isOpen: PropTypes.bool,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'failure', 'info'])
}

Alert.defaultProps = {
  isOpen: false,
  variant: 'success'
}

export default Alert
