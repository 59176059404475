import { gql } from '@apollo/client'

const defaults = {
  auth: {
    emails: [],
    accountTokens: [],
    currentToken: null,
    __typename: 'Auth'
  }
}

const typeDefs = `
  type AccountToken {
    accountId: String!
    dashxToken: String!
    token: String!
    __typename: String!
  }
`

const GET_AUTH = gql`
  query GetAuthQuery {
    auth @client {
      emails
      currentToken
      accountTokens {
        accountId
        dashxToken
        token
      }
    }
  }
`

const ADD_EMAIL = gql`
  mutation addEmailMutation($email: EmailInput!) {
    addEmail(email: $email) @client
  }
`

const ADD_ACCOUNT_TOKEN = gql`
  mutation addAccountTokenMutation(
    $accountId: ID!
    $dashxToken: String!
    $token: String!
  ) {
    addAccountToken(
      accountId: $accountId
      dashxToken: $dashxToken
      token: $token
    ) @client
  }
`

const LOGIN = gql`
  mutation login($accountId: ID!) {
    login(accountId: $accountId) @client
  }
`

const LOGOUT = gql`
  mutation LogoutMutation {
    logout @client
  }
`

const DESTROY_SESSION_MUTATION = gql`
  mutation DestroySessionMutation($input: DestroySessionInput!) {
    destroySession(input: $input) {
      id
      accessToken
      dashxToken
      expiresAt
      externalToken
      status
      verificationUrl

      account {
        id
      }
    }
  }
`

const resolvers = {
  Mutation: {
    addEmail: (_obj, { email }, { cache }) => {
      const {
        auth: { emails, ...rest }
      } = cache.readQuery({ query: GET_AUTH })

      if (emails.indexOf(email) === -1) {
        cache.writeQuery({
          query: GET_AUTH,
          data: {
            auth: {
              ...rest,
              emails: [email, ...emails],
              __typename: 'Auth'
            }
          }
        })
      }

      return null
    },
    addAccountToken: (_obj, { accountId, dashxToken, token }, { cache }) => {
      const { auth: { accountTokens, ...rest } = {} } = cache.readQuery({
        query: GET_AUTH
      })

      const newAccountToken = {
        accountId,
        dashxToken,
        token,
        __typename: 'AccountToken'
      }

      const otherAccountTokens = accountTokens.filter(
        accountToken => accountToken.accountId !== accountId
      )

      cache.writeQuery({
        query: GET_AUTH,
        data: {
          auth: {
            ...rest,
            accountTokens: [newAccountToken, ...otherAccountTokens],
            __typename: 'Auth'
          }
        }
      })

      return null
    },
    login: (_obj, { accountId }, { cache }) => {
      const { auth: { accountTokens, ...rest } = {} } = cache.readQuery({
        query: GET_AUTH
      })

      const selectedAccountTokens = accountTokens.find(
        accountToken => accountToken.accountId === accountId
      )

      if (selectedAccountTokens) {
        cache.writeQuery({
          query: GET_AUTH,
          data: {
            auth: {
              ...rest,
              accountTokens,
              currentToken: selectedAccountTokens.token,
              __typename: 'Auth'
            }
          }
        })
      }

      return null
    },
    logout: (_obj, _args, { cache }) => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('selectedProviderTabForOpReportAndImages')
      localStorage.removeItem('selectedProviderTabForNotes')

      const {
        auth: { accountTokens, currentToken, ...rest }
      } = cache.readQuery({ query: GET_AUTH })

      const otherAccountTokens = accountTokens.filter(
        accountToken => accountToken.token !== currentToken
      )

      cache.writeQuery({
        query: GET_AUTH,
        data: {
          auth: {
            ...rest,
            accountTokens: otherAccountTokens,
            currentToken: null,
            __typename: 'Auth'
          }
        }
      })

      return null
    }
  }
}

const auth = { defaults, resolvers, typeDefs }

export {
  ADD_ACCOUNT_TOKEN,
  ADD_EMAIL,
  DESTROY_SESSION_MUTATION,
  GET_AUTH,
  LOGIN,
  LOGOUT
}

export default auth
