const filterKeyEnter = handler => e => {
  if (e.keyCode === 13) {
    handler(e)
  }
}

export default function accessibleOnClick(handler, role = 'button') {
  if (!handler) return null
  const props = {
    onKeyDown: filterKeyEnter(handler),
    onClick: handler
  }
  if (role) {
    props.role = role
  }
  return props
}
