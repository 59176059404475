import React, { Fragment, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

import AccountVerificationForm from 'components/forms/AccountVerificationForm'
import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import Row from 'components/base/Row'
import TextLink from 'components/links/TextLink'
import useMutation from 'hooks/useMutation'

import {
  SEND_VERIFICATION_CODE_MUTATION,
  ACCOUNT_VERIFICATION_MUTATION
} from 'models/Account'

function AccountVerificationPage({ location: { state: { account } = {} } }) {
  const { t } = useTranslation()
  const history = useHistory()
  const [verificationCode, setVerificationCode] = useState()

  const [sendVerificationCode] = useMutation(
    SEND_VERIFICATION_CODE_MUTATION,
    {}
  )

  useEffect(() => {
    if (account && account.status !== 'active') {
      sendVerificationCode({ id: account.id })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [verifyAccount] = useMutation(ACCOUNT_VERIFICATION_MUTATION, {
    onCompleted: () => {
      history.push({
        pathname: '/complete-registration',
        state: { account, verificationCode }
      })
    }
  })

  const handleSubmit = values => {
    setVerificationCode(values.verificationCode)

    const params = {
      ...values,
      id: account.id
    }
    return verifyAccount(params)
  }

  if (!account || account.status === 'active') {
    return <Redirect to="/login" />
  }

  return (
    <Fragment>
      <AuthTitle subtitle="" title={t('accountVerificationPage.title')} />
      <Row>
        <Column span={3} hiddenXs />
        <Column span={6}>
          <Card variant="secondary">
            <AccountVerificationForm onFormSubmit={handleSubmit} />
          </Card>
        </Column>
      </Row>
      <Row />
      <AuthTitle
        subtitle={
          <p>
            <Trans
              i18nKey="accountVerificationPage.subtitle.resendInstructions"
              components={{
                supportEmail: (
                  <TextLink
                    label="support@docbuddy.com"
                    href="mailto:support@docbuddy.com"
                  />
                )
              }}
            />
          </p>
        }
      />
    </Fragment>
  )
}

export default AccountVerificationPage
