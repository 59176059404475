import React from 'react'
import { createUseStyles } from 'react-jss'

import { breakpointMax } from 'styles/mixins'

const useStyles = createUseStyles(({ units }) => ({
  footer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 10,
    position: 'absolute',
    right: units.cardRightPadding,
    width: `calc(100% - ${units.cardLeftPadding + units.cardRightPadding}px)`,

    [breakpointMax('xs')]: {
      bottom: 0,
      flexDirection: 'column',
      right: '50%',
      transform: 'translate(50%, 50%)',
      width: 'auto'
    }
  }
}))

function AuthFormFooter({ children, ...props }) {
  const classes = useStyles(props)

  return <footer className={classes.footer}>{children}</footer>
}

export default AuthFormFooter
