export const initialRecommendations = {
  message: '',
  result: [
    {
      id: '123',
      recommendedBy: {
        createdOn: '2022-11-21T19:33:18.265Z',
        email: 'dbuddy1@training.com',
        externalUid: '1927',
        firstName: 'Doc',
        id: '597',
        lastName: 'Buddy',
        organizationId: '339',
        role: 'doctor',
        updatedOn: '2022-11-21T19:33:18.265'
      },
      sourceOrg: {
        id: '339',
        name: 'The Steadman Clinic'
      },
      status: 'new',
      comments: 'This is a test comment about a recommendation.',
      meta: {
        lastUpdated: '2022-04-15T06:00:00.000Z',
        created: '2021-12-20T05:00:00.000Z'
      },
      patient: {
        id: '12753877',
        meta: {
          versionId: '0',
          lastUpdated: '2021-05-21T16:02:17.000Z'
        },
        basic: {
          externalId: '12753877',
          addresses: [
            {
              id: 'CI-24365387-0',
              line1: 'Mailing',
              line2: 'Apt 607',
              city: 'Cookeville',
              state: 'TN',
              postalCode: '38501',
              type: 'postal'
            },
            {
              id: 'CI-24365387-1',
              line1: 'Physical',
              line2: 'Apt 602',
              city: 'Austin',
              state: 'TX',
              postalCode: '78745',
              type: 'both'
            },
            {
              id: 'CI-24365387-2',
              line1: 'Physical',
              line2: 'Apt 602',
              city: 'Dallas',
              state: 'TX',
              postalCode: '78745',
              type: 'physical'
            }
          ],
          phoneNumbers: [
            { value: '6298124761', use: 'home' },
            { value: '6298124762', use: 'mobile' },
            { value: '6298124763', use: 'work' },
            { value: '6298124764', use: 'home' },
            { value: '6298124765', use: 'mobile' },
            { value: '6298124766', use: 'mobile' }
          ],
          email: 'test@relatient.net',
          mrn: '7780',
          firstName: 'James',
          lastName: 'Street'
        },
        identifier: [
          {
            use: 'usual',
            system: 'urn:oid:2.16.840.1.113883.3.787.0.0',
            value: '7780',
            type: {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
                  code: 'MR',
                  display: 'MRN'
                }
              ],
              text: 'MRN'
            }
          },
          {
            use: 'usual',
            system: 'urn:oid:2.16.840.1.113883.6.1000',
            value: '15761',
            type: {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
                  code: 'MR',
                  display: 'MRN'
                }
              ],
              text: 'MRN'
            }
          }
        ],
        active: true,
        name: [
          {
            id: 'CI-12753877-0',
            use: 'official',
            text: 'Ortiz, Helen Gracie',
            family: 'Ortiz',
            given: ['Helen', 'Gracie']
          }
        ],
        telecom: [
          {
            id: 'CI-PH-29865803-0',
            extension: [
              {
                valueUrl: '6298124761',
                url: 'http://hl7.org/fhir/StructureDefinition/iso21090-TEL-address'
              }
            ],
            system: 'phone',
            value: '6298124761',
            use: 'home',
            rank: 1
          },
          {
            id: 'CI-PH-29865805-1',
            extension: [
              {
                valueUrl: '6298124761',
                url: 'http://hl7.org/fhir/StructureDefinition/iso21090-TEL-address'
              }
            ],
            system: 'phone',
            value: '6298124761',
            use: 'mobile',
            rank: 2
          },
          {
            id: 'CI-EM-29865807-0',
            system: 'email',
            value: 'test@relatient.net',
            use: 'home',
            rank: 1
          }
        ],
        birthDate: '1977-08-25',
        address: [
          {
            id: 'CI-24365387-0',
            use: 'home',
            text: '400 Test Ave\nAPT 607\nCookeville, TN 38501',
            line: ['400 Test Ave', 'Apt 607'],
            city: 'Cookeville',
            state: 'TN',
            postalCode: '38501',
            type: 'physical'
          }
        ],
        managingOrganization: { name: 'Vail Health', id: '1' }
      },
      attachments: [
        {
          url: 'https://docbuddy-staging.s3.amazonaws.com/uploads/on_call_record/demographics_images/1298/4599841f-21f7-4b5b-9b6f-c5ee276c2562.jpg'
        }
      ],
      coverage: {
        basic: {
          groupValue: '129829892',
          planValue: '121321303',
          subscriberId: ''
        }
      },
      destinationOrg: {
        id: '378',
        name: 'Vail Health'
      }
    }
  ]
}

export const searchRecommendations = initialRecommendations.result.map(rec => {
  const { patient } = rec
  Object.keys(patient).forEach(key => {
    if (typeof patient[key] === 'string') {
      patient[key] = patient[key].toLowerCase()
    }
  })
  return { ...rec, ...patient }
})
