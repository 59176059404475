// import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ colors, blockColors }) => ({
  spin: {
    animation: '$rotation 1s linear infinite',
    border: `solid 2px ${colors.greyPale}`,
    borderBottomColor: blockColors.linkWater,
    borderRadius: '50%',
    content: '',
    height: 22,
    width: 22,
    display: 'inline-block'
  },

  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}))

function Spinner() {
  const classes = useStyles()

  return <div className={classes.spin} />
}

// Spinner.propTypes = {}

// Spinner.defaultProps = {}

export default Spinner
