import { gql } from '@apollo/client'

const defaults = {
  alert: {
    isOpen: false,
    variant: 'failure',
    message: null,
    __typename: 'Alert'
  }
}

const GET_ALERT = gql`
  query AlertQuery {
    alert @client {
      isOpen
      message
      variant
    }
  }
`

const ALERT_FAILURE = gql`
  mutation OpenFailureAlertMutation($alert: AlertInput!) {
    openFailureAlert(alert: $alert) @client
  }
`

const ALERT_SUCCESS = gql`
  mutation OpenSuccessAlertMutation($alert: AlertInput!) {
    openSuccessAlert(alert: $alert) @client
  }
`

const CLOSE_ALERT = gql`
  mutation CloseAlertMutation {
    closeAlert @client
  }
`

const resolvers = {
  Mutation: {
    closeAlert: (_obj, _args, { cache }) => {
      cache.writeQuery({
        query: GET_ALERT,
        data: {
          alert: { ...defaults.alert }
        }
      })

      return null
    },
    openFailureAlert: (_obj, args, { cache }) => {
      cache.writeQuery({
        query: GET_ALERT,
        data: {
          alert: {
            isOpen: true,
            variant: 'failure',
            ...args.alert,
            __typename: 'Alert'
          }
        }
      })

      return null
    },
    openSuccessAlert: (_obj, args, { cache }) => {
      cache.writeQuery({
        query: GET_ALERT,
        data: {
          alert: {
            isOpen: true,
            variant: 'success',
            ...args.alert,
            __typename: 'Alert'
          }
        }
      })

      return null
    }
  }
}

const alert = {
  defaults,
  resolvers
}

export { ALERT_FAILURE, ALERT_SUCCESS, CLOSE_ALERT, GET_ALERT }

export default alert
