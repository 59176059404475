import Bugsnag from '@bugsnag/js'
import { cloneDeep } from 'lodash'
import { filter } from 'graphql-anywhere'
import { useMutation as useApolloMutation } from '@apollo/client'

import componentQuery from 'lib/componentQuery'
import MutationResponseModes from 'constants/responsemodes'
import parseError, { errorTypes } from 'lib/parseError'
import { hideAlertFailure, showAlertFailure } from 'client/methods'

const findMutationMethodNameOrAlias = mutation => {
  const selection = mutation.definitions[0].selectionSet.selections[0]
  return (selection.alias && selection.alias.value) || selection.name.value
}

const findQueryFieldNameOrAlias = query => {
  const selection = query.definitions[0].selectionSet.selections[0]
  return (selection.alias && selection.alias.value) || selection.name.value
}

const addRecords = (
  fieldName = '',
  currentRecords = [],
  responseRecords = [],
  mode = MutationResponseModes.APPEND
) => {
  let newRecords = []
  try {
    newRecords = [...responseRecords].filter(
      record => !currentRecords.some(current => current.id === record.id)
    )
  } catch (error) {
    Bugsnag.notify(error)
  }

  switch (mode) {
  case MutationResponseModes.APPEND:
    return { [fieldName]: [...currentRecords, ...newRecords] }
  case MutationResponseModes.PREPEND:
    return { [fieldName]: [...newRecords, ...currentRecords] }
  default:
    throw new Error('Incorrect `mode` specified when using addRecords.')
  }
}

const deleteRecords = (
  fieldName = '',
  currentRecords = [],
  responseRecords = [],
  key = 'id'
) => {
  const deleteIds = responseRecords.map(record => record[key])

  return {
    [fieldName]: currentRecords.filter(record => {
      if (deleteIds.includes(record[key])) {
        return false
      }

      return true
    })
  }
}

function useMutation(mutation, options = {}) {
  const [mutate, result] = useApolloMutation(mutation, options)
  const loadedComponentQuery = componentQuery.get()

  const enhancedMutate = values => {
    const {
      query: mutationQuery,
      variables: mutationQueryVariables,
      mode = MutationResponseModes.IGNORE,
      inputFilter,
      updateData
    } = options

    const { id, update, ...rawInput } = values || {}
    const input = inputFilter ? filter(inputFilter, rawInput) : rawInput
    const query =
      mutationQuery || (loadedComponentQuery && loadedComponentQuery.query)
    const variables =
      mutationQueryVariables ||
      (loadedComponentQuery && loadedComponentQuery.variables)

    const responseName = findMutationMethodNameOrAlias(mutation)

    const config = {
      variables: { id, input },
      update
    }

    if (mode !== MutationResponseModes.IGNORE) {
      config.update = (cache, { data }) => {
        // We need to mutate the query data in order to update
        // See: https://github.com/apollographql/apollo-client/issues/3909

        const currentRecords = cloneDeep(cache.readQuery({ query, variables }))
        const fieldName = findQueryFieldNameOrAlias(query)

        /*
          Convert response to array for mutations like BatchCreate*
        */

        const responseRecords =
          data[responseName].constructor === Array
            ? data[responseName]
            : [data[responseName]]

        let updatedRecords = []
        if (
          mode === MutationResponseModes.APPEND ||
          mode === MutationResponseModes.PREPEND
        ) {
          updatedRecords = addRecords(
            fieldName,
            currentRecords[fieldName],
            responseRecords,
            mode
          )
        } else if (mode === MutationResponseModes.DELETE) {
          updatedRecords = deleteRecords(
            fieldName,
            currentRecords[fieldName],
            responseRecords
          )
        } else if (mode === MutationResponseModes.CUSTOM) {
          if (!updateData) {
            throw new Error(
              'You must specify `updateData` for CUSTOM mutation mode.'
            )
          }

          updatedRecords = updateData({
            currentRecords,
            responseRecords,
            variables
          })
        }

        try {
          cache.writeQuery({ data: updatedRecords, query, variables })
        } catch (error) {
          Bugsnag.notify(error)
        }
      }
    }

    return mutate(config).catch(error => {
      const { message, submissionError, errorType } = parseError(error)

      if (errorType === errorTypes.ACTIONABLE_WARNING && options.onWarning) {
        hideAlertFailure(() => options.onWarning({ message }))

        return submissionError
      }

      if (message) {
        showAlertFailure({ message })
      }

      if (options.onError) {
        options.onError(error)
      }

      return submissionError
    })
  }

  return [enhancedMutate, result]
}

export default useMutation
