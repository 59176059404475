import dx from 'lib/dashx'
import { last } from 'lodash'
import { useEffect, useState } from 'react'

function useFetchPage(route) {
  const [page, setPage] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const filter = route ? { route: { eq: route } } : {}

    dx.searchRecords('page')
      .preview(import.meta.env.VITE_ENV !== 'production')
      .filter(filter)
      .all()
      .then(data => {
        if (data) {
          setPage(last(data))
          setLoading(false)
        }
      })
  }, [route])

  return [page, loading]
}

export default useFetchPage
