import PropTypes from 'prop-types'
import Modal from 'react-modal'
import React from 'react'

import rgba from 'lib/rgba'
import { transitionFluid, breakpointMax } from 'styles/mixins'
import { createUseStyles } from 'react-jss'

const closeTransitionDuration = 300

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  overlay: {
    ...transitionFluid(),
    backgroundColor: rgba(colors.light, 0.75),
    backdropFilter: 'blur(25px)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: zIndexes.modal
  },

  modal: {
    ...transitionFluid(),
    background: colors.greyExtraLight,
    borderRadius: 0,
    bottom: 0, // This is the key difference for the modal to slide in from the bottom
    boxShadow: `0px 30px 80px ${rgba(colors.primary, 0.12)}`,
    left: '50%',
    maxWidth: '100%',
    overflow: ({ overflow }) => overflow,
    padding: 0,
    position: 'fixed',
    right: 'auto',
    top: 'auto', // Top is set to auto, so it slides up from the bottom
    transform: 'translateX(-50%)',
    width: ({ width }) => width,
    height: '100%', // Take the full height of the screen
    transition: 'transform 0.3s ease-out', // Smooth slide-in animation
    zIndex: zIndexes.modal,

    [breakpointMax('xs')]: {
      width: () => 320
    }
  },

  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const rootElement = document.getElementById('root')

function FullScreenModal({ children, width, variant, overflow, ...props }) {
  const classes = useStyles({ width, variant, overflow })

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { variant })
  )

  return (
    <Modal
      appElement={rootElement}
      closeTimeoutMS={closeTransitionDuration}
      className={classes.modal}
      overlayClassName={classes.overlay}
      aria={{
        label: props.ariaLabel ? props.ariaLabel : 'modal'
      }}
      {...props}
    >
      <div className={classes.container}>{childrenWithProps}</div>
    </Modal>
  )
}

FullScreenModal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(['compact', 'default']),
  overflow: PropTypes.oneOf(['hidden', 'visible'])
}

FullScreenModal.defaultProps = {
  width: '100%',
  variant: 'default',
  overflow: 'hidden'
}

export default FullScreenModal
