import React, { createContext, useContext, useState, useMemo } from 'react'

const ScrollContext = createContext()

export function ScrollProvider({ children }) {
  const [scrollPositions, setScrollPositions] = useState({})
  const [prevPath, setPrevPath] = useState('')

  const value = useMemo(
    () => ({
      scrollPositions,
      setScrollPositions,
      prevPath,
      setPrevPath
    }),
    [scrollPositions, prevPath]
  )

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  )
}

export const useScrollContext = () => useContext(ScrollContext)
