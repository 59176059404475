const MutationResponseModes = Object.freeze({
  IGNORE: 'IGNORE',
  APPEND: 'APPEND',
  PREPEND: 'PREPEND',
  DELETE: 'DELETE',
  REPLACE: 'REPLACE',
  CUSTOM: 'CUSTOM'
})

export default MutationResponseModes
