import { useEffect, useState, useRef } from 'react'

function useHover(isParentHovering, delay = 0) {
  const timeout = useRef()

  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    if (!isParentHovering) {
      clearTimeout(timeout)
      setIsHovering(false)
    }
  }, [isParentHovering, timeout])

  return [
    isHovering,
    {
      onMouseEnter: () => {
        clearTimeout(timeout.current)
        setIsHovering(true)
      },
      onMouseLeave: () => {
        timeout.current = setTimeout(() => setIsHovering(false), delay)
      },
      onFocus: () => {
        clearTimeout(timeout.current)
        setIsHovering(true)
      },
      onBlur: () => {
        timeout.current = setTimeout(() => setIsHovering(true), delay)
      }
    }
  ]
}

export default useHover
