import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Text from 'components/typography/Text'
import { makeRoundedCorners } from 'styles/mixins'

const useStyles = createUseStyles(({ colors, typography, zIndexes }) => {
  const initialPosition = 'translateY(-30%)'
  const finalPosition = 'translateY(0%)'

  return {
    statusMessage: {
      ...makeRoundedCorners(12),

      alignItems: 'flex-end',
      animation: '$slide-down ease 0.4s',
      backgroundColor: colors.negativePale,
      bottom: -25,
      display: 'flex',
      fontSize: typography.fontSizeVariants.xsmall,
      height: '100%',
      position: 'absolute',
      width: '100%',
      zIndex: zIndexes.background
    },
    statusMessage_expanded: {
      justifyContent: 'flex-end',
      padding: [ 5, 15 ]
    },
    statusMessage_compact: {
      height: 22,
      justifyContent: 'flex-end',
      padding: [ 3, 30 ],
      width: 'auto'
    },

    '@keyframes slide-down': {
      '0%': {
        transform: initialPosition
      },
      '100%': {
        transform: finalPosition
      }
    }
  }
})

function FieldError({ error, variant }) {
  const classes = useStyles()

  return (
    <div className={classNames(classes.statusMessage, classes[`statusMessage_${variant}`])}>
      <Text color="negative" size="xxxSmall" weight="medium">{error}</Text>
    </div>
  )
}

FieldError.propTypes = {
  error: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([ 'compact', 'expanded' ])
}

FieldError.defaultProps = {
  variant: 'expanded'
}

export default FieldError
