import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import alerts from 'constants/alerts'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import ForgotPasswordForm from 'components/forms/ForgotPasswordForm'
import PageTitle from 'components/typography/PageTitle'
import Row from 'components/base/Row'
import useMutation from 'hooks/useMutation'
import { showAlertSuccess } from 'client/methods'

export const SEND_RESET_PASSWORD_INSTRUCTIONS = gql`
  mutation SendResetPasswordInstructionsMutation(
    $input: SendResetPasswordInstructionsInput!
  ) {
    sendResetPasswordInstructions(input: $input) {
      id
    }
  }
`

function ForgotPasswordPage({ location: { state: { account } = {} } }) {
  const { t } = useTranslation()
  const { organizationId } = account
  const history = useHistory()

  const [sendResetPasswordInstructions] = useMutation(
    SEND_RESET_PASSWORD_INSTRUCTIONS,
    {
      onCompleted: () => {
        showAlertSuccess({ message: alerts.forgot_password })
        history.push('/login')
      }
    }
  )

  const handleSubmit = values => {
    const params = {
      organizationId,

      ...values
    }

    return sendResetPasswordInstructions(params)
  }

  return (
    <Fragment>
      <Row>
        <Column span={3} hiddenMd hiddenXs />
        <Column span={6} spanMd={12}>
          <PageTitle>{t('forgotPasswordPage.title')}</PageTitle>
        </Column>
      </Row>
      <Row>
        <Column span={3} hiddenMd hiddenXs />
        <Column span={6} spanMd={12}>
          <Card variant="secondary">
            <ForgotPasswordForm onFormSubmit={handleSubmit} />
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default ForgotPasswordPage
