import { useMutation as useApolloMutation } from '@apollo/client'

function useClientMutation(mutation, options = {}) {
  const [mutate, result] = useApolloMutation(mutation, options)

  const newMutate = values => mutate({ variables: values })

  return [newMutate, result]
}

export default useClientMutation
