import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import AppContext from 'contexts/AppContext'
import Loader from 'components/loaders/Loader'

const sessionStates = Object.freeze({
  LOGGED_IN: 'logged_in',
  LOGGED_OUT: 'logged_out'
})

function ProtectedRoute({
  component: Component,
  layout: Layout,
  layoutProps,
  requiredSessionState,
  title,
  ...rest
}) {
  const { isLoggedIn } = useContext(AppContext)
  const location = useLocation()

  const helmet = title && (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )

  const referrer = location.pathname
  const isAdminRoute = referrer.includes('/admin')

  return (
    <Route
      {...rest}
      render={props => {
        if (requiredSessionState === sessionStates.LOGGED_IN && !isLoggedIn) {
          return (
            <Redirect
              to={`/login${!isAdminRoute ? `?referrer=${referrer}` : ''}`}
            />
          )
        }

        return (
          <Layout {...layoutProps}>
            {helmet}
            <React.Suspense fallback={<Loader />}>
              <Component {...props} />
            </React.Suspense>
          </Layout>
        )
      }}
    />
  )
}

ProtectedRoute.propTypes = {
  layoutProps: PropTypes.object,
  requiredSessionState: PropTypes.oneOf(Object.values(sessionStates)),
  title: PropTypes.string
}

ProtectedRoute.defaultProps = {
  layoutProps: {},
  requiredSessionState: null,
  title: null
}

ProtectedRoute.sessionStates = sessionStates

export default ProtectedRoute
