import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import AuthTextLink from 'components/links/AuthTextLink'
import Button from 'components/buttons/Button'
import Hidden from 'components/base/Hidden'

import { isSurgicalNotesProduct } from 'lib/CheckProduct'
import validateSchema from 'lib/validators'

const createSchema = t =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('loginPage.form.fields.email.validationMessage'))
      .required()
  })

const helpURL = `${import.meta.env.VITE_MARKETING_SITE_BASE_URL}/request-a-demo`

function LoginForm({ onFormSubmit }) {
  const { t } = useTranslation()
  const schema = createSchema(t)

  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoFocus
            component={AuthTextInput}
            icon={isSurgicalNotesProduct() ? 'surgical_notes_email' : 'email'}
            name="email"
            placeholder={t('loginPage.form.fields.email.placeholder')}
            showValidationMessage
            showValidationUI
            trimInput
          />
          <AuthFormFooter>
            <Button
              label={t('loginPage.form.buttons.login')}
              disabled={submitting}
            />
            <Hidden xs>
              <AuthTextLink href={helpURL} iconRight="angle-right">
                {t('loginPage.form.links.help')}
              </AuthTextLink>
            </Hidden>
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

LoginForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default LoginForm
