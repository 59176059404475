import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function useScrollTo(options) {
  const location = useLocation()

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(options)
    }
  }, [ location, options ])

  return null
}

export default useScrollTo
