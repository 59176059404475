/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import _ from 'lodash'
import Cookie from 'js-cookie'

export const baseUrl = import.meta.env.VITE_REST_API_BASE_URL

export const axiosInstance = axios.create({
  baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error)
)

const api = {
  // eslint-disable-next-line consistent-return
  httpRequest: async (path, method, body, params) => {
    const sanitizedParams = _.omitBy(
      params,
      param => _.isUndefined(param) || _.isNull(param) || param === ''
    )

    const options = {}
    if (body instanceof FormData) {
      options.headers = {
        'Content-Type': 'multipart/form-data'
      }
    }

    try {
      if (method.toLowerCase() === 'post') {
        const response = await axiosInstance.post(
          `${baseUrl}/${path}`,
          body,
          options
        )

        return {
          message: response.data.message,
          result: response.data.result
        }
      }

      if (method.toLowerCase() === 'put') {
        const response = await axiosInstance.put(`${baseUrl}/${path}`, body, {
          params: sanitizedParams
        })

        return {
          message: response.data.message,
          result: response.data.result
        }
      }

      if (method.toLowerCase() === 'get') {
        if (path.includes('csv')) {
          const response = await axiosInstance.get(`${baseUrl}/${path}`, {
            params: sanitizedParams,
            responseType: 'blob'
          })

          return response
        }

        const response = await axiosInstance.get(`${baseUrl}/${path}`, {
          params: sanitizedParams
        })

        return {
          message: response.data.message,
          result: response.data.result
        }
      }
    } catch (error) {
      if (error.response) {
        if (!error.response.data.code || !error.response.data.message) {
          return {
            error: `${error.response.status} - ${error.response.statusText}`
          }
        }
        return {
          error: `${error.response.data.code} - ${error.response.data.message}`
        }
      }
      if (error.request) {
        return {
          error: 'There was a problem with your request. Please try again.'
        }
      }
      return {
        error: error.message
      }
    }
  }
}

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const { response, config } = error
    const graphQLAccessToken = Cookie.get('access-token')
    if (
      (response?.status === 401 || response?.status === 403) &&
      !config._retry &&
      !config.url.includes('auth/login')
    ) {
      config._retry = true
      try {
        const data = await api.httpRequest(`auth/login`, 'POST', {
          userToken: graphQLAccessToken
        })
        const {
          access: {
            token: integrationHubAccessToken,
            expires: accessTokenExpiresAt
          }
        } = data.result.tokens

        localStorage.setItem('accessToken', integrationHubAccessToken)
        localStorage.setItem('accessTokenExpiresAt', accessTokenExpiresAt)

        config.headers.Authorization = `Bearer ${integrationHubAccessToken}`
        return axiosInstance(config)
      } catch (e) {
        return Promise.reject(e)
      }
    }
    return Promise.reject(error)
  }
)

export default api
