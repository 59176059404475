import React from 'react'

import Card from 'components/cards/Card'
import PageTitle from 'components/typography/PageTitle'
import Text from 'components/typography/Text'

function CompletedPage() {
  return (
    <Card>
      <PageTitle>All done! Your application is being processed.</PageTitle>
      <Text size="large" weight="regular" color="light">
        Thank you for your submission.
        A member of our support team will get in touch with you shortly to complete the
        activation process.
      </Text>
    </Card>
  )
}

export default CompletedPage
