const emrs = [
  { value: 'standalone', label: "I don't have an EMR" },
  { value: 'allscripts', label: 'AllScripts' },
  { value: 'aprima', label: 'Aprima' },
  { value: 'athenahealth', label: 'athenahealth' },
  { value: 'CenterIntegrated', label: 'Center Integrated' },
  { value: 'cerner', label: 'Cerner' },
  { value: 'modmed', label: 'Modmed' },
  { value: 'epic-systems', label: 'Epic Systems' },
  { value: 'eclinicalworks', label: 'eClinicalWorks' },
  { value: 'ge-healthcare', label: 'GE Healthcare' },
  { value: 'greenway', label: 'Greenway' },
  { value: 'gmed', label: 'GMed' },
  { value: 'intermountain', label: 'Intermountain' },
  { value: 'mckesson', label: 'McKesson' },
  { value: 'meditech', label: 'MEDITECH' },
  { value: 'nextgen', label: 'NextGen' },
  { value: 'practice-fusion', label: 'Practice Fusion' }
]

export const vetbuddyEmrs = [{ value: 'standalone', label: 'Standalone' }]

export default emrs
