import { createUploadLink } from 'apollo-upload-client'
import { regionAcronyms } from 'models/Account'

const getBaseURI = region => {
  if (!region || region === 'United States')
    return import.meta.env.VITE_API_BASE_URL

  return import.meta.env[
    `VITE_API_BASE_URL_${regionAcronyms[region].toUpperCase()}`
  ]
}

const httpLink = region => {
  const baseURI = getBaseURI(region)
  return createUploadLink({
    uri: `${baseURI}/graphql`
  })
}

export default httpLink
