import createClient from 'client'
import dx from 'lib/dashx'

import { ALERT_FAILURE, ALERT_SUCCESS, CLOSE_ALERT } from 'client/state/alert'
import { LOGOUT } from 'client/state/auth'
import { DB_USER_REGION_KEY } from 'models/Account'

const client = createClient(localStorage.getItem(DB_USER_REGION_KEY))

const logout = (delay = 2000) =>
  client.mutate({ mutation: LOGOUT }).then(() => {
    dx.reset()
    /**
     * Used client.clearStore() instead of client.resetStore()
     * in order to unsubscribe from the active queries so there
     * are no network/graphql errors when token is not
     * present. Reloading the page after the promise resolution,
     * so the queries can again be subscribed. For more info
     * about this, read here:
     * https://github.com/apollographql/apollo-client/issues/6359#issuecomment-656858871
     */
    client.clearStore().then(() =>
      // eslint-disable-next-line no-restricted-globals
      setTimeout(() => location && location.reload(), delay)
    )
  })

const hideAlertFailure = callBack =>
  client.mutate({ mutation: CLOSE_ALERT }).then(() => callBack())

const showAlertFailure = alert =>
  client.mutate({ mutation: ALERT_FAILURE, variables: { alert } })

const showAlertSuccess = alert =>
  client.mutate({ mutation: ALERT_SUCCESS, variables: { alert } })

export { logout, hideAlertFailure, showAlertFailure, showAlertSuccess }
