import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function BaseLink({ defaultTag, defaultTagProps, href, to, ...other }) {
  const linkProps = {}

  if (!to && !href) {
    const LinkTag = defaultTag
    return <LinkTag {...defaultTagProps} {...other} />
  }

  const LinkTag = to ? Link : 'a'

  if (to) {
    linkProps.to = to
  } else if (href) {
    linkProps.href = href
    linkProps.target = '_blank'
    linkProps.rel = 'noreferrer noopener'
  }

  return <LinkTag {...linkProps} {...other} />
}

BaseLink.propTypes = {
  defaultTag: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  defaultTagProps: PropTypes.object,
  href: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

BaseLink.defaultProps = {
  defaultTag: 'a',
  defaultTagProps: null,
  href: null,
  to: null
}

export default BaseLink
