import PropTypes from 'prop-types'
import Modal from 'react-modal'
import React from 'react'

import rgba from 'lib/rgba'
import { transitionFluid, breakpointMax } from 'styles/mixins'
import { createUseStyles } from 'react-jss'

const closeTransitionDuration = 300

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  overlay: {
    ...transitionFluid(),

    backgroundColor: rgba(colors.light, 0.75),
    backdropFilter: 'blur(25px)',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: zIndexes.modal
  },

  modal: {
    ...transitionFluid(),

    background: ({ noBg }) => colors[`${noBg ? 'greyPale' : 'light'}`],
    borderRadius: ({ variant }) => (variant === 'default' ? 40 : 12),
    bottom: 'auto',
    boxShadow: `0px 30px 80px ${rgba(colors.primary, 0.12)}`,
    left: '50%',
    maxWidth: '100%',
    overflow: ({ overflow }) => overflow,
    padding: 0,
    position: 'fixed',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: ({ width }) => width,

    [breakpointMax('xs')]: {
      width: () => 320
    }
  }
}))

const rootElement = document.getElementById('root')

function BaseModal({ children, width, variant, overflow, noBg, ...props }) {
  const classes = useStyles({ width, variant, overflow, noBg })

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, { variant })
  )

  return (
    <Modal
      appElement={rootElement}
      closeTimeoutMS={closeTransitionDuration}
      className={classes.modal}
      overlayClassName={classes.overlay}
      aria={{
        label: props.ariaLabel ? props.ariaLabel : 'modal'
      }}
      {...props}
    >
      {childrenWithProps}
    </Modal>
  )
}

BaseModal.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(['compact', 'default']),
  overflow: PropTypes.oneOf(['hidden', 'visible'])
}

BaseModal.defaultProps = {
  width: '60%',
  variant: 'default',
  overflow: 'hidden'
}

export default BaseModal
