import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import rgba from 'lib/rgba'
import { childrenOfType } from 'lib/customPropTypes'
import { createUseStyles } from 'react-jss'
import { transitionFluid } from 'styles/mixins'

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  menu: {
    ...transitionFluid(),

    background: colors.light,
    borderRadius: [12, 0, 12, 12],
    boxShadow: `0px 10px 30px ${rgba(colors.primary, 0.12)}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingTop: 15,
    position: 'absolute',
    right: 0,
    whiteSpace: 'noWrap',
    zIndex: zIndexes.popup
  },
  menu_appointment: {
    top: 145,
    paddingTop: 10,
    right: 76
  },
  open: {
    opacity: 1,
    visibility: 'visible'
  },
  close: {
    opacity: 0,
    visibility: 'hidden'
  }
}))

function Menu({ children, className, visible, variant }) {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        classes.menu,
        classes[`menu_${variant}`],
        className,
        { [classes.open]: visible },
        { [classes.close]: !visible }
      )}
    >
      {children}
    </div>
  )
}

Menu.propTypes = {
  className: PropTypes.string,
  children: childrenOfType('MenuItem').isRequired,
  visible: PropTypes.bool.isRequired
}

Menu.defaultProps = {
  className: null
}

export default Menu
