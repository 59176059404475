import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import AuthTextLink from 'components/links/AuthTextLink'
import Button from 'components/buttons/Button'
import Organization from 'models/Organization'
import validateSchema from 'lib/validators'
import {
  accountType,
  isExternalLogin,
  isInternalLogin,
  isExternalRedirectLogin
} from 'models/Account'
import { isSurgicalNotesProduct } from 'lib/CheckProduct'

const schema = yup.object().shape({
  login: yup.string().required(),
  password: yup.string().required()
})

function OrganizationLoginForm({ account, onFormSubmit }) {
  const { t } = useTranslation()
  const { type, organizationAuthProvider } = account || {}

  const isStaff = type === accountType.STAFF

  if (isExternalRedirectLogin(account)) {
    const url = new URL(account.externalLoginUrl)

    const params = url.searchParams
    params.append(
      'redirect_uri',
      `https://${import.meta.env.VITE_HOST}/oauth-redirect`
    )
    const externalLoginUrl = url.toString()
    const label = t('organizationLoginPage.form.externalLoginButton', {
      authProvider: organizationAuthProvider || 'OAuth'
    })

    return (
      <Button
        label={label}
        size="fill"
        // eslint-disable-next-line no-return-assign
        onClick={() => (window.location.href = externalLoginUrl)}
      />
    )
  }

  const initialValues = { login: Organization.getLogin(account) }

  return (
    <Form
      validate={validateSchema(schema)}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="login"
            placeholder={
              isExternalLogin(account) && !organizationAuthProvider
                ? t(
                  'organizationLoginPage.form.fields.login.placeholder.emrUsername'
                )
                : t('organizationLoginPage.form.fields.login.placeholder.email')
            }
            component={AuthTextInput}
            icon={isSurgicalNotesProduct() ? 'surgical_notes_email' : 'email'}
            showValidationMessage
          />
          <Field
            autoFocus
            name="password"
            placeholder={t(
              'organizationLoginPage.form.fields.password.placeholder'
            )}
            component={AuthTextInput}
            icon={isSurgicalNotesProduct() ? 'surgical_notes_lock' : 'lock'}
            type="password"
            showValidationMessage
          />
          <AuthFormFooter>
            <Button
              label={t('organizationLoginPage.form.buttons.login')}
              disabled={submitting}
            />
            {!organizationAuthProvider &&
              (isInternalLogin(account) || isStaff) && (
              <AuthTextLink
                to={{
                  pathname: '/forgot-password',
                  state: { account }
                }}
              >
                {t('organizationLoginPage.form.links.forgotPassword')}
              </AuthTextLink>
            )}
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

OrganizationLoginForm.propTypes = {
  account: PropTypes.object.isRequired,
  onFormSubmit: PropTypes.func.isRequired
}

export default OrganizationLoginForm
