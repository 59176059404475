import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ colors }) => ({
  tabs_default: {
    display: 'flex',
    listStyle: 'none',
    paddingLeft: 0
  },
  tabs_fullWidth: {
    extend: 'tabs_default',
    borderBottom: `2px solid ${colors.secondaryVivid}`,
    marginBottom: props => props.inline && 35
  },
  tabs_vertical: {
    extend: 'tabs_default',
    alignItems: 'start',
    flexDirection: 'column'
  },
  tabs_modal: {
    extend: 'tabs_default',
    border: `1px solid ${colors.greyLight}`,
    borderRadius: 10
  }
}))

function Tabs({ children, inline, variant }) {
  const classes = useStyles({ inline })

  const childrenWithProps = React.Children.map(
    children,
    child => child && React.cloneElement(child, { variant })
  )

  return (
    <ul className={classes[`tabs_${variant}`]} role="menubar">
      {childrenWithProps}
    </ul>
  )
}

Tabs.propTypes = {
  inline: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'fullWidth', 'vertical', 'modal'])
}

Tabs.defaultProps = {
  inline: false,
  variant: 'default'
}

export default Tabs
