import React from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import Hidden from 'components/base/Hidden'
import Stack from 'components/base/Stack'
import Text from 'components/typography/Text'

const useStyles = createUseStyles(({ colors }) => ({
  buttons: {
    borderTop: `1px solid ${colors.secondary}`,
    padding: [50, 0, 10, 0]
  }
}))

const CURRENT_YEAR = new Date().getFullYear()

function Footer({ navItems }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <footer>
      <Hidden xs>
        <Text size="xSmall" weight="medium" color="primaryLight">
          {t('footer.patentPending')}
        </Text>
        <Text size="xSmall" weight="medium" color="primaryLight">
          {t('footer.copyright', { year: CURRENT_YEAR })}
        </Text>
      </Hidden>
      <Hidden>
        <Stack className={classes.buttons} justify="space-around">
          {navItems}
        </Stack>
      </Hidden>
    </footer>
  )
}

export default Footer
