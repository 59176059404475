import React, { Fragment } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AuthTextLink from 'components/links/AuthTextLink'
import AuthTitle from 'components/internal/AuthTitle'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import dx from 'lib/dashx'
import Row from 'components/base/Row'
import SelectionCard from 'components/cards/SelectionCard'
import useClientMutation from 'hooks/useClientMutation'
import useClientQuery from 'hooks/useClientQuery'
import useQuery from 'hooks/useQuery'
import useURLParams from 'hooks/useURLParams'

import { GET_AUTH, LOGIN } from 'client/state/auth'
import { ACCOUNTS_INFO_QUERY } from 'models/Account'
import getDefaultTab from 'lib/getDefaultTab'

const useStyles = createUseStyles(({ units }) => ({
  footer: {
    marginTop: 12,
    position: 'absolute',
    right: units.cardRightPadding
  }
}))

function ChooseOrganizationPage() {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const urlParams = useURLParams()
  const referrer = urlParams.get('referrer')

  const { data: { auth: { accountTokens = [], emails = [] } = {} } = {} } =
    useClientQuery(GET_AUTH)
  const [login] = useClientMutation(LOGIN)

  const { data: { accountsInfo } = {} } = useQuery(ACCOUNTS_INFO_QUERY, {
    variables: {
      emails,
      input: {
        organizationStatuses: ['approved', 'pending']
      }
    },
    skip: emails.length === 0
  })

  if (emails.length === 0) {
    history.push({
      pathname: `/login`,
      ...(referrer ? { search: `?referrer=${referrer}` } : {})
    })
  }

  const getAccountToken = account =>
    accountTokens.find(accountToken => accountToken.accountId === account.id)

  const handleCardClick = (account, path) => {
    const existingToken = getAccountToken(account)
    const [pathname, searchString] = path.split('?')

    const defaultTab = getDefaultTab(
      account.defaultTab,
      searchString ? referrer : null
    )

    if (existingToken) {
      login({ accountId: account.id }).then(() => {
        history.push(defaultTab)
        dx.setIdentity(account.id, existingToken.dashxToken)
      })
    } else if (account.status === 'invited') {
      history.push({
        pathname: '/verification',
        state: { account }
      })
    } else {
      history.push({
        pathname,
        state: { account },
        ...(searchString ? { search: `?${searchString}` } : {})
      })
    }
  }

  const sortedAccounts =
    accountsInfo &&
    accountsInfo
      .map(info => info)
      .sort((a1, a2) => a1.organizationName > a2.organizationName)

  return (
    <Fragment>
      <AuthTitle
        title={t('chooseOrganizationPage.title')}
        subtitle={t('chooseOrganizationPage.subtitle')}
      />
      <Row>
        <Column span={3} hiddenXs />
        <Column span={6}>
          <Card variant="secondary">
            {sortedAccounts &&
              sortedAccounts.map(account => (
                <SelectionCard
                  account={account}
                  key={`${account.organizationName}-${account.email}`}
                  onClick={handleCardClick}
                  path={`/organization-login${
                    referrer ? `?referrer=${referrer}` : ''
                  }`}
                  accessoryIcon={
                    getAccountToken(account) ? 'success-small' : ''
                  }
                />
              ))}
            <div className={classes.footer}>
              <AuthTextLink to="/login" iconRight="angle-right">
                {t('chooseOrganizationPage.addOrganizationLink')}
              </AuthTextLink>
            </div>
          </Card>
        </Column>
      </Row>
    </Fragment>
  )
}

export default ChooseOrganizationPage
