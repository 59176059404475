import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import validateSchema from 'lib/validators'

const schema = yup.object().shape({
  password: yup.string().required()
})

function SetupPasswordForm({ onFormSubmit }) {
  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoFocus
            component={AuthTextInput}
            name="password"
            icon="lock"
            type="password"
            placeholder="Set Password"
            showValidationMessage
            showValidationUI
          />
          <AuthFormFooter>
            <Button label="Next" disabled={submitting} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

SetupPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default SetupPasswordForm
