import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'react-jss'
import App from 'components/base/App'
import ClientProvider from 'components/base/ClientProvider'
import { getProductTheme } from 'lib/CheckProduct'

function Root() {
  return (
    <ThemeProvider theme={getProductTheme()}>
      <ClientProvider>
        {({ apolloClient }) => (
          <ApolloProvider client={apolloClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ApolloProvider>
        )}
      </ClientProvider>
    </ThemeProvider>
  )
}

export default Root
