import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import Column from 'components/base/Column'
import Row from 'components/base/Row'
import validateSchema from 'lib/validators'

const schema = yup.object().shape({
  providerEmail1: yup.string().email('Please enter a valid email'),
  providerEmail2: yup.string().email('Please enter a valid email'),
  providerEmail3: yup.string().email('Please enter a valid email')
})

function InviteProvidersForm({ requiresUsername, onFormSubmit, onSkip, role }) {
  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Column span={requiresUsername ? 6 : 12}>
              {role !== 'provider' && (
                <Field
                  autoFocus
                  component={AuthTextInput}
                  icon="email"
                  name="providerEmail1"
                  placeholder="Email"
                  data-testid="provider-email1"
                  showValidationMessage
                />
              )}
              <Field
                component={AuthTextInput}
                icon="email"
                name="providerEmail2"
                placeholder="Email"
                data-testid="provider-email2"
                showValidationMessage
              />
              <Field
                component={AuthTextInput}
                icon="email"
                name="providerEmail3"
                placeholder="Email"
                data-testid="provider-email3"
                showValidationMessage
              />
            </Column>
            {requiresUsername && (
              <Column span={6}>
                {role !== 'provider' && (
                  <Field
                    component={AuthTextInput}
                    name="providerUsername1"
                    data-testid="provider-username-1"
                    placeholder="Provider Username 1"
                    showValidationMessage
                  />
                )}
                <Field
                  component={AuthTextInput}
                  name="providerUsername2"
                  data-testid="provider-username-2"
                  placeholder="Provider Username 2"
                  showValidationMessage
                />
                <Field
                  component={AuthTextInput}
                  name="providerUsername3"
                  data-testid="provider-username-3"
                  placeholder="Provider Username 3"
                  showValidationMessage
                />
              </Column>
            )}
          </Row>
          <AuthFormFooter>
            <Button
              type="submit"
              label="Next"
              disabled={pristine || submitting}
            />
            <Button type="button" label="Skip" onClick={onSkip} />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

InviteProvidersForm.propTypes = {
  requiresUsername: PropTypes.bool.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
}

export default InviteProvidersForm
