import { showAlertFailure } from 'client/methods'

function handleNuanceXHRErrors() {
  const originalXhrSend = XMLHttpRequest.prototype.send

  XMLHttpRequest.prototype.send = function (...args) {
    this.addEventListener('readystatechange', () => {
      if (this.readyState === 4) {
        if (
          (this.responseType === 'text' || this.responseType === '') &&
          this.responseText.includes('USER_NOT_LICENSED')
        ) {
          showAlertFailure({
            message: 'Invalid user license. Please contact the account manager.'
          })
        }
      }
    })
    originalXhrSend.apply(this, args)
  }
}

handleNuanceXHRErrors()
