import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import BaseLink from 'components/links/BaseLink'
import FontIcon from 'components/base/FontIcon'
import { breakpointMax, transitionSimple } from 'styles/mixins'

const useStyles = createUseStyles(({ colors, typography }) => ({
  textLink: {
    ...transitionSimple(),

    color: colors.tertiary,
    cursor: 'pointer',
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: typography.fontSizeVariants.xSmall,
    fontWeight: typography.fontWeightVariants.semibold,
    letterSpacing: props => props.hasIcon && 1.2,
    textTransform: props => props.hasIcon && 'uppercase',

    '& > .icon': {
      marginLeft: 10
    },

    '&:hover': {
      color: colors.light
    },

    '&:hover > .icon': {
      color: colors.light
    },

    [breakpointMax('xs')]: {
      marginTop: 15,
      textAlign: 'center'
    }
  },
  icon: {
    ...transitionSimple()
  }
}))

function AuthTextLink({ children, iconRight, ...other }) {
  const hasIcon = !!iconRight
  const classes = useStyles({ hasIcon })

  return (
    <BaseLink defaultTag="a" className={classes.textLink} {...other}>
      {children}
      {iconRight && (
        <FontIcon
          className={classes.icon}
          name={iconRight}
          size="small"
          color="tertiary"
        />
      )}
    </BaseLink>
  )
}

AuthTextLink.propTypes = {
  iconRight: PropTypes.string
}

AuthTextLink.defaultProps = {
  iconRight: null
}

export default AuthTextLink
