import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import BaseLink from 'components/links/BaseLink'
import { getProductTheme } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ colors, typography }) => ({
  textLink: {
    color: props => colors[props.color],
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: props => typography.fontSizeVariants[props.size],
    fontWeight: props => typography.fontWeightVariants[props.weight],
    letterSpacing: props =>
      typography.fontLetterSpacingVariants[props.letterSpacing],

    '&:hover': {
      borderBottomColor: props =>
        props.hoverable ? colors.secondaryDark : 'transparent',
      borderBottomStyle: 'solid',
      borderBottomWidth: props => (props.hoverable ? 1 : 0)
    }
  }
}))

function TextLink({
  className,
  color,
  hoverable,
  label,
  letterSpacing,
  size,
  type,
  weight,
  ...props
}) {
  const classes = useStyles({
    color,
    hoverable,
    letterSpacing,
    size,
    weight
  })

  return (
    <BaseLink
      defaultTag="a"
      className={classNames(classes.textLink, className)}
      defaultTagProps={{ type }}
      {...props}
    >
      {label}
    </BaseLink>
  )
}

TextLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(getProductTheme().colors)),
  hoverable: PropTypes.bool,
  label: PropTypes.string.isRequired,
  letterSpacing: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.fontLetterSpacingVariants)
  ),
  size: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.fontSizeVariants)
  ),
  type: PropTypes.string,
  weight: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.fontWeightVariants)
  )
}

TextLink.defaultProps = {
  className: null,
  color: 'grey',
  hoverable: false,
  letterSpacing: 'cozy',
  size: 'small',
  type: 'submit',
  weight: 'medium'
}

export default TextLink
