import { useQuery as useApolloQuery } from '@apollo/client'
import componentQuery from 'lib/componentQuery'

function useQuery(query, options = {}) {
  const { variables, update = false } = options

  const newOptions = { fetchPolicy: 'cache-and-network', ...options }

  if (update) {
    componentQuery.update({ query, variables })
  }

  return useApolloQuery(query, newOptions)
}

export default useQuery
