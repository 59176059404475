import { onError } from '@apollo/client/link/error'

import cache from 'client/cache'
import parseError from 'lib/parseError'
import { logout, showAlertFailure } from 'client/methods'
import { GET_AUTH } from 'client/state/auth'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const {
    auth: { currentToken }
  } = cache.readQuery({ query: GET_AUTH })

  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => showAlertFailure({ message }))
  }

  if (networkError) {
    const { message } = parseError({ networkError })

    if (networkError.statusCode === 401 && currentToken) {
      logout().finally(() => {
        showAlertFailure({ message })
      })
    } else {
      showAlertFailure({ message })
    }
  }
})

export default errorLink
