import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { createUseStyles } from 'react-jss'

import classNames from 'classnames'
import rgba from 'lib/rgba'
import { breakpointMax } from 'styles/mixins'
import Text from 'components/typography/Text'

const useStyles = createUseStyles(({ colors, units }) => ({
  card: {
    backgroundColor: props => colors[props.variant],
    position: 'relative'
  },
  card_bold: {
    borderRadius: 40,
    boxShadow: `0px 10px 30px ${rgba(colors.primary, 0.51)}`,
    padding: [50, units.cardRightPadding, 60, units.cardLeftPadding],

    [breakpointMax('md')]: {
      margin: [35, 0, 65, 0]
    },

    [breakpointMax('xs')]: {
      padding: [50, 30, 55, 40]
    }
  },
  card_minimal: {
    borderRadius: 12,
    boxShadow: `0px 2px 3px ${rgba(colors.primary, 0.04)}`,
    padding: [20, 50]
  },
  cardHeader: {
    marginBottom: 16
  }
}))

function Card({ children, ...props }) {
  const classes = useStyles(props)
  const { minimal, title } = props

  return (
    <Fragment>
      {title && (
        <div className={classes.cardHeader}>
          <Text color="dark" size="large" weight="semibold">
            {title}
          </Text>
        </div>
      )}
      <div
        className={classNames(
          classes.card,
          {
            [classes.card_minimal]: minimal,
            [classes.card_bold]: !minimal
          },
          props.className
        )}
      >
        {children}
      </div>
    </Fragment>
  )
}

Card.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'light']),
  minimal: PropTypes.bool,
  title: PropTypes.string
}

Card.defaultProps = {
  variant: 'primary',
  minimal: false,
  title: ''
}

export default Card
