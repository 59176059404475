import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  stack: {
    alignItems: ({ align }) => align,
    display: 'flex',
    flexDirection: ({ direction }) => direction,
    justifyContent: ({ justify }) => justify,
    height: ({ verticalStretch }) => verticalStretch && '100%',
    flexWrap: ({ wrap }) => wrap
  },
  stack_column: {
    '& > *': {
      marginBottom: ({ gutter }) => gutter,
      width: props => props.stretch && '100%'
    },

    '& > *:last-child': {
      marginBottom: 0
    }
  },
  stack_column_reverse: {
    '& > *': {
      marginTop: ({ gutter }) => gutter,
      width: props => props.stretch && '100%'
    },

    '& > *:last-child': {
      marginTop: 0
    }
  },
  stack_row: {
    '& > *': {
      marginRight: ({ gutter }) => gutter,
      width: props => props.stretch && '100%'
    },

    '& > *:last-child': {
      marginRight: 0
    }
  },
  stack_row_reverse: {
    '& > *': {
      marginLeft: ({ gutter }) => gutter,
      width: props => props.stretch && '100%'
    },

    '& > *:last-child': {
      marginLeft: 0
    }
  }
})

function Stack({
  align, children, className, direction, gutter, justify, stretch, verticalStretch, wrap, ...rest
}) {
  const classes = useStyles({ align, direction, gutter, justify, stretch, verticalStretch, wrap })

  return (
    <div
      className={classNames(
        classes.stack,
        {
          [classes.stack_row]: direction === 'row',
          [classes.stack_column]: direction === 'column',
          [classes.stack_row_reverse]: direction === 'row-reverse',
          [classes.stack_column_reverse]: direction === 'column-reverse'
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

Stack.propTypes = {
  align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch']),
  className: PropTypes.string,
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  gutter: PropTypes.number,
  justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
  stretch: PropTypes.bool,
  verticalStretch: PropTypes.bool,
  wrap: PropTypes.oneOf(['wrap', 'nowrap', 'wrap-reverse'])
}

Stack.defaultProps = {
  align: 'center',
  className: null,
  direction: 'row',
  gutter: 10,
  justify: 'flex-start',
  stretch: false,
  verticalStretch: false,
  wrap: 'nowrap'
}

export default Stack
