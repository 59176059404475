function columnWidth(span) {
  const gridColumns = 12
  const columnGap = 20

  const totalWidth = '100%'
  const totalColumnsGap = columnGap * (gridColumns - 1)

  const gridColumnWidth = `(${totalWidth} - ${totalColumnsGap}px) / ${gridColumns}`

  const spannedWidth = `${gridColumnWidth} * ${span}`
  const spannedColumnsGap = `${columnGap}px * ${span - 1}`

  return `calc(${spannedWidth} + ${spannedColumnsGap})`
}

export default columnWidth
