import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import rgba from 'lib/rgba'

const useStyles = createUseStyles(({ colors, typography }) => ({
  badge: {
    background: colors.tertiary,
    borderRadius: '50%',
    boxShadow: `0px 4px 4px ${rgba(colors.tertiary, 0.4)}`,
    color: colors.primary,
    fontSize: typography.fontSizeVariants.xxxSmall,
    fontWeight: typography.fontWeightVariants.bold,
    height: 12,
    padding: [ 2, 6 ],
    textAlign: 'center',
    width: 12
  }
}))

function Badge({ children, className }) {
  const classes = useStyles()

  return (
    <span className={classNames(classes.badge, className)}>
      {children}
    </span>
  )
}

Badge.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}

Badge.defaultProps = {
  children: null,
  className: null
}

export default Badge
