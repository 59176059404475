import { gql } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import Loader from 'components/loaders/Loader'
import useMutation from 'hooks/useMutation'
import useURLParams from 'hooks/useURLParams'

export const VERIFY_SAML_AUTHENTICATION_TOKEN_MUTATION = gql`
  mutation VerifySamlAuthenticationTokenMutation(
    $input: VerifySamlAuthenticationTokenInput!
  ) {
    verifySamlSession(input: $input) {
      id
      accessToken
      dashxToken
      expiresAt
      status
      account {
        id
        email
      }
    }
  }
`

function VerifySessionPage({ handleSetTokens }) {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const urlParams = useURLParams()

  const [verifySamlSession] = useMutation(
    VERIFY_SAML_AUTHENTICATION_TOKEN_MUTATION,
    {
      onCompleted: response => {
        const createdSession = response.verifySamlSession
        handleSetTokens(createdSession)
      }
    }
  )

  useEffect(() => {
    const accountId = urlParams.get('account_id')
    const state = urlParams.get('state')

    if (accountId && state) {
      setIsLoading(true)
      const loginParameters = { accountId, state }
      verifySamlSession(loginParameters).finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  if (isLoading) return <Loader />

  return null
}

export default VerifySessionPage
