import useURLParams from 'hooks/useURLParams'
import getDefaultTab from 'lib/getDefaultTab'
import useSetting, { settingTypes } from './useSetting'

const useDefaultTab = () => {
  const defaultTab = useSetting(settingTypes.DEFAULT_TAB)
  const urlParams = useURLParams()
  const referrer = urlParams.get('referrer')

  return getDefaultTab(defaultTab, referrer)
}

export default useDefaultTab
