import React, { Fragment } from 'react'
import { createUseStyles } from 'react-jss'

import AlertBox from 'components/alerts/AlertBox'
import Footer from 'components/auth/Footer'
import Hidden from 'components/base/Hidden'
import MainContainer from 'components/auth/MainContainer'
import Navbar from 'components/auth/Navbar'
import RectangleArt from 'components/art/RectangleArt'

import { breakpointMax } from 'styles/mixins'
import { isSurgicalNotesProduct } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  background: {
    position: 'fixed',
    backgroundImage: isSurgicalNotesProduct()
      ? `radial-gradient(96.94% 314.1% at 17.22% 29%, ${colors.surgicalNotesBlue} 0%, ${colors.secondary} 100%)`
      : `linear-gradient(to bottom right, 
      ${colors.primary}, ${colors.primaryVivid}, ${colors.secondary})`,
    height: '100vh',
    width: '100vw',
    zIndex: zIndexes.background
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: [50, 70],

    [breakpointMax('xs')]: {
      padding: [50, 25, 30, 25]
    }
  }
}))

function AuthLayout({ children, navbarItems, fullWidthContent }) {
  const classes = useStyles()

  const fixedElements = (
    <Fragment>
      <AlertBox />
      <div className={classes.background} />
      <Hidden xs>
        <RectangleArt top={8.5} left={34} topLeftRadius={80} />
        <RectangleArt
          top={35.5}
          left={22.5}
          opacity={0.2}
          topLeftRadius={80}
          topRightRadius={80}
        />
        <RectangleArt top={58.25} right={29} topRightRadius={80} />
        <RectangleArt top={69} right={34} opacity={0.25} topRightRadius={80} />
      </Hidden>
    </Fragment>
  )

  return (
    <Fragment>
      {fixedElements}
      <div className={classes.layout}>
        <Navbar>{navbarItems}</Navbar>
        <MainContainer fullWidthContent={fullWidthContent}>
          {children}
        </MainContainer>
        <Footer navItems={navbarItems} />
      </div>
    </Fragment>
  )
}

export default AuthLayout
