import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import Text from 'components/typography/Text'
import { getProductTheme } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ colors }) => ({
  header: {
    background: ({ background }) => colors[background],
    justifyContent: 'center',
    display: 'flex',
    padding: ({ variant }) => (variant === 'default' ? 16 : 10),
    borderRadius: '40px 40px 0 0'
  }
}))

function ModalHeader({ children, background, variant }) {
  const classes = useStyles({ background, variant })

  const isPrimary = background === 'primary'

  return (
    <header className={classes.header}>
      <Text
        color={isPrimary ? 'tertiary' : 'light'}
        size="medium"
        weight="medium"
      >
        {children}
      </Text>
    </header>
  )
}

ModalHeader.propTypes = {
  background: PropTypes.oneOf(Object.keys(getProductTheme().colors)),
  variant: PropTypes.oneOf(['compact', 'default'])
}

ModalHeader.defaultProps = {
  background: 'primary',
  variant: 'default'
}

export default ModalHeader
