import { gql } from '@apollo/client'
import React, { useState, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'

import alerts from 'constants/alerts'
import Card from 'components/cards/Card'
import Column from 'components/base/Column'
import PageTitle from 'components/typography/PageTitle'
import Row from 'components/base/Row'
import SignupForm from 'components/forms/SignupForm'
import Text from 'components/typography/Text'
import useClientMutation from 'hooks/useClientMutation'
import useClientQuery from 'hooks/useClientQuery'
import useLazyQuery from 'hooks/useLazyQuery'
import useMutation from 'hooks/useMutation'
import usePersistedState from 'hooks/usePersistedState'
import { breakpointMax } from 'styles/mixins'
import { getCurrentProduct } from 'lib/CheckProduct'
import { showAlertFailure } from 'client/methods'

import { ACCOUNTS_INFO_QUERY, DB_USER_REGION_KEY } from 'models/Account'
import { ADD_EMAIL, GET_AUTH } from 'client/state/auth'

const INITIATE_SIGNUP_MUTATION = gql`
  mutation initiateSignupMutation($input: InitiateSignupInput!) {
    initiateSignup(input: $input) {
      email
      token
    }
  }
`

const useStyles = createUseStyles({
  text: {
    marginBottom: 15,

    [breakpointMax('md')]: {
      textAlign: 'center'
    }
  },
  pageTitle: {
    marginBottom: 40
  }
})

function SignupPage() {
  const classes = useStyles()
  const history = useHistory()

  const { data: { auth: { emails = '' } = {} } = {} } = useClientQuery(GET_AUTH)
  const [addEmail] = useClientMutation(ADD_EMAIL)

  const [email, setEmail] = useState(null)
  const [userRegion, setUserRegion] = usePersistedState(
    DB_USER_REGION_KEY,
    'United States'
  )

  const [getAccountsInfo, { data: { accountsInfo } = {} }] = useLazyQuery(
    ACCOUNTS_INFO_QUERY,
    {
      variables: { emails: [email] },
      onCompleted: response => {
        if (response.accountsInfo.length === 0) {
          showAlertFailure({ message: alerts.no_account })
        } else {
          addEmail({ email })
        }
      }
    }
  )

  useEffect(() => {
    if (accountsInfo && emails.includes(email)) {
      if (accountsInfo.length === 1) {
        history.push({
          pathname: '/organization-login',
          state: { account: accountsInfo[0] }
        })
      } else {
        history.push({
          pathname: '/choose-organization'
        })
      }
    }
  }, [accountsInfo, emails, email, history])

  const [initiateSignup] = useMutation(INITIATE_SIGNUP_MUTATION, {
    onCompleted: response => {
      history.push(`/setup/${response.initiateSignup.token}/account`)
    }
  })

  const handleLookupInvite = values => {
    setEmail(values.email)
    getAccountsInfo()
  }

  const product = useMemo(() => getCurrentProduct(), [])

  return (
    <Row wrap>
      <Column span={6} spanMd={12}>
        <Card variant="secondary">
          <Text
            size="small"
            weight="semibold"
            color="tertiary"
            className={classes.text}
          >
            Signup New Organization
          </Text>
          <PageTitle className={classes.pageTitle}>
            Start using {product} by signing up for a{' '}
            <strong>free 15-day trial.</strong>
          </PageTitle>
          <SignupForm
            submitLabel="Next"
            onFormSubmit={({ region, ...values }) => {
              const params = { ...values, region: undefined }
              setUserRegion(region)

              return initiateSignup(params)
            }}
            userRegion={userRegion}
          />
        </Card>
      </Column>
      <Column span={6} spanMd={12}>
        <Card>
          <Text
            size="small"
            weight="semibold"
            color="tertiary"
            className={classes.text}
          >
            Join Existing Organization
          </Text>
          <PageTitle className={classes.pageTitle}>
            Enter your email address to check if you&apos;ve{' '}
            <strong>received an invite.</strong>
          </PageTitle>
          <SignupForm
            submitLabel="Lookup invite"
            onFormSubmit={handleLookupInvite}
          />
        </Card>
      </Column>
    </Row>
  )
}

export default SignupPage
