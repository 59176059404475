import React from 'react'
import { createUseStyles } from 'react-jss'

import rgba from 'lib/rgba'
import SelectInput from 'components/inputs/SelectInput'

const useStyles = createUseStyles(({ colors, typography }) => ({
  authInput: {
    '&:hover, &:focus': {
      boxShadow: `0px 20px 40px ${rgba(colors.primary, 0.46)}`
    },

    '& div': {
      fontSize: typography.fontSizeVariants.small,
      fontWeight: typography.fontWeightVariants.semibold,
      lineHeight: 1.5
    }
  }
}))

function AuthSelectInput(props) {
  const classes = useStyles(props)

  return (
    <SelectInput variant="large" className={classes.authInput} {...props} />
  )
}

export default AuthSelectInput
