const defaultTabs = {
  default: '/schedule',
  admin: '/admin',
  on_call_records: '/on-call/records',
  on_call_calendar: '/on-call/schedule',
  schedule: '/schedule',
  schedule_surgeries: '/schedule/surgeries',
  voice_commands: '/voice-commands',
  notes: '/notes',
  portal_dictation: '/notes'
}

const getDefaultTab = (setting, referrer = null) => {
  if (referrer) return referrer
  if (!setting) return defaultTabs.default

  if (Object.keys(defaultTabs).includes(setting)) {
    return defaultTabs[setting]
  }

  return defaultTabs.default
}

export default getDefaultTab
