import PropTypes from 'prop-types'
import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'

import { isSurgicalNotesProduct } from 'lib/CheckProduct'
import validateSchema from 'lib/validators'

const createSchema = t =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('forgotPasswordPage.form.fields.email.validationMessage'))
      .required()
  })

function ForgotPasswordForm({ onFormSubmit }) {
  const { t } = useTranslation()
  const schema = createSchema(t)

  return (
    <Form
      validate={validateSchema(schema)}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit}>
          <Field
            autoFocus
            component={AuthTextInput}
            name="email"
            icon={isSurgicalNotesProduct() ? 'surgical_notes_email' : 'email'}
            placeholder={t('forgotPasswordPage.form.fields.email.placeholder')}
            showValidationUI
            showValidationMessage
          />
          <AuthFormFooter>
            <Button
              label={t('forgotPasswordPage.form.buttons.sendInstructions')}
              disabled={pristine || submitting}
            />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

ForgotPasswordForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired
}

export default ForgotPasswordForm
