import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import AppContext from 'contexts/AppContext'
import { getInitialProviderOption } from 'models/Provider'
import { useInitialPractices } from 'models/Practice'

export const filterKeys = Object.freeze({
  APPOINTMENTS_START_DATE: 'appointments_start_date',
  APPOINTMENT_TYPE: 'appointment_type',
  CLIENT_NAME: 'client_name',
  ENCOUNTERS_START_DATE: 'encounters_start_date',
  LOCATION: 'location',
  MANAGEMENT_STATUS: 'management_status',
  ON_CALL_FACILITY: 'on_call_facility',
  ON_CALL_FACILITIES: 'on_call_facilities',
  ON_CALL_SCHEDULE_FACILITY: 'on_call_schedule_facility',
  ON_CALL_SCHEDULE_FACILITIES: 'on_call_schedule_facilities',
  ON_CALL_SCHEDULE_PROVIDERS: 'on_call_schedule_providers',
  ON_CALL_SCHEDULE_TYPES: 'on_call_schedule_types',
  ON_CALL_FROM_DATE: 'on_call_from_date',
  ON_CALL_PATIENT_SEARCH: 'on_call_patient_search',
  ON_CALL_RECORD_STATUS: 'on_call_record_status',
  ON_CALL_TO_DATE: 'on_call_to_date',
  ON_CALL_TYPES: 'on_call_types',
  OP_REPORT_STATUS: 'op_report_status',
  ORGANIZATION: 'organization',
  PATIENT: 'patient',
  PATIENT_APPOINTMENT: 'patient_appointment',
  PATIENTS_SEARCH: 'patients_search',
  PHONE_NUMBER: 'phone_number',
  PRACTICE: 'practice',
  PRACTICES: 'practices',
  PROVIDER: 'provider',
  PROVIDER_APPOINTMENT: 'provider_appointment',
  PROVIDER_VOICE_COMMANDS: 'provider_voice_commands',
  PROVIDERS: 'providers',
  ROOM: 'room',
  ROOM_APPOINTMENT: 'ROOM_APPOINTMENT',
  SCHEDULE_FROM_DATE: 'schedule_from_date',
  SCHEDULE_TO_DATE: 'schedule_to_date',
  SCHEDULE_LOCATION: 'schedule_location',
  STATUS_CATEGORY: 'status_category',
  SURGERY_LOCATION: 'surgery_location',
  VOICE_COMMANDS_KEYWORDS: 'voice_commands_keywords',
  VOICE_COMMANDS_SEARCH: 'voice_commands_search',
  RANGE_DATE_SELECTOR: 'range_date_selector',
  ADMIN_USER_SEARCH: 'admin_user_search',
  WORKFLOW_STATUS_SEARCH: 'workflow_status_search',
  NOTES_TITLE: 'notes_title',
  NOTES_START_DATE: 'notes_start_date',
  NOTES_FROM_DATE: 'notes_from_date',
  NOTES_TO_DATE: 'notes_to_date',
  WORKFLOW_ITEMS: 'workflow_items',
  REPORT_PATIENT: 'report_patient',
  REPORT_PROVIDERS: 'report_providers',
  REPORT_LOCATION: 'report_location',
  REPORT_STATUSES: 'report_statuses',
  NOTES_STATUSES: 'notes_statuses'
})

const FilterContext = createContext()

export function FilterProvider({ children }) {
  const { currentAccount } = useContext(AppContext)
  const [isFirstRun, setIsFirstRun] = useState(true)
  const [loading, initialPracticeOptions] = useInitialPractices(currentAccount)

  const initialFilters = useMemo(
    () => ({
      [filterKeys.PROVIDER]: getInitialProviderOption(currentAccount, false),
      [filterKeys.PROVIDERS]: getInitialProviderOption(currentAccount, true),
      [filterKeys.PRACTICES]: initialPracticeOptions,
      [filterKeys.APPOINTMENTS_START_DATE]: new Date().toISOString()
    }),
    [currentAccount, initialPracticeOptions]
  )

  const [filters, setFilters] = useState(initialFilters)

  const methods = useMemo(
    () => ({
      setFilterValue(key, value) {
        setFilters(prevFilters => ({
          ...prevFilters,
          [key]: value
        }))
      },

      resetFilters() {
        setFilters(initialFilters)
      },

      resetFilter(key) {
        setFilters(prevFilters => ({
          ...prevFilters,
          [key]: undefined
        }))
      }
    }),
    [initialFilters]
  )

  const contextValue = useMemo(() => [filters, methods], [filters, methods])

  useEffect(() => {
    if (!isFirstRun && currentAccount) {
      methods.resetFilters()
    }
    setIsFirstRun(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, loading])

  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => useContext(FilterContext)

export const useFilterState = (key, initialValue) => {
  const [filters, { setFilterValue }] = useFilterContext()

  useEffect(() => {
    if (initialValue && filters[key] === undefined) {
      setFilterValue(key, initialValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [filters[key], value => setFilterValue(key, value)]
}

export default FilterContext
