import { isValidPhoneNumber } from 'libphonenumber-js/max'

function validatePhone(value) {
  return (
    value === undefined ||
    value === null ||
    value.length === 0 ||
    isValidPhoneNumber(value)
  )
}

export default validatePhone
