import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import EulaModal from 'components/modals/EulaModal'
import useDefaultTab from 'hooks/useDefaultTab'
import useMutation from 'hooks/useMutation'

import { logout } from 'client/methods'

export const ACCEPT_EULA_MUTATION = gql`
  mutation acceptEulaMutation {
    acceptEula {
      id
      eulaSignedAt
    }
  }
`

function EulaPage() {
  const defaultTab = useDefaultTab()
  const history = useHistory()
  const [showModal, setShowModal] = useState(true)

  const [acceptEula] = useMutation(ACCEPT_EULA_MUTATION, {
    onCompleted: () => history.push(defaultTab)
  })

  const handleSubmit = () => acceptEula()

  const handleDecline = () => {
    logout()
    setShowModal(false)
  }

  return (
    <EulaModal
      isOpen={showModal}
      onCancel={handleDecline}
      onSubmit={handleSubmit}
    />
  )
}

export default EulaPage
