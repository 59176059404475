import React, { useState, useRef, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import ConfirmationModal from 'components/modals/ConfirmationModal'

import { logout } from 'client/methods'

const ONE_MINUTE = 60000 // milliseconds

function IdleTimer({ timeout }) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [timeVal, setTimeVal] = useState(null)
  const idleTimerRef = useRef(null)

  const onPrompt = () => {
    setShowConfirmationModal(true)
  }

  const { reset, getRemainingTime, message } = useIdleTimer({
    crossTab: true,
    emitOnAllTabs: true,
    syncTimers: 1000,
    ref: idleTimerRef,
    onPrompt,
    // eslint-disable-next-line no-use-before-define
    onIdle,
    timeout: timeout - ONE_MINUTE,
    promptTimeout: ONE_MINUTE,
    // eslint-disable-next-line no-use-before-define
    onMessage
  })

  function onIdle() {
    setShowConfirmationModal(false)
    logout()
    message({ action: 'LOGOUT' }, true)
  }

  function onMessage(data) {
    switch (data.action) {
    case 'LOGOUT':
      logout()
      break
    case 'REMAIN_LOGGED_IN':
      reset()
      setShowConfirmationModal(false)
      break
    default:
    }
  }

  const handleLogout = () => {
    setShowConfirmationModal(false)
    logout()
    message({ action: 'LOGOUT' }, true)
  }

  const stayLoggedIn = () => {
    reset()
    setShowConfirmationModal(false)
    message({ action: 'REMAIN_LOGGED_IN' }, true)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeVal(getRemainingTime())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime])

  return (
    <ConfirmationModal
      content={`Your session will expire in ${Math.round(
        timeVal / 1000
      )} seconds. Would you like to remain logged in?`}
      isOpen={showConfirmationModal}
      onCancel={handleLogout}
      onSubmit={stayLoggedIn}
    />
  )
}

export default IdleTimer
