export const firstPageActivityLog = {
  message: '',
  result: {
    total: 200,
    currentPage: 1,
    records: [
      {
        id: '12345',
        date: '2022-05-25T12:00:00.000Z',
        user: {
          id: '1',
          name: 'Jim Holt'
        },
        comments: 'Recommend patient to Steadman Clinic.',
        patient: {
          id: '3',
          firstName: 'Cal',
          lastName: 'Halverson',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'cal@me.com',
          phoneNumbers: [
            {
              number: '2297498713',
              type: 'mobile'
            },
            {
              number: '2293225188',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-1',
              line1: '751 Whitemarsh Street',
              line2: '',
              city: 'Denver',
              state: 'CO',
              zip: '80001',
              country: 'US',
              type: 'physical'
            },
            {
              id: 'CI-24313554-0',
              line1: 'P.O. Box 445',
              line2: '',
              city: 'Denver',
              state: 'CO',
              zip: '80002',
              country: 'US',
              type: 'mailing'
            }
          ],
          organization: {
            name: 'VVSC',
            id: '2'
          },
          mrn: '22343343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '3456',
        date: '2022-05-25T15:00:00.000Z',
        user: {
          id: '1',
          name: 'Jim Holt'
        },
        comments: 'Cancel patient request.',
        patient: {
          id: '1',
          firstName: 'Thomas',
          lastName: 'Bye',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'thomasbye@me.com',
          phoneNumbers: [
            {
              number: '7192226547',
              type: 'mobile'
            },
            {
              number: '7198073336',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-0',
              line1: '47 Birch Hill Court',
              line2: '',
              city: 'Denver',
              state: 'CO',
              zip: '80002',
              country: 'US',
              type: 'physical'
            }
          ],
          organization: {
            name: 'VVSC',
            id: '2'
          },
          mrn: '22343678',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '7859',
        date: '2022-05-25T17:30:00.000Z',
        user: {
          id: '1',
          name: 'Jim Holt'
        },
        comments: 'View patient details.',
        patient: {
          id: '2',
          firstName: 'Sarah',
          lastName: 'Bye',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'sarahbye@me.com',
          phoneNumbers: [
            {
              number: '8184435677',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-0',
              line1: '47 Birch Hill Court',
              line2: '',
              city: 'Denver',
              state: 'CO',
              zip: '80002',
              country: 'US',
              type: 'physical'
            }
          ],
          organization: {
            name: 'Vail Health',
            id: '2'
          },
          mrn: '22778343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '8888',
        date: '2022-05-26T12:00:00.000Z',
        user: {
          id: '3',
          name: 'Sally Lopez'
        },
        comments: 'View patient details.',
        patient: {
          id: '4',
          firstName: 'Jessica',
          lastName: 'Graham',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'jgraham@me.com',
          phoneNumbers: [
            {
              number: '2196061052',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-2',
              line1: '16 South Wrangler Road',
              line2: '',
              city: 'Smallville',
              state: 'CO',
              zip: '80013',
              country: 'US',
              type: 'physical'
            },
            {
              id: 'CI-24313554-1',
              line1: '436 Wood Street',
              line2: '',
              city: 'Salt Lake City',
              state: 'UT',
              zip: '84001',
              country: 'US',
              type: 'mailing'
            }
          ],
          organization: {
            name: 'Vail Health',
            id: '2'
          },
          mrn: '11243343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '3783',
        date: '2022-05-26T15:00:00.000Z',
        user: {
          id: '2',
          name: 'Cynthia Rhodes'
        },
        comments: 'Recommend patient to Steadman Clinic.',
        patient: {
          id: '5',
          firstName: 'Thomas',
          lastName: 'Perkins',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'tperk95@gmail.com',
          phoneNumbers: [
            {
              number: '1234567890',
              type: 'mobile'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-5',
              line1: '751 Whitemarsh Street',
              line2: '',
              city: 'Denver',
              state: 'CO',
              zip: '80002',
              country: 'US',
              type: 'physical'
            },
            {
              id: 'CI-24313553-6',
              line1: 'P.O. Box 178',
              line2: '',
              city: 'Dallas',
              state: 'TX',
              zip: '78753',
              country: 'US',
              type: 'mailing'
            }
          ],
          organization: {
            name: 'VVSC',
            id: '2'
          },
          mrn: '08943343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '7654',
        date: '2022-05-26T17:30:00.000Z',
        user: {
          id: '3',
          name: 'Sally Lopez'
        },
        comments: 'Import patient.',
        patient: {
          id: '6',
          firstName: 'Walt',
          lastName: 'Lengel',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'lengelwalter@me.com',
          phoneNumbers: [
            {
              number: '3183910307',
              type: 'mobile'
            },
            {
              number: '5056140619',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-3',
              line1: '18 High Ridge Ave',
              line2: '',
              city: 'Austin',
              state: 'TX',
              zip: '78745',
              country: 'US',
              type: 'physical'
            }
          ],
          organization: {
            name: 'Vail Health',
            id: '2'
          },
          mrn: '22445343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '2233',
        date: '2022-05-27T12:00:00.000Z',
        user: {
          id: '1',
          name: 'Jim Holt'
        },
        comments: 'Ignore patient recommendation.',
        patient: {
          id: '7',
          firstName: 'Nick',
          lastName: 'Moore',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'nmmoore@me.com',
          phoneNumbers: [
            {
              number: '5056442847',
              type: 'mobile'
            },
            {
              number: '5054651302',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-4',
              line1: '46 Peachtree Dr',
              line2: '',
              city: 'Phoenix',
              state: 'AZ',
              zip: '85001',
              country: 'US',
              type: 'physical'
            }
          ],
          organization: {
            name: 'Steadman Clinic',
            id: '2'
          },
          mrn: '44343343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '0789',
        date: '2022-05-27T15:00:00.000Z',
        user: {
          id: '2',
          name: 'Cynthia Rhodes'
        },
        comments: 'View patient details.',
        patient: {
          id: '8',
          firstName: 'Hazel',
          lastName: 'Barber',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'hazelbarber@yahoo.com',
          phoneNumbers: [
            {
              number: '7192226548',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-2',
              line1: '123 Main St',
              line2: '',
              city: 'Austin',
              state: 'TX',
              zip: '78745',
              country: 'US',
              type: 'physical'
            },
            {
              id: 'CI-24313554-1',
              line1: '607 Woodland Dr',
              line2: '',
              city: 'Austin',
              state: 'TX',
              zip: '78704',
              country: 'US',
              type: 'mailing'
            }
          ],
          organization: {
            name: 'Vail Health',
            id: '2'
          },
          mrn: '88763343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '9432',
        date: '2022-05-27T17:30:00.000Z',
        user: {
          id: '2',
          name: 'Cynthia Rhodes'
        },
        comments: 'Import patient.',
        patient: {
          id: '9',
          firstName: 'Hazel',
          lastName: 'Barber',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'hazelbarber@yahoo.com',
          phoneNumbers: [
            {
              number: '7192226548',
              type: 'home'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-2',
              line1: '123 Main St',
              line2: '',
              city: 'Austin',
              state: 'TX',
              zip: '78745',
              country: 'US',
              type: 'physical'
            },
            {
              id: 'CI-24313554-1',
              line1: '607 Woodland Dr',
              line2: '',
              city: 'Austin',
              state: 'TX',
              zip: '78704',
              country: 'US',
              type: 'mailing'
            }
          ],
          organization: {
            name: 'Vail Health',
            id: '2'
          },
          mrn: '88763343',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      },
      {
        id: '8456',
        date: '2022-05-28T12:00:00.000Z',
        user: {
          id: '3',
          name: 'Sally Lopez'
        },
        comments: 'Request patient record.',
        patient: {
          id: '10',
          firstName: 'Juli',
          lastName: 'Flemming',
          dateOfBirth: '1998-09-05T05:00:00.000Z',
          email: 'jflemming89@gmail.com',
          phoneNumbers: [
            {
              number: '4412879900',
              type: 'mobile'
            }
          ],
          addresses: [
            {
              id: 'CI-24313553-1',
              line1: '764 Bluebonnet Dr',
              line2: '',
              city: 'Great Lakes',
              state: 'CO',
              zip: '80009',
              country: 'US',
              type: 'physical'
            }
          ],
          organization: {
            name: 'Steadman Clinic',
            id: '2'
          },
          mrn: '88763312',
          lastUpdated: '2022-04-27T14:48:00.000Z',
          insurance: {
            provider: 'Blue Cross Blue Shield',
            membershipNumber: '1234566',
            groupNumber: 'WDF567',
            cardImageFront: null,
            cardImageBack: null
          }
        }
      }
    ]
  }
}

export const secondPageActivityLog = {
  total: 200,
  currentPage: 2,
  records: [
    {
      id: '3456',
      date: '2022-05-25T17:19:00.793Z',
      user: {
        id: '1',
        name: 'Jim Holt'
      },
      comments: 'Cancel patient request.',
      patient: {
        id: '1',
        firstName: 'Thomas',
        lastName: 'Bye',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'VVSC',
          id: '2'
        },
        mrn: '22343678',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '1234',
      date: '2022-05-25T17:22:00.793Z',
      user: {
        id: '1',
        name: 'Jim Holt'
      },
      comments: 'Recommend patient to Steadman Clinic.',
      patient: {
        id: '3',
        firstName: 'Cal',
        lastName: 'Halverson',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'VVSC',
          id: '2'
        },
        mrn: '22343343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '8888',
      date: '2022-05-25T16:43:00.793Z',
      user: {
        id: '3',
        name: 'Sally Lopez'
      },
      comments: 'View patient details.',
      patient: {
        id: '4',
        firstName: 'Jessica',
        lastName: 'Graham',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'Vail Health',
          id: '2'
        },
        mrn: '11243343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '7654',
      date: '2022-05-24T23:09:00.793Z',
      user: {
        id: '3',
        name: 'Sally Lopez'
      },
      comments: 'Import patient.',
      patient: {
        id: '6',
        firstName: 'Walt',
        lastName: 'Lengel',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'Vail Health',
          id: '2'
        },
        mrn: '22445343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '7859',
      date: '2022-05-25T17:10:00.793Z',
      user: {
        id: '1',
        name: 'Jim Holt'
      },
      comments: 'View patient details.',
      patient: {
        id: '2',
        firstName: 'Sarah',
        lastName: 'Bye',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'sbye1998@me.com',
        phoneNumbers: [
          {
            number: '8187734532',
            type: 'mobile'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          }
        ],
        organization: {
          name: 'Vail Health',
          id: '2'
        },
        mrn: '22778343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '2233',
      date: '2022-05-24T12:25:00.793Z',
      user: {
        id: '1',
        name: 'Jim Holt'
      },
      comments: 'Ignore patient recommendation.',
      patient: {
        id: '7',
        firstName: 'Nick',
        lastName: 'Moore',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'Steadman Clinic',
          id: '2'
        },
        mrn: '44343343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '8456',
      date: '2022-05-23T22:56:00.793Z',
      user: {
        id: '3',
        name: 'Sally Lopez'
      },
      comments: 'Request patient record.',
      patient: {
        id: '10',
        firstName: 'Juli',
        lastName: 'Flemming',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'Steadman Clinic',
          id: '2'
        },
        mrn: '88763343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '5566',
      date: '2022-05-23T22:56:00.793Z',
      user: {
        id: '2',
        name: 'Cynthia Rhodes'
      },
      comments: 'View patient details.',
      patient: {
        id: '8',
        firstName: 'Hazel',
        lastName: 'Barber',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'Vail Health',
          id: '2'
        },
        mrn: '88763343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '2234',
      date: '2022-05-24T12:25:00.793Z',
      user: {
        id: '1',
        name: 'Jim Holt'
      },
      comments: 'Ignore patient recommendation.',
      patient: {
        id: '7',
        firstName: 'Nick',
        lastName: 'Moore',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'Steadman Clinic',
          id: '2'
        },
        mrn: '44343343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    },
    {
      id: '3783',
      date: '2022-05-25T15:22:00.793Z',
      user: {
        id: '2',
        name: 'Cynthia Rhodes'
      },
      comments: 'Recommend patient to Steadman Clinic.',
      patient: {
        id: '5',
        firstName: 'Thomas',
        lastName: 'Perkins',
        dateOfBirth: '1998-09-05T05:00:00.000Z',
        email: 'jgraham@me.com',
        phoneNumbers: [
          {
            number: '7192226547',
            type: 'home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'physical'
          },
          {
            id: 'CI-24313554-1',
            line1: '123 Main St',
            line2: '',
            city: 'Austin',
            state: 'TX',
            zip: '78745',
            country: 'US',
            type: 'mailing'
          }
        ],
        organization: {
          name: 'VVSC',
          id: '2'
        },
        mrn: '08943343',
        lastUpdated: '2022-04-27T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    }
  ]
}

export const specificActivityLog = {
  total: 1,
  currentPage: 1,
  records: [
    {
      id: '8989',
      date: '2022-05-27T17:22:00.793Z',
      user: {
        id: '1',
        name: 'Jim Holt'
      },
      comments: 'Recommend patient to Steadman Clinic.',
      patient: {
        id: '100',
        firstName: 'Bob',
        lastName: 'Test',
        dateOfBirth: '1985-01-20T05:00:00.000Z',
        email: '',
        phoneNumbers: [
          {
            number: '816778393',
            type: ''
          },
          {
            number: '8162017667',
            type: 'Home'
          }
        ],
        addresses: [
          {
            id: 'CI-24313553-2',
            line1: '123 Bear Creek Dr',
            line2: '',
            city: 'Olathe',
            state: 'KS',
            zip: '66062',
            country: 'US',
            type: 'physical'
          }
        ],
        organization: {
          name: 'Vail Health',
          id: '2'
        },
        mrn: '6574',
        lastUpdated: '2018-10-22T14:48:00.000Z',
        insurance: {
          provider: 'Blue Cross Blue Shield',
          membershipNumber: '1234566',
          groupNumber: 'WDF567',
          cardImageFront: null,
          cardImageBack: null
        }
      }
    }
  ]
}

export const searchMrnActivityLog = firstPageActivityLog.result.records.map(
  log => {
    const { patient } = log
    const patientRecords = firstPageActivityLog.result.records.filter(
      r => r.patient.mrn === patient.mrn
    )
    return { ...firstPageActivityLog, ...patient, records: patientRecords }
  }
)

export const searchNameActivityLog = firstPageActivityLog.result.records.map(
  log => {
    const { patient } = log
    const patientRecords = firstPageActivityLog.result.records.filter(
      r => r.patient.firstName === patient.firstName
    )
    Object.keys(patient).forEach(key => {
      if (typeof patient[key] === 'string') {
        patient[key] = patient[key].toLowerCase()
      }
    })
    return { ...firstPageActivityLog, ...patient, records: patientRecords }
  }
)

export const searchUserActivityLog = firstPageActivityLog.result.records.map(
  log => {
    const { user } = log
    Object.keys(user).forEach(key => {
      if (typeof user[key] === 'string') {
        user[key] = user[key].toLowerCase()
      }
    })
    const patientRecords = firstPageActivityLog.result.records.filter(
      r => r.user.name === user.name
    )
    return { ...firstPageActivityLog, user: user.name, records: patientRecords }
  }
)
