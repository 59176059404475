import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import { breakpointMax } from 'styles/mixins'
import { childrenOfType } from 'lib/customPropTypes'

const useStyles = createUseStyles({
  row: {
    display: 'flex',
    flexWrap: ({ wrap }) => wrap && 'wrap',
    marginBottom: ({ inline, bottomGap }) => !inline && bottomGap,
    marginTop: ({ inline, topGap }) => !inline && topGap,
    width: '100%',

    '&:last-of-type': {
      marginBottom: 0
    },

    '& > *': {
      marginRight: ({ columnGap }) => columnGap
    },

    '& > :last-child': {
      marginRight: 0
    },

    [breakpointMax('md')]: {
      flexWrap: () => 'wrap',

      '& > *': {
        marginRight: 0,
        marginBottom: ({ wrap, bottomGap }) => wrap && bottomGap
      }
    }
  }
})

function Row({ children, ...props }) {
  const classes = useStyles(props)

  return <div className={classes.row}>{children}</div>
}

Row.propTypes = {
  children: childrenOfType('Column').isRequired,
  columnGap: PropTypes.number,
  inline: PropTypes.bool,
  bottomGap: PropTypes.number,
  wrap: PropTypes.bool
}

Row.defaultProps = {
  inline: false,
  columnGap: 20,
  bottomGap: 30,
  wrap: false
}

export default Row
